.statisticsSection {
    font-family: 'Lexend', sans-serif;
    color: #0671E0;
    padding: 16px;
    width: 100%;
    /* max-width: calc(100vw - 32px); */
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .statisticsCards {
    display: flex;
    justify-content:flex-start;
    margin-bottom: 20px;
    gap: 4px;
  }
  
  .statisticsCard {
    width: 176px;
    height: 107px;
    background: rgba(6, 113, 224, 0.06);
    /* border: 1px solid #0671E0; */
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .statisticsNumber {
    font-weight: 300;
    font-size: 28px;
    line-height: 150%;
    letter-spacing: -0.01em;
  }
  
  .statisticsLabel {
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 6px;
  }
  
  .ctaCard {
    background: #FFFFFF;
    border: 0.7px solid rgba(6, 113, 224, 0.88);
    border-radius: 14px;
    padding: 20px;
    margin-bottom: 20px;
    width: 358px;
    height: 222px;
  }
  
  .dots {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .dot {
    width: 6px;
    height: 6px;
    background: #0671E0;
    opacity: 0.12;
    border-radius: 50%;
  }
  
  .ctaTitle {
    font-weight: 400;
    font-size: 28px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 20px;
  }

  .ctaTitleBlue {
    font-weight: 400;
    font-size: 28px;
    color: #0671E0 !important ;
    text-align: center;
    letter-spacing: -0.01em;
    margin-bottom: 0px;
  }
  
  .ctaButton {
    background: #0671E0;
    border-radius: 90px;
    color: #F2F2F2;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 9px 18px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  
  .arrow {
    width: 8px;
    height: 8px;
    border-right: 1.3px solid #F2F2F2;
    border-bottom: 1.3px solid #F2F2F2;
    transform: rotate(-45deg);
    margin-left: 10px;
  }
  
  @media (max-width:768px) {
    .statisticsSection{
      display: flex;
    }
    
  }