.founder-hobbies {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    /* border-radius: 14px; */
    background-image: radial-gradient(circle at 100% 100%, transparent 20px, #ffffff 20px, #ffffff39 24px, transparent 24px), linear-gradient(to right, #ffffff89, #ffffff00), radial-gradient(circle at 0% 100%, transparent 20px, #ffffff00 20px, #ffffff00 24px, transparent 24px), linear-gradient(to bottom, #ffffff00, #ffffff00), radial-gradient(circle at 0% 0%, transparent 20px, #ffffff00 20px, #ffffff00 24px, transparent 24px), linear-gradient(to left, #ffffff00, #ffffff0b), radial-gradient(circle at 100% 0%, transparent 20px, #ffffff3a 20px, #ffffff17 24px, transparent 24px), linear-gradient(to top, #ffffff2a, #ffffffe2);
    background-size: 24px 24px, calc(100% - 48px) 4px, 24px 24px, 4px calc(100% - 48px);
    background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
    background-repeat: no-repeat;
    margin: 0;
    width: 49%;

}


.founder-hobbies h5 {
    position: relative;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    top: 4px;
    color: var(--primary-800);
}

.founder-hobbies p {
    font-size: 20px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: -0.03em;
    text-align: left;

}

@media (max-width: 768px) {
    .founder-hobbies {
        width: 100%;
    }

    .founder-hobbies img{
        width: 22px;
    }

    .founder-hobbies h5 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.03em;
        text-align: left;

    }

    .founder-hobbies p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: -0.03em;
        text-align: left;
        
    
    }
}