.container {
    padding : 0rem 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    gap: 2.625rem;
}


.mediaContainer {
    width: 100%;
    border-radius: 4.31px;
    border : 2px solid #216A99;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: contain;
}

.imgContainer {
    position: relative;
}

.mask {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    background: #00000066;
    backdrop-filter: blur(1px);
}

.linksContainer {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap : 1rem;
}

.linksContainer a {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #90B4CC;
}

.linksContainerLinkActive {
    font-weight: 600 !important;
    color: #11354D !important;
}

.linksContainer a:hover {
    font-weight: 600;
    color: #11354D;
}