.button-hollow {
    background-color: #ffffff00;
    border : 1.6px solid #0671E0;
    font-size: 12px;
    color: #0671E0;
}

.button-hollow:hover {
    background-color: rgba(255, 255, 255, 0);
    border : 1.6px solid #0671E0;
    color: #0671E0;
}

.button-hollow:disabled{
    background-color: rgba(255, 255, 255, 0);
    border : 1.6px solid #216A99;
    font-size: 12px;
    color: #216A99;
}

@media (min-width: 992px) {
    .button-hollow {
        border : 2px solid #0671E0;
        padding : 16px 24px;
        font-family: Lexend;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.03em;
        text-align: center;        
    }

    .button-hollow:disabled {
        border : 2px solid #0671E0;
        padding : 16px 24px;
        font-family: Lexend;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.03em;
        text-align: center;  
    }

}