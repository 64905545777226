#inner_container_tou {
    margin-left: 15%;
    margin-right: 15%;
    display: flex;
    flex-direction: column;
    gap : 3px;
}

#inner_container_tou ol {
    margin: 0;
    padding: 0
}

#inner_container_tou         table td,
#inner_container_tou         table th {
    padding: 0
}

#inner_container_tou         .c24 {
    margin-left: 50pt;
    padding-top: 0pt;
    text-indent: -18pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: center
}

#inner_container_tou         .c9 {
    margin-left: 57pt;
    padding-top: 0pt;
    text-indent: -22pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c6 {
    margin-left: 86pt;
    padding-top: 0pt;
    text-indent: -25pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c10 {
    margin-left: 50pt;
    padding-top: 0pt;
    text-indent: -18pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c7 {
    margin-left: 62pt;
    padding-top: 0pt;
    text-indent: -22pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou        .c18 {
    margin-left: 62pt;
    padding-top: 0pt;
    text-indent: -22pt;
    padding-bottom: 0pt;
    line-height: 1.0699977272727272;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c27 {
    -webkit-text-decoration-skip: none;
    color: #164766;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-style: normal
}

#inner_container_tou        .c30 {
    margin-left: 78pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0699977272727272;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou         .c8 {
    margin-left: 40pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c25 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

#inner_container_tou         .c15 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0699977272727272;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou         .c19 {
    margin-left: 40pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0699977272727272;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c17 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0699977272727272;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou        .c0 {
    color: #164766;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
font-family : inherit;
    font-style: normal
}

#inner_container_tou         .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500022727272727;
    orphans: 2;
    widows: 2;
    text-align: justify
}

#inner_container_tou         .c3 {
    color: #164766;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-style: normal
}

#inner_container_tou         .c23 {
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

#inner_container_tou         .c12 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt
}

#inner_container_tou         .c14 {
    color: #164766;
    text-decoration: inherit
}

#inner_container_tou        .c2 {
    font-weight: 400;
font-family : inherit;
}

#inner_container_tou         .c26 {
    font-weight: 400;
font-family : inherit;
}

#inner_container_tou         .c1 {
    font-weight: 700;
font-family : inherit;
}

#inner_container_tou         .c28 {
    height: 11pt
}

#inner_container_tou         .c21 {
    color: #646464
}

#inner_container_tou         .c20 {
    font-style: italic
}

#inner_container_tou         .c5 {
    color: #0563c1
}

#inner_container_tou         .c4 {
    font-size: 7pt
}

#inner_container_tou          .c16 {
    margin-left: 32pt
}


#inner_container_tou         .c22 {
    margin-left: 14pt
}

#inner_container_tou          .c29 {
    margin-left: 61pt
}

#inner_container_tou         .title {
    padding-top: 0pt;
    color: #164766;
    font-size: 26pt;
    padding-bottom: 3pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou         .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou         li {
    color: #164766;
    font-size: 11pt;
font-family : inherit;
}

#inner_container_tou         p {
    margin: 0;
    color: #164766;
    font-size: 11pt;
font-family : inherit;
}

#inner_container_tou         h1 {
    padding-top: 20pt;
    color: #164766;
    font-size: 20pt;
    padding-bottom: 6pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou          h2 {
    padding-top: 18pt;
    color: #164766;
    font-size: 16pt;
    padding-bottom: 6pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou         h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou         h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#inner_container_tou        h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

 #inner_container_tou         h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
font-family : inherit;;
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

