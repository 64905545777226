:root {
    --background: #E5F0F7;
    --primary-700: #164766;
    --primary-800: #11354D;
    --primary-500: #25335A;
    --primary-base: #216A99;
    --items-container-bg: #F1F7FB;
}

body {
    background: var(--background);
}

.featured-items {
    margin-top: 4rem;
    padding: 32px 80px 32px 80px;
}


#features-section-heading {
    font-size: 32px;
    padding: 0;
}

#features-section {
    margin-bottom: 40px;
}

#features-section {
    margin-top: 32px;
    padding-top: 0px;
}

@media (max-width: 768px) {

    .featured-items {
        padding: 0px;
    }

    .featured-items {
        padding: 24px;
        margin-top: 30px;
    }

    .featured-img {
        background: var(--items-container-bg);
        padding: 23px 14px 23px 14px;
        border-radius: 12px;
    }

    #features-section-heading {
        padding: 0 46px;
    }

    #features-section {
        margin-bottom: 40px;
    }

    #features-section {
        margin-top: 30px;
    }

}