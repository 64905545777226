.container {
  position: relative;
  width: 100%;
  min-height: 625px;
  overflow: hidden;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 50px;
  padding-left: 112px;
  padding-bottom: 50px;
  padding-right: 60px;
}

.textContent {
  width: 50%;
  max-width: 644px;
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 6px;
  width: 100%;
}

.heading {
  width: 100%;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 128%;
  display: flex;
  flex-wrap: wrap; /* Allows wrapping if necessary */
  letter-spacing: -0.05em;
}

.blueText {
  color: #007BFF;
}

.blackText {
  color: #0D121A;
}


.description {
  width: 100%;
  margin-top: 13px;
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #24282F;
}

.buttonGroup {
  display: flex;
  gap: 16px;
  margin-top: 30px;
}

.primaryButton {
  box-sizing: border-box;
  border-width: 1px;
  border-style: none;
  border-color: #0671E0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 188px;
  height: 48px;
  background: #0671E0;
  border-radius: 90px;
  text-decoration: none;
}

.secondaryButton {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 153px;
  height: 48px;
  border: 1.5px solid #0671E0;
  border-radius: 85px;
  text-decoration: none;
}

.buttonText {
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #F2F2F2;
}

.secondaryButton .buttonText {
  color: #0671E0;
}

.imageWrapper {
  width: 45%;
  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
    padding: 30px;
  }

  .textContent,
  .imageWrapper {
    width: 100%;
  }

  .imageWrapper {
    order: 2;
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    height: auto;
  }

  .heading {
    font-size: 36px;
    justify-content: center;
  }

  .description {
    font-size: 16px;
    text-align: center;
  }

  .buttonGroup {
    flex-direction: row;
  }

  .primaryButton,
  .secondaryButton {
    width: 100%;
  }

  .imageWrapper {
    min-height: 300px;
  }
}

