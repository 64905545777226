#pricing-container {
    background-image: url(/public/assets/images/mobile_grid.svg);
    background-position-y: top;
    background-position-x : center;
    background-repeat: no-repeat;
    background-size: contain;
}

.pricing-heading {
    font-family: Lexend;
    font-family: Lexend;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;    
    color: #11354D;
}

.pricing-heading-container {
    margin-top: 8px;
    backdrop-filter: blur(2.5px);
}

.pricing-heading-container-2 {
    margin-top: 26px;
    backdrop-filter: blur(2px);
}

.pricing-desc {
    font-family: Lexend;
    font-size: 14px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #4683AA;
    margin-top: 16px;
}

.mb-m {
    margin-top: 96px !important;
}

#features-section-heading {
    padding : 0 32px;
}

#features-section {
    margin-bottom: 40px;
}

.map-cont {
    background-color: #E7F1F880;
    backdrop-filter: blur(1.5px);
}

#features-section {
    margin-top: 30px;
}

.sub-heading {
    color : #164766;
}




@media (min-width:992px) {
    #pricing-container {
        background-image: url(/public/assets/images/grid_bg.svg);
        background-size: contain;
        background-position-y: top;
        background-position-x : center;
    }

    .mb-m {
        margin-top: 0px ;
    }

    
    #pricing-container>.row>.pricing-heading-container {
        margin-top: 64px !important;
    }

    #select-price {
        margin-top: 72px;
    }

    #price-cards {
        margin-left: 100px;
        margin-right: 100px;
    }

    .pricing-heading {
        font-family: Lexend;
        font-size: 64px;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #11354D;
        padding: 2px 144px;
    }

    .sub-heading {

        font-family: Lexend;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: center;
        padding : 0px 250px

    }

    #features-section-heading {
        font-size: 32px;
        padding: 0;
    }

    #features-section {
        margin-bottom: 40px;
    }


    .pricing-desc {
        font-family: Lexend;
        font-size: 24px;
        font-weight: 350;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: center;        
        color: #4683AA;
        padding : 4px 340px;
        margin: 0px 100px;
    }

    #features-section {
        margin-top: 32px;
        padding-top: 0px;
    }

}


.popIn {
    animation: pop-in 1s ease-out;
    animation-iteration-count: 1;
}

.priceCard {
    opacity: 0;
}

@keyframes pop-in {
    from {
        margin-top: 175px;
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.show1 {
    animation: show 0.5s ease-in 1;
}

.show2 {
    animation: show2 0.5s ease-in 1;
}


@keyframes show {
    0% {
        scale : 0 0;
    }
    100% {
        scale: 1 1;
    }
}

@keyframes show2 {
    0% {
        scale : 0 0;
    }
    100% {
        scale: 1 1;
    }
}