
    .box-heading {
        border: 2px solid #B5CDDD;
        color: var(--primary-base);
        font-size: 16px;
        font-weight: 500;
        padding: 4px 20px;
        border-radius: 12px;
        background: #fff;
    }


@media (max-width: 768px) {
    .box-heading {
        font-size: 16px;
        font-weight: 500;
        padding: 4px 20px;
        border-radius: 12px;
        background: #fff;
    }

    .line {
        display: inline-block;
        height: 2px;
        width: 20%;
        background-image: linear-gradient(to right, #E7F1F8, #003E7D);
    }

}