.calendarFeatureCard>div {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    opacity: .9;
}

.calendarFeatureCard>button {
    padding: 0 27px;
    height: 48px;
    border-radius: 85px;
    border: 1.5px solid #0671E0;
    display: grid;
    place-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -3%;
    color: #0671E0;
    background: transparent;
    max-width: fit-content;
}

.calendarFeatureCard>button:hover {
    background: #0671E0;
    color: #ffffff;
}

.calendarFeatureCard>button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.calendarFeatureCard>button span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.calendarFeatureCard>button:hover span {
    padding-right: 15px;
}

.calendarFeatureCard>button:hover span:after {
    opacity: 1;
    right: 0;
}

.calendarFeatureCard {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 76px;
}

.calendarFeatureCard>img {
    width: 100%;
    object-fit: contain;
    display: none;
}

@media screen and (max-width: 768px) {
    .calendarFeatureCard {
        padding: 0;
        gap: 12px;
    }

    .calendarFeatureCard>div {
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        opacity: 0.9;
    }

    .calendarFeatureCard>button {
        /* width: 107px; */
        padding: 0 10px;
        height: 30px;
        font-size: 14px;
        line-height: 24px;
    }

    .calendarFeatureCard>img {
        display: block;
    }
}