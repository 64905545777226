#home {
    margin-top: 34px;
}

.home-section-one {
    gap : 16px
}

.home-section-one-actions {
    gap : 16px
}

.home-title {
    font-family: Lexend;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;
    color: #07151F;
}

iframe{
    border-radius: 25px;
}

.home-title-container,
.home-title-container-tutor
.home-title-container-live,
.home-title-container-of,
.home-title-container-success,
.home-title-container-price{
    color: #07151F;
    font-family: Lexend;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.05em;
    text-align: center; 
}

.home-title-live {
    color: #07151F;
    font-family: Lexend;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.05em;
    text-align: center;    
}

.home-title-container-tutor {
    font-family: Lexend;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;
}

.home-description-container,
.home-description-container-live,
.home-description-container-of,
.home-description-container-price{
    color : #07151F;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: center;

}

.home-title-container-tutor{
    color: #07151F;
}

.home-title {
    display: inline-block;
}

.home-description{
    color: #25335A;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: center;

}
.home-description-live,
.home-description-of {
    font-size: 16px;
    text-align: center;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}

.home-title-lg,
.home-description-lg,
.home-description-live-lg,
.home-description-of-lg {
    display: none;
}

.subheading-container-home {
    background-color: white;
    border: 2px solid #9EBDDB;
    border-radius: 10px;
    color: #41484E;
    font-family: Lexend;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 8px 20px 8px 20px;
    display: inline-block;
    text-transform: uppercase;
}

.subheading-container-2-home {
    background-color: white;
    border: 0.7px solid #9EBDDB;
    color: #41484E;
    font-size: 6px;
    padding:   5px;
    font-weight: 500;
    border-radius: 12px;
    text-align: center;
    margin-left: 10px;
}

.line{
    display: inline-block;
    height: 0.7px;
    width: 15vw;
    background-image: linear-gradient(to right,#E7F1F8,#003E7D);
}

.comparison {
    gap : 16px
}

.home-section-two {
    margin-top: 32px;
    gap : 32px
}

.home-section-two-sub-one {
    gap : 32px
}

#current-tutor{
    color: #25335A;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: right;
}

#evallo-tutor {
    color: #25335A;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
}

#vs{
    color: #216A9930;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: center;
    text-transform: lowercase;
}

.divider {
    gap : 1.48px;
    font-size: 3.5px;
}

.features-container {
    gap : 8px;
}

.features {
    gap : 4px;
    width: 157px;
}

.features>img{
    margin-top: 6px;
}

.features-heading {
    color: #0B2333;
    font-family: Lexend;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.03em;
    text-align: left;
}

.features-description{
    font-family: Lexend;
    font-size: 10px;
    font-weight: 350;
    line-height: 13px;
    letter-spacing: -0.03em;
    color: #515C7B;
}

.divider {
    height : 30vw;
}

/* .fc-one {
    padding-left: 5vw;
} */


.home-section-three {
    display : none
}

.oct-wrapper {
    position: relative;
    height: fit-content;
    width: 89vw;
}

.oct {
    margin : -1.3vw;
    height: 41vw;
}

.oct-container {
    gap : 20px;
    position: absolute;
    top :  10vw;
    left : 100% - 89vw
}

.home-section-four {
    gap : 32px;
}

.home-container-live {
    gap: 8px;
}

.home-container-of {
    margin-top: 32px;
}

.home-section-five {
    margin-top: 38px;
    gap : 8px;
}

.home-section-seven {
    margin-top: 50px;
    margin-bottom: 40px;
}

.home-container-of {
    gap : 8px
}

.features-wrapper {
    margin-top: 4px;
    margin-bottom: 4px;
}

.fc {
    padding-left: 20px;
    padding-right: 20px;
}

.our-features-container {
    background-color : #F1F7FB;
    border-radius: 2vw;
    height: 100%;
}

.our-features-heading {
    font-family: Lexend;
    font-size: 19px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #25335A;
}

.our-features-description {
    font-family: Lexend;
    font-size: 10px;
    font-weight: 350;
    line-height: 13px;
    letter-spacing: -0.03em;
    text-align: left;    
    color :#515C7B;
}


#img-crm{
    height: 150px;
    width: 161px;
    object-fit: contain;
}

#img-invoice {
    height: 197;
    width: 100%;
    object-fit: contain;
}

#img-dets {
    height: 96px;
    width: 144px;
    object-fit: contain;
}

#img-dash {
    width: 200px;
    height: 130px;
    object-fit: contain;
}

#img-pay {
    height: 100%;
    width: 140px;
    border-radius: 2vw;
    object-fit: contain;
}

#img-cal {
    height: 178px;
    width: 303px;
    object-fit: contain;
}

#img-perf {
    height: 158px;
    width: 278px;
    object-fit: contain;
}

#img-admin {
    margin: 0 auto;
    height: 98px;
    width: 296px;
    object-fit: contain;
}

/* .home-container-success {

} */

/* .home-title-container-success {

} */

.home-title-success {
    font-size: 32px;
    color: #07151F;
}

.home-description-success {
    font-size: 16px;
    color : #4683AA;
    letter-spacing: -0.03em;
}

.home-description-success-lg{
    color : #07151F;
    display: none;
    margin-top: 8px;
}

.bh-lg{
    display: none;
    margin-top: 8px;
}


.home-faq-title-container {
    font-family: Lexend;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;
}

.faq-title-1{
    color : #164766;
    width: 100%;
}
.faq-title-2{
    color : #B5CDDD;
}

.brand-section{
    height: fit-content !important;
}

/* .brand-section {
   height: 550px;
   white-space: nowrap;
   margin: 0 auto;
   overflow: hidden;
} */

.price-cont {
    margin-top: 32px;
}

.home-container-price {
    margin-top: 32px;
    gap : 16px;
}

.price-select-home {
    margin-top: 32px;
}

.home-section-nine {
    margin-top: 32px;
}

.home-faq-title-container{
    margin-top: 32px;
}

.meteorite-1{
    position: absolute;
    left : 570px;
    animation: test_sm 5s linear infinite;
    opacity: 0;
}

.meteorite-2{
    position: absolute;
    left : 620px;
    animation: test_sm  7.5s linear 1.25s infinite;
    opacity: 0;
}
.meteorite-3{
    position: absolute;
    left : 600px;
    animation: test_sm  4.5s linear 0.25s infinite;
    opacity: 0;
}

.meteorite-4{
    position: absolute;
    right : 680px;
    animation: test_sm  7.6s linear 0.75s infinite;
    opacity: 0;
}
.meteorite-5{
    position: absolute;
    left : 740px;
    animation: test_sm  7s linear 1s infinite;
    opacity: 0;
}

.meteorite-6 {
    position: absolute;
    left : 220px;
    animation: test_sm  8s linear 1.4s infinite;
    opacity: 0;
}

.meteorite-7{
    position: absolute;
    left : 150px;
    animation: test_sm 10s linear 1.75s infinite;
    opacity: 0;
}
.meteorite-8{
    position: absolute;
    left : 300px;
    animation: test_sm 6s linear 1.8s infinite;
    opacity: 0;
}
.meteorite-9{
    position: absolute;
    left : 420px;
    animation: test_sm 6.4s linear 4s infinite;
    opacity: 0;
}

.meteorite-10 {
    position: absolute;
    left : 320px;
    animation: test_sm  8s linear 2.4s infinite;
    opacity: 0;
}

.meteorite-11{
    position: absolute;
    left : 450px;
    animation: test_sm 5.4s linear 3.75s infinite;
    opacity: 0;
}

.meteorite-12{
    position: absolute;
    left : 500px;
    animation: test_sm 6s linear 0.8s infinite;
    opacity: 0;
}

.homeCard {
    opacity: 0;
}


.homeSection {
    scale : 0 1
}


.popOut {
    animation: pop-out 1.5s ease-out;
}


@media (min-width : 992px) {

    .features-container {
        gap : 20px;
    }

    .features {
        gap : 10px;
        width: 321px;
    }

}


@media (min-width : 1200px) {

    .price-cards-padding {
        padding-left: 98px;
        padding-right: 110px;
    }

    .faq-title-2{
        color : #B5CDDD;
        margin-top: 0px;
    }

    .home-section-two {
        margin-top : 64px;
    }

    .price-cont {
        margin-top: 88px;
    }

    .home-description-container {
        color: #25335A;
    }

    .home-title,
    .home-description,
    .home-description-live,
    .home-description-of,
    .home-description-price{
        display: none;
    }

    .home-title-lg,.home-description-lg, 
    .home-description-live-lg,
    .home-description-of-lg {
        display: block;
    }

    .home-description-lg,
    .home-description-live-lg,
    .home-description-of-lg,
    .home-description-success-lg   {
        font-size: 24px;
        font-family: Lexend;
        font-size: 24px;
        font-weight: 350;
        line-height: 26px;
        letter-spacing: -0.03em;
        text-align: center;

    }

    .home-title-success {
        font-size: 48px;
    }

    .home-title-container{
        font-family: Lexend;
        font-size: 64px;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: -0.05em;
        text-align: center;
        
    }

    .home-title-container-tutor,
    .home-title-container-live,
    .home-title-container-of,
    .home-title-container-success,
    .home-title-container-price,
    .home-title-live {
        color: #07151F;
        font-family: Lexend;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: center;
        
    }

    .home-title-container-tutor{
        color: #07151F;
    }

    .subheading-container-home {
        border: 2px solid #9EBDDB;
        color: #216A99;
        font-size: 16px;
        font-weight: 500;
        padding : 9px 22px 9px 22px;
        border-radius: 12px;
        
    }
    
    .line{
        display: inline-block;
        height: 2px;
        width: 27vw;
        background-image: linear-gradient(to right,#E7F1F8,#003E7D);
    }

    .home-section-two {
        gap : 40px
    }
    
    .home-section-two-sub-one {
        gap : 40px
    }
    .comparison {
        gap : 31px !important;
    }

    #current-tutor{
        color: #07151F;
        font-family: Lexend;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.03em;
        text-align: right;   
    }
    
    #evallo-tutor {
        color: #25335A;
        font-family: Lexend;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.03em;
        text-align: left;
        
    }

    #vs{
        font-size: 32px;
        color: #216A9930;
        font-weight: 400;
    }

    .divider {
        height : 24vw;
    }

    .features-container {
        gap : 20px;
    }

    .features {
        gap : 12px;
        width: 536px;
    }

    .features-heading {
        color: #0B2333;
        font-family: Lexend;
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: left;
        
    }

    .features-description{
        font-family: Lexend;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: -0.03em;
        color: #515C7B;
    }
    /* .fc-one {
        padding-left: 5vw;
    } */

    .home-section-four {
        margin-top: 46px;
        gap : 40px
    }

    .home-container-live {
        gap: 24px;
    }

    .home-section-five {
        margin-top: 46px;
        gap : 40px
    }

    .home-section-seven{
        margin-top: 32px;
    }

    .home-container-of {
        margin-top: 0px;
        gap : 24px
    }

    .home-container-price {
        margin-top: 40px;
    }

    .fc {
        padding-left: 120px;
        padding-right: 120px;
    }

    /* .features-wrapper{
        padding: 10px;
    } */

    .our-features-heading {
        font-family: Lexend;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #25335A;
    }

    .our-features-description {
        font-family: Lexend;
        font-size: 16px;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: -0.03em;
        text-align: left;    
        z-index: 3;
        margin-right: -14px;    
    }

    .price-select-home{
        margin-top: 135px;
        margin-bottom: 55px;
    }

    #img-crm{
        height: 257px ;
        width: 351px ;
        object-fit: contain;
    }

    #img-invoice {
        height: 170px ;
        width: 100% ;
        object-fit: contain;
    }

    #img-dets {
        height: 170px;
        width: 242px;
        object-fit: contain;
    }

    #img-dash {
        height: 252px ;
        width: 363px ;
        object-fit: contain;
    }

    #img-pay {
        height: 186px; 
        width: 261px;
        border-radius: 2vw;
    }

    #img-cal {
        height: 192px;
        width: 341px ;
        object-fit: contain;
    }

    #img-perf {
        height: 179px;
        width:314px;
        object-fit: contain;
    }

    #img-admin {
        height: 111px;
        width: 334px;
        object-fit: contain;
    }

    .home-description-success {
        display: none;
    }
    .home-description-success-lg {
        display: block;
        font-family: Lexend;
        font-size: 24px;
        font-weight: 350;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        padding-right: 20px;
    }

    .cards-prices {
        background-image: url(/public/assets/cardBack.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
        background-position-y: 140px;
    }

    .bh{
        display: none;
    }
    .bh-lg{
        display: inline;
    }

    .home-faq-title-container {
        font-family: Lexend;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: center;
        margin-top: 40px;
    }

    .faq-title-1{
        color : #BEC2CE;
        width: fit-content;
    }
    .faq-title-2{
        color : #07151F;
    }
    


    .home-section-nine {
        margin-top: 40px;

    }

    .meteorite-1{
        position: absolute;
        left : 700px;
        animation: test 5s linear infinite;
        opacity: 0;
    }
    
    .meteorite-2{
        position: absolute;
        left : 900px;
        animation: test  7s linear 1.25s infinite;
        opacity: 0;
    }
    .meteorite-3{
        position: absolute;
        left : 800px;
        animation: test  5.5s linear 0.25s infinite;
        opacity: 0;
    }
    
    .meteorite-4{
        position: absolute;
        right : 1800px;
        animation: test  7.6 linear 0.75s infinite;
        opacity: 0;
    }
    .meteorite-5{
        position: absolute;
        left : 1400px;
        animation: test  7s linear 1s infinite;
        opacity: 0;
    }
    
    .meteorite-6 {
        position: absolute;
        left : 1200px;
        animation: test  8s linear 1.4s infinite;
        opacity: 0;
    }
    
    .meteorite-7{
        position: absolute;
        left : 1500px;
        animation: test 4.4s linear 1.75s infinite;
        opacity: 0;
    }
    .meteorite-8{
        position: absolute;
        left : 1600px;
        animation: test 6s linear 1.8s infinite;
        opacity: 0;
    }
    .meteorite-9{
        position: absolute;
        left : 1800px;
        animation: test 6.4s linear 4s infinite;
        opacity: 0;
    }
    
    .meteorite-10 {
        position: absolute;
        left : 2200px;
        animation: test  8s linear 2.4s infinite;
        opacity: 0;
    }
    
    .meteorite-11{
        position: absolute;
        left : 2000px;
        animation: test 4.4s linear 3.75s infinite;
        opacity: 0;
    }

    .meteorite-12{
        position: absolute;
        left : 1900px;
        animation: test 6s linear 0.8s infinite;
        opacity: 0;
    }

   

}

@media (min-width : 1200px){
  
}


.trail{
    height: 6px;
    border-radius: 25px;
    width: 150px;
    background-image: linear-gradient(to right, rgba(255,255,255,0.9),rgba(255,255,255,0.1));
}

.meteor{
    height: 8.5px;
    width: 8.5px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 10px 0px 15px white,0px 10px 15px white, 0px -10px 15px white,-10px 0px 15px white;
}


@media( min-width : 1600px){
    #locked-row {
        width: 1440px;
    }
}


@keyframes test {
    0% {
        opacity: 1;
        transform: rotate(-45deg) translate(0px,0px);
    }

    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(-200vw,0px) 
    }
}

@keyframes test_sm {
    0% {
        opacity: 1;
        transform: rotate(-45deg) translate(0px,0px);
    }

    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(-300vw,0px) 
    }
}

/* .popIn{
    animation: pop-in 1s linear 1;
} */

@keyframes pop-in {
    from {
        margin-top: 175px;
        opacity: 0;
    }

    to {
        margin-top: 0px;
        opacity: 1;
    }
}



@keyframes pop-out {
    from {
        margin-top: 175px;
        height: 0px;
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


