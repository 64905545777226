.container{
    width: 100%;
    padding: 1rem 1rem; 
    height: 100%;
}


.imgContainer {
    border-radius: 12px;
    background: #F1F7FB;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.img{
    padding : 1rem;
    width: 100%;
    object-fit: contain;
}

.heading {
    font-family: Lexend;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #11354D;
    padding-right: 3rem;
   
}

.description {
    font-family: Lexend;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color : #4683AA;
  
}



@media (min-width : 992px){

    .container{
        width: 100%;
        padding: 2rem 0.5rem 2rem 0.5rem; 
        max-height: 550px;
        margin-top: 2rem;
       
    }

    
    .img{
        padding : 1rem;
        max-width: 100%;
        object-fit: contain;
    }
    
    .imgContainer {
        border-radius: 24px;
        background: #F1F7FB;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        
        
    }
    
    
    .heading {
        font-size: 2rem;
        font-family: Lexend;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #164766;
    }
    
    .description {
        font-size: 1.2rem;
        font-family: Lexend;
        font-weight: 350;
        line-height: 25px;
        letter-spacing: -0.03em;
        text-align: left;
        color : #4683AA;
        
    }

    
}
