.stage-container {
  background-color: #e7f1f8;
  display: grid;
  grid-template-rows: auto minmax(auto, 1fr) auto;
  height: fit-content;
}

.navbar-container {
  top: 0;
  position: sticky;
  padding-top: 40px;
  z-index: 500;

}

.content-container {
  height: 100%;
  grid-row: 2/3;
}

.footer-container {
  height: 100%;
  grid-row: 4/5;
  background-color: #0b2333;
  padding: 0px;
  margin: 0px;
}

@media screen and (max-width: 768px) {
  .navbar-container {
    top: 0;
    position: sticky;
    padding-top: 20px !important;
    z-index: 100;
  }
}