.banner-card {
    gap: 1rem;
    padding: 1rem 0;
    max-height: 474px;
    height: 100%;
}

.note-part{
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #4683AA;
}

.banner-card h1 {
    font-size: 48px;
    /* font-size: 3.3vw; */
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.05em;
    text-align: left;
    color: var(--primary-700);
}

.banner-card p {
    font-size: 20px;
    font-weight: 350;
    line-height: 25px;
    text-align: left;
    color: var(--primary-500);
}

.banner-card-info {
    width: 40%;
}

.banner-cxard-img {
    padding: 1rem;
    background: #F1F7FB;
    border-radius: 24px;
    width: 60%;
    height: 100%;
    max-width: 700px;
    max-height: 100%;
}

.banner-card-img {
    width: 100%;
    max-height: 100%;
    padding: 1rem;
    object-fit: contain;
}

.banner-init {
    opacity: 0;

}

.popIn {
    animation: pop-in 0.5s ease-out;
    /* animation-timeline: view(80% 0%);  */
}

@keyframes pop-in {
    from {
        margin-top: 175px;
        opacity: 0;
    }

    to {
        margin-top: 0px;
        opacity: 1;
    }
}



@media (max-width: 768px) {

    .banner-card h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: left;
    }

    .banner-card p {
        font-size: 14px;
        font-weight: 350;
        line-height: 18px;
        letter-spacing: -0.03em;
        text-align: left;
    }

    .banner-card-info {
        width: 100%;
    }

    .banner-cxard-img {
        padding: 16px 10px;
        background: #F1F7FB;
        border-radius: 14px;
        width: 100%;
        max-height: 100%;
    }

    .banner-card {
        gap: 1rem;
        padding: .8rem 0;
        max-height: none;
    }

    .note-part{
        font-size: 12px;
    }
}