.normal-btn {
    padding: 12px 16px;
    border-radius: 22px;
    border: 2px solid #0671E0;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #0671E0;
    display: flex;
    align-items: center;
    gap: 8px;
    background: none;
    margin: 16px 0;
}

@media (min-width: 768px) {

    .normal-btn {
        font-size: 20px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.03em;
        text-align: center;
        padding: 16px 24px;
        border-radius: 81px;


    }

}