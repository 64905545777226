.container {
  border-radius: 17.235px;
  border: 4px solid rgba(255, 255, 255, 0);
  background: linear-gradient(115deg, rgba(255, 255, 255, 0.21) 1.14%, rgba(255, 255, 255, 0) 101.59%);
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(6px);
  padding: 35px 55px;
  display: flex;
  flex-direction: column;
  gap: 53px;
  width: 100%;
  max-width: 883px;
}
.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.heading {
  color: var(--Secondary-Solid, #25335a) !important;
  text-align: center;
  font-family: "Lexend";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 60px */
  letter-spacing: -2.4px;
}
.subHeading {
  color: var(--secondary-80, #515c7b) !important;
  font-feature-settings: "cv01" on, "cv03" on, "cv04" on;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 350;
  line-height: 110%; /* 26.4px */
  letter-spacing: -0.72px;
}
.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.inputNameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.inputStyles {
  width: 100%;
  padding: 7.5px 12px;
  color: var(--secondary-60, #7c859c) !important;

  /* Body/B2 */
  font-family: 'Lexend';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.inputStyles::-webkit-outer-spin-button,
.inputStyles::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputStyles:focus {
  outline: none;
}

.demoButton {
  display: flex;
  color: #fff;
  width: fit-content;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 8px;
  border-radius: 85px;
  background: var(--Blue-700, #0671e0);
  border: none;
  color: #fff;
  text-align: center;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.48px;
}
@media (max-width: 1024px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .heading {
    font-size: 24px;
  }
  .subHeading {
    font-size: 12px;
  }
}
