.container {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: 
    #E5F0F7;
}

.navContainer {
    grid-row: 1 / 2;
    height: 100%;
}

.contentContainer {
    grid-row: 2 / 3;
    padding: 1rem 1.5rem;
    padding: 1rem  1.5rem;
    overflow-x: hidden;
    overflow-wrap: break-word;
    color: #164766;
}

.contentContainer img {
    max-width: 100%;
    object-fit: contain;
}

.contentContainer .title{
    font-family: Lexend;
    font-size: 2rem;
    font-weight: 600;
    line-height: 83px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #11354D;

}

.contentContainer h1 {
    font-family: Lexend;
    font-size: 2rem;
    font-weight: 600;
    line-height: 83px;
    letter-spacing: -0.05em;
    text-align: left;
    color:  #164766;
}

.contentContainer h2 {
    font-family: Lexend;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 62px;
    letter-spacing: -0.05em;
    text-align: left;
    color:  #164766;
}

.contentContainer h3 {
    font-family: Lexend;
    font-size: 1rem;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: -0.05em;
    text-align: left;
    color:  #164766;
}

.contentContainer h4 {
    font-family: Lexend;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: -0.05em;
    text-align: left;
    color:  #164766;
}

.contentContainer h5 {
    font-family: Lexend;
    font-size: 0.625rem;
    font-weight: 350;
    line-height: 26px;
    letter-spacing: -0.05em;
    text-align: left;
    color:  #164766;
}

.contentContainer img{
    max-width: 100%;
    object-fit: contain;
}


.contentNav {
    display: none;
}

.contentHelp {
    display: none;
}

.makeBackgroundDiv {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: rgba(17, 53, 77, 0.2);
}

@media (min-width : 992px) {
    .container {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 20% 1fr 20%;
        height: 100%;
        width: 100%;
    }

    .navContainer {
        grid-row: 1 /2;
        grid-column: 1 / 4;
        padding: 1.5rem 2.5rem 0rem 2.5rem;
        border-bottom: 1px solid #D3E1EB;
    }

    .contentNav {
        display: block;
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        padding-top: 1.5rem;
    }

    .contentContainer {
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        font-family: Lexend;
        font-size: 20px;
        font-weight: 350;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #164766;
        max-height: 100%;
        overflow-y: scroll;
    }

    .contentContainer .title{
        font-family: Lexend;
        font-size: 4rem;
        font-weight: 600;
        line-height: 83px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #11354D;
    
    }

    .contentHelp {
        display: block;
        grid-row: 2 / 3;
        grid-column: 3 / 4;
        padding-top: 1.5rem;
    }

    .contentContainer h1 {
        font-family: Lexend;
        font-size: 4rem;
        font-weight: 600;
        line-height: 83px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #164766;
    }

    .contentContainer h2 {
        font-family: Lexend;
        font-size: 3rem;
        font-weight: 600;
        line-height: 62px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #164766;
    }

    .contentContainer h3 {
        font-family: Lexend;
        font-size: 2rem;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #164766;
    }

    .contentContainer h4 {
        font-family: Lexend;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 31px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #164766;
    }

    .contentContainer h5 {
        font-family: Lexend;
        font-size: 1.25rem;
        font-weight: 350;
        line-height: 26px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #164766;
    }

}