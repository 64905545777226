
.banner2 {
  padding: 0px 72px;
  margin-top: 30px;
}


.artice_banner2 {
  padding: 64px  49.5px;
  border-radius: 24px;
  display: flex;
  gap: 64px;
}

.art_desc {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.art_desc2 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.article_image2 {
  width: 501px !important;
  height: 501px !important;
}
.article_image2 img {
  border-radius: 16px;
  width: 501px !important;
  height: 501px !important;
}

.art_button {
  background-color: #0671e00d;
  overflow: hidden;
  border: none;
  padding: 5px 15px;
  color: #0671e0;
  border-radius: 24px;
  font-weight: 500;
  width: fit-content;
  font-size: 24px;
  cursor: default !important;
}

.artice_banner2 h2 {
  font-size: 64px;
  font-weight: 600;
  color:  #0B2333;
  line-height: 83.2px;
}

.artice_banner2 p {
  font-weight: 350;
  padding: 16px 0px;
  font-size: 20px;
  line-height: 26px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}

.art_button_2 {
  overflow: hidden;
  border: solid 3px #0671e0;
  color: #0671e0;
  padding: 10px 35px;
  width: fit-content;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 450;
  line-height: 30px;
  display: flex;
  gap: 16px;
  align-items:center;
  justify-content: center;
  background-color: transparent;
}

.art_time {
  display: flex;
  width: fit-content;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  color: #515C7B;
  align-items: center;
  justify-content: center;
  font-weight: 350;
}
.art_time img {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

@media (max-width: 1200px) {
  .art_time img {
    width: 20px;
    height: 20px;
    margin: 0px 5px;
  }


  .artice_banner2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {

  .banner2 {
    padding: 16px 0px;
    margin-top: 0px;
  }

  .artice_banner2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 32px;
  }

  .art_desc {
    gap: 10px;
  }


  .article_image2 {
    width: 340px !important;
    height: 340px !important;
  }
  .article_image2 img {
    border-radius: 16px;
    width: 340px !important;
    height: 340px !important;
  }
  


  .art_button{

    font-size: 16px;
    padding: 5px 15px;
  }




  .artice_banner2 h2 {
    font-size: 32px !important ;
    line-height: 43.2px !important;
    margin: 0 !important ;
  }
  .artice_banner2 p {
    display: none !important;
  }

  .art_desc h2 {
    font-size: 28px;
    line-height: 33.6px;
  }

  .art_desc p {
    font-size: 16px;
    line-height: 18.2px;
    height: 36px;
    -webkit-line-clamp: 2;
  }

  .art_button_2 {
    font-size: 14px;
    line-height: 17.5px;
    padding: 14px 32px;
  }

  .art_time {
    font-size: 16px;
    line-height: 20.8px;
  }
  .art_time img {
    width: 20px;
    height: 20px;
    margin: 0px 5px;
  }
}
