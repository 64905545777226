.meteorite-1{
    position: absolute;
    animation: test_sm 5s linear infinite;
    opacity: 0;
    left : 50px;
    top : 0px;

}

.meteorite-2{
    position: absolute;
    left : 250px;
    animation: test_sm  7.5s linear 1.25s infinite;
    opacity: 0;
    top : 0px;
}
.meteorite-3{
    position: absolute;
    left : 450px;
    animation: test_sm  4.5s linear 0.25s infinite;
    opacity: 0;
    top : 0px;
}

.meteorite-4{
    position: absolute;
    left : 650px;
    animation: test_sm  7.6s linear 0.75s infinite;
    opacity: 0;
    top : 0px;
}
.meteorite-5{
    position: absolute;
    left : 850px;
    animation: test_sm  7s linear 1s infinite;
    opacity: 0;
}

.meteorite-6 {
    position: absolute;
    left : 1050px;
    animation: test_sm  8s linear 1.4s infinite;
    opacity: 0;
    top : 0px;
}

.meteorite-7{
    position: absolute;
    left : 1250px;
    animation: test_sm 10s linear 1.75s infinite;
    opacity: 0;
    top : 0px;
}
.meteorite-8{
    position: absolute;
    left : 1450px;
    animation: test_sm 6s linear 1.8s infinite;
    opacity: 0;
    top : 0px;
}

/*These meteorites are not to be used*/
.meteorite-9{
    display: none;
    position: absolute;
    left : 420px;
    animation: test_sm 6.4s linear 4s infinite;
    opacity: 0;
    top : 0px;
}

.meteorite-10 {
    display: none;
    position: absolute;
    left : 320px;
    animation: test_sm  8s linear 2.4s infinite;
    opacity: 0;
    top : 0px;
}

.meteorite-11{
    display: none;
    position: absolute;
    left : 450px;
    animation: test_sm 5.4s linear 3.75s infinite;
    opacity: 0;
    top : 0px;
}

.meteorite-12{
    display: none;
    position: absolute;
    left : 500px;
    animation: test_sm 6s linear 0.8s infinite;
    opacity: 0;
    top : 0px;
}


@media (min-width : 992px) {
    .meteorite-1{
        position: absolute;
        left : 700px;
        animation: test 5s linear infinite;
        opacity: 0;
        top : 0px;
    }
    
    .meteorite-2{
        position: absolute;
        left : 900px;
        animation: test  7s linear 1.25s infinite;
        opacity: 0;
        top : 0px;
    }
    .meteorite-3{
        position: absolute;
        left : 800px;
        animation: test  5.5s linear 0.25s infinite;
        opacity: 0;
    }
    
    .meteorite-4{
        position: absolute;
        right : 1800px;
        animation: test  7.6 linear 0.75s infinite;
        opacity: 0;
        top : 0px;
    }
    .meteorite-5{
        position: absolute;
        left : 1400px;
        animation: test  7s linear 1s infinite;
        opacity: 0;
        top : 0px;
    }
    
    .meteorite-6 {
        position: absolute;
        left : 1200px;
        animation: test  8s linear 1.4s infinite;
        opacity: 0;
        top : 0px;
    }
    
    .meteorite-7{
        position: absolute;
        left : 1500px;
        animation: test 4.4s linear 1.75s infinite;
        opacity: 0;
        top : 0px;
    }
    .meteorite-8{
        position: absolute;
        left : 1600px;
        animation: test 6s linear 1.8s infinite;
        opacity: 0;
        top : 0px;
    }
    .meteorite-9{
        position: absolute;
        left : 1800px;
        animation: test 6.4s linear 4s infinite;
        opacity: 0;
        top : 0px;
    }
    
    .meteorite-10 {
        position: absolute;
        left : 2200px;
        animation: test  8s linear 2.4s infinite;
        opacity: 0;
        top : 0px;
    }
    
    .meteorite-11{
        position: absolute;
        left : 2000px;
        animation: test 4.4s linear 3.75s infinite;
        opacity: 0;
        top : 0px;
    }

    .meteorite-12{
        position: absolute;
        left : 1900px;
        animation: test 6s linear 0.8s infinite;
        opacity: 0;
        top : 0px;
    }

}

.trail{
    height: 6px;
    border-radius: 25px;
    width: 150px;
    background-image: linear-gradient(to right, rgba(255,255,255,0.9),rgba(255,255,255,0.1));
}

.meteor{
    height: 8.5px;
    width: 8.5px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 10px 0px 15px white,0px 10px 15px white, 0px -10px 15px white,-10px 0px 15px white;
}



@keyframes test {
    0% {
        opacity: 1;
        transform: rotate(-45deg) translate(0px,0px);
    }

    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(-200vw,0px) 
    }
}

@keyframes test_sm {
    0% {
        opacity: 1;
        transform: rotate(-45deg) translate(0px,0px);
    }

    100% {
        opacity: 0;
        transform: rotate(-45deg) translate(-300vw,0px) 
    }
}



