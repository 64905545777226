.simpleForm {
    width: 444px;
    border-radius: 8px;
    border: 3px solid #ffffff;
    background: #ffffff;
    padding: 32px 45px 32px 45px;
    box-shadow: 0px 0px 4px 0px #00000040;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.simpleForm h6 {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #25335A;
    text-wrap: nowrap;
}

.simpleForm h6>span {
    color: #0671E0;
}

.inputFieldContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.inputFieldContainer input {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 7.5px 12px;
    border-radius: 6px;
    background: #F6F9FA;
    outline: none;
}

.inputFieldContainer input:focus {
    border: 1px solid #0671E0;
}

.inputFieldContainer input::placeholder {
    color: #7C859C;
}

.submitBtn {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    width: 100%;
    height: 58px;
    background: #0671E0;
    /* border: 2px solid linear-gradient(92.16deg, #D8EBFF 0%, #0671E0 35.18%, #0671E0 62.08%, #D8EBFF 92.37%); */
    text-align: center;
    border-radius: 28px;
    border: none;
}

.submitBtn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.submitBtn span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.submitBtn:hover span {
    padding-right: 15px;
}

.submitBtn:hover span:after {
    opacity: 1;
    right: 0;
}

@media screen and (max-width: 768px) {
    .simpleForm {
        width: 302px;
        padding: 18px 24px;
        gap: 16px;
    }

    .submitBtn {
        margin-bottom: 0;
    }

    .simpleForm h6 {
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #25335A;
        text-wrap: nowrap;
    }

    .submitBtn {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        width: 170px;
        height: 34px;
        margin: auto;
    }
}