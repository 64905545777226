/* Base styles */
.container{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: calc(100vw - 32px);
  align-self: center;
}
.cardSection {
    width: fit-content;
    /* width: 100%; */
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
    
  
  .cardContainer {
    max-width: calc(100vw - 32px);
    max-height: 622px;
    padding: 41px 46px;
    /* justify-content: center;
    align-items: center; */
  }
  
.card{
  
  width: 480px;
  height: 527px;
}


   @media (max-width: 768px) {
    .cardSection {
      grid-template-columns: 1fr;
      gap:30px
    }
    .cardContainer {

      max-width: 348px;
      max-height: 410px;
      padding: 0px;
      /* justify-content: center;
      align-items: center; */
    }
    .card{
  
      width: 348px;
      height: 410px;
    }
  }
  