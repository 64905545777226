@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
html{
  height: 100%;
}

body {
  margin: 0;
  font-family: "Lexend", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  padding: 0;
}


#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
}

@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }

}

em {
  font-style: italic;
}

.ql-toolbar {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 5;
  padding-top: 20px !important;
  height: auto;
  padding-bottom: 20px !important;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./fonts/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(./fonts/Gilroy-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: local('Gilroy-Heavy'), url(./fonts/Gilroy-Heavy.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(./fonts/Gilroy-Regular.ttf) format('truetype');
}


@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url(./fonts/Gilroy-SemiBold.ttf) format('truetype');
}


@font-face {
  font-family: 'Gilroy-UltraLight';
  src: local('Gilroy-UltraLight'), url(./fonts/Gilroy-UltraLight.ttf) format('truetype');
}


/*Media Queries for trying scaling, to be removed when pushing*/
/* @media (min-width : 1440px) {
  html {
    font-size: 1.1vw;
  }
}  */