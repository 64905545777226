.outerContainer {
    position: fixed;
    top : 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(17, 53, 77, 0.2);
    z-index: 105;
}