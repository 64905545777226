.thankYouCardContainer {
    width: 640px;
    border-radius: 12px;
    padding: 77px 24px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px #11167D26;
    display: flex;
    flex-direction: column;
    gap: 42px;
}

.thankYouCardContainer>h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #25335A;
}

.thankYouCardContainer>div {
    width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
}

.thankYouCardContainer>div>img {
    width: 134px;
}

.thankYouCardContainer>div>div {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #515C7B;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .thankYouCardContainer {
        width: 342px;
        padding: 92px 16px;
        gap: 30px;
    }

    .thankYouCardContainer>div {
        width: 100%;
    }

    .thankYouCardContainer>div>img {
        width: 97px;
    }


    .thankYouCardContainer>h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        text-align: center;
        color: #515C7B;
    }

    .thankYouCardContainer>div>div {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }

}