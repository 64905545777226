.container {
  display: flex;
  flex-direction: column;
  gap: 37px;
  margin-top: 73px;
  margin-bottom: 88px;
  max-width: 100%;
  overflow: hidden;
}
.textWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.text1 {
  color: #33373b;
  text-align: center;
  font-family: "Lexend";
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 63px */
  letter-spacing: -0.84px;
}
.text2 {
  color: #9aa1a8;
  font-family: "Lexend";
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.84px;
}

.scrollDiv {
  display: flex;
  justify-content: center;
}

.productList {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 37px;
  width: 794px;
}

.productContainer {
  width: 240px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 45px;
  border: 0.8px solid #d5dee7;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 9px;
  cursor: pointer;
}
.iconDiv {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 100%;
  background-color: #f1f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleDiv {
  color: var(--Black-text-color, rgba(0, 0, 0, 0.9));
  font-family: "Lexend";
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 33px */
  text-transform: capitalize;
}
@media (max-width: 768px) {
  .textWrapper {
    flex-direction: column;
  }
  .text1 {
    font-size: 28px;
    line-height: 150%; /* 42px */
    letter-spacing: -0.56px;
    text-transform: capitalize;
  }
  .text2 {
    font-size: 28px;
    line-height: 150%;
    letter-spacing: -0.56px;
    text-transform: capitalize;
  }
  .scrollDiv {
    overflow-x: auto;
    width: 100%;
    max-width: calc(100vw - 48px);
    padding-bottom: 8px;
    justify-content: start;
  }
  .productContainer {
    padding: 6px;
    padding-right: 23px;
    height: 48px;
    width: fit-content;
  }
  .iconDiv{
    width: 36px;
    height: 36px;
  }
  .titleDiv {
    color: #0671e0;
    font-family: "Lexend";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 27px */
  }
  .productList{
    gap: 14px;
  }
}
