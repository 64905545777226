.pricing-details-list {
    list-style-type: none;
    font-family: Lexend;
    font-size: 18px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #164766;
}

.price-details-container {
    background-color: #F0F9FE;
    border: 1px solid #B5CDDD;
    border-radius: 24px;
    margin-top: 20px;
    padding-left : 16px;
    padding-right: 16px;
}

.price-features-li {
    margin-top: 28px;
}


@media (min-width : 992px) {
    li>img {
        margin-left: -30px;
    }
}