.card {
    width: 494px;
    height: 540px;
    border-radius: 13px;
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.05);
    background-image: url(/public/assets/images/bluecard2.png);
    overflow: hidden;
    max-width: 527px;
    max-height: 540px; 
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  /* .cardInner {
    border-radius: 10px;
    overflow: hidden;
  } */
  
  /* .cardImage {
    position: absolute;
  } */
  
 
  
  .cardContent {
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 90px;
    gap:20px;
    z-index: 1000;
  }
  
  .cardTitle {
    font-weight: 300;
    font-size: 28px;
    line-height: 36px;
    color: #FFFFFF;
    margin: 0 0 15px;
  }

  .backgroundImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 10;
  }


  .button {
    font-family: 'Lexend';
    background: #FFFFFF;
    border-radius: 90px;
    color: #6CACEC;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 9px 18px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 4px #BCD9F6;
  }
  
  .arrow {
    width: 5px;
    height: 5px;
    border-right: 1.3px solid #6CACEC;;
    border-bottom: 1.3px solid #6CACEC;;
    transform: rotate(-45deg);
    margin-left: 10px;
  }
  
  
  @media (max-width: 768px) {
    .card {
      width: 384px;
      border-radius: 13px;
      box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.05);
      overflow: hidden;
      max-width: 384px;
      max-height: 410px; 
    }
    
    /* .cardInner {
      border-radius: 10px;
      overflow: hidden;
    } */
    
    /* .cardImage {
      position: absolute;
    } */
    
   
    
    .cardContent {
      padding-top: 50px;
      padding-left: 40px;
      padding-right: 20px;
      gap:20px;
      z-index: 1000;
    }
    
    .cardTitle {
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #FFFFFF;
      margin: 0 0 15px;
    }
  
    .backgroundImage {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10;
    }
  
  
    .button {
      font-family: 'Lexend';
      background: #FFFFFF;
      border-radius: 90px;
      color: #6CACEC;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 9px 18px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 3px #BCD9F6;
    }
    
    .arrow {
      width: 5px;
      height: 5px;
      border-right: 1.3px solid #6CACEC;;
      border-bottom: 1.3px solid #6CACEC;;
      transform: rotate(-45deg);
      margin-left: 10px;
    }
  
  }
    
    