.team-profile h6 {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--primary-700);
}

.team-profile p {
    font-size: 16px;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #515C7B;
}

.team-profile>div>div:first-child {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 768px) {

    .team-profile h6 {
        font-size: 18px;
        font-weight: 400;
        /* line-height: 23px; */
        letter-spacing: 0em;
        text-align: center;

    }

    .team-profile p {
        font-size: 16px;
        font-weight: 350;
        line-height: 8px;
        letter-spacing: 0em;
        text-align: center;

    }


}