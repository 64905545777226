    .container {
    padding : 1.5rem 1.5rem 0 1.5rem;
    background: #F1F7FB;
    gap : 2rem;
    border-radius: 0.875rem;
    width: 100%;
    height: 100%;
}

.heading {
    font-family: Lexend;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #07151F;
}

.description {
    font-family: Lexend;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #25335A;
}

.image {
    width: 100%;
    object-fit: contain;
}

@media (min-width : 992px) {
    .container {
        padding : 2.5rem 2.5rem 0 2.5rem;
        background: #F1F7FB; 
        gap : 2rem;
        width: 100%;
        height: 100%;
    }
    
    .heading {
        font-family: Lexend;
        font-size: 2rem;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #07151F;
    }
    
    .description {
        font-family: Lexend;
        font-size: 1rem;
        font-weight: 350;
        line-height: 20px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #25335A;
    }
    
}