.blog_banner {
  padding: 24px;
  border-radius: 24px;
  border: solid 4px #f1f7fb;
  display: flex;
  gap: 32px;
  align-items: start;
  justify-content: start;
}

.mblog_banner {
  display: none;
}

.blog_desc {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.blog_image {
  width: 260px !important;
  height: 260px !important;
}
.blog_image img {
  width: 260px !important;
  height: 260px !important;
  border-radius: 16px;
}

.blog_button {
  background-color: #0671e00d;
  overflow: hidden;
  border: none;
  padding: 5px 15px;
  color: #0671e0;
  border-radius: 24px;
  font-weight: 450;
  width: fit-content;
  font-size: 20px;
  cursor: default !important;
}

.blog_desc h2 {
  font-size: 32px;
  font-weight: 600;
  color: #11354d;
  line-height: 38.4px;
}

.blog_desc p {
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;

  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.blog_button_2 {
  overflow: hidden;
  border: solid 3px #0671e0;
  color: #0671e0;
  padding: 5px 35px;
  width: fit-content;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 450;
  line-height: 30px;
  background-color: transparent;
}

.blog_time {
  display: flex;
  width: fit-content;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  color: #515c7b;
  align-items: center;
  justify-content: center;
}
.blog_time img {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

@media (max-width: 768px) {
  .blog_banner {
    display: none;
  }

  .mblog_banner {
    padding: 24px;
    border-radius: 24px;
    border: solid 4px #f1f7fb;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;
    justify-content: center;
  }

  .mblog_desc {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .mblog_image {
    width: 100px !important;
    height: 100px !important;
  }
  .mblog_image img {
    width: 100px !important;
    height: 100px !important;
    border-radius: 17.37px;
  }

  .mblog_button {
    background-color: #0671e00d;
    overflow: hidden;
    border: none;
    padding: 5px 15px;
    color: #0671e0;
    border-radius: 24px;
    font-weight: 450;
    width: fit-content;
    line-height: 18.2px;
    font-size: 14px;
  }

  .mblog_desc h1 {
    font-size: 16px;
    font-weight: 600;
    color: #11354d;
    line-height: 21.6px;
  }

  .mblog_time {
    display: flex;
    width: fit-content;
    font-size: 14px;
    font-weight: 350;
    line-height: 18.2px;
    color: #515c7b;
    align-items: center;
    justify-content: center;
  }
  .mblog_time img {
    width: 14px;
    height: 14px;
    margin: 0px 5px;
  }

  .msummary {
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 350;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }

  .mblog_button_2 {
    overflow: hidden;
    border: solid 3px #0671e0;
    color: #0671e0;
    padding: 10px 20px;
    width: fit-content;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 450;
    line-height: 18.2px;
    background-color: transparent;
  }
}
