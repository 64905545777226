.our-team {
    margin: 20px 0;
}

.our-team-title h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #25335A;


}

.our-team-title p {
    font-size: 14px;
    font-weight: 350;
    line-height: 19px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #515C7B;

}




@media (min-width: 768px) {
    .our-team {
        padding: 0 110px;
    }

    .our-team-title h1 {
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: center;

    }

    .our-team-title p {
        /* width: 70%; */
        /* padding: 0 12rem; */
        width: 768px;
        margin: auto;
        font-size: 24px;
        font-weight: 350;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: center;

    }

    .our-team-info>div {}

    .our-team-info {
        margin: 60px 0;
    }


}