h1,
h2,
h3,
h4,
h5,
h6 {
    color: #164766;
}

.title {
    color: #164766;
    font-family: Lexend;
    font-size: 2rem;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.05em;
    text-align: center;
}

.pp_container {
    padding: 0 15%;
}

.pp_container p {
    color: #4683AA;
    font-family: Lexend;
    font-size: 1rem;
    font-weight: 350;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justify;

}

.pp_container li {
    list-style: none;
    margin: 9px 0;
    color: #4683AA;
}

.pp_container a {
    color: #164766;
    text-decoration: underline;
}

@media (min-width : 992px) {
    .title {
        font-family: Lexend;
        font-size: 4rem;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: -0.05em;
        text-align: center;
    }

    .description {
        font-family: Lexend;
        font-size: 1.25rem;
        font-weight: 350;
        line-height: 26px;
        letter-spacing: -0.03em;
        text-align: justify;
    }
}