.video-card {
    /* max-width: 560px; */
    padding: 40px;
    /* padding: 2.8vw; */
    border-radius: 24px;
    gap: 40px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(4px);
    background-image: radial-gradient(circle at 100% 100%, transparent 20px, #ffffff 20px, #ffffff39 24px, transparent 24px), linear-gradient(to right, #ffffffa8, #ffffff15), radial-gradient(circle at 0% 100%, transparent 20px, #ffffff0f 20px, #ffffff35 24px, transparent 24px), linear-gradient(to bottom, #ffffff38, #ffffff), radial-gradient(circle at 0% 0%, transparent 20px, #ffffff 20px, #ffffff 24px, transparent 24px), linear-gradient(to left, #ffffffa9, #ffffff62), radial-gradient(circle at 100% 0%, transparent 20px, #ffffff2f 20px, #ffffff2d 24px, transparent 24px), linear-gradient(to top, #ffffff40, #ffffff);
    background-size: 24px 24px, calc(100% - 48px) 4px, 24px 24px, 4px calc(100% - 48px);
    background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
    background-repeat: no-repeat;
}

/* px-3 py-4 p-lg-4 */

.video-card>div:first-child {
    height: 60%;
}

.video-card h2 {
    font-size: 32px;
    /* font-size: 2.2vw; */
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: left;
    color: var(--primary-800);

}

.video-card p {
    font-size: 18px;
    /* font-size: 1.3vw; */
    font-weight: 350;
    line-height: 25px;
    /* line-height: 1.6vw; */
    letter-spacing: -0.03em;
    text-align: left;
    color: var(--primary-700);
    opacity: 70%;

}

.video-card img {
    width: 100%;
}

.play-btn,
.doc-btn {
    background: #0671E0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 12px 18px;
    /* padding: .9vw 1.3vw; */
    border-radius: 72px;
    border: none;
    color: #fff;


}

.doc-btn {
    padding: 11px 20px;
    border: 2px solid #0671E0;
    color: #0671E0;
    background: none;
    font-weight: 500;
}

.play-btn img {
    width: 24px;
}

@media (max-width: 768px) {
    .video-card>div:first-child {
        height: auto;
    }

    .video-card {
        padding: 24px 20px;
    }

    .video-card h2 {
        font-size: 32px;
    }

    .video-card p {
        font-size: 14px;
        line-height: 18px;
    }

    .play-btn,
    .doc-btn {
        padding: 12px 16px;
        gap: 8px;
        font-size: 12px;
    }

    .play-btn img {
        width: 16px;
    }
}