/* ------------- Support & FAQs Css --------------- */

.background-grid {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    height: auto;

}

.background-grid img {
    width: 120%;
}

.color-spread {
    width: 36%;
    height: 50px;
    background: linear-gradient(180deg, rgba(229, 240, 247, 1) 23%, rgba(229, 240, 247, 0.5018601190476191) 100%);
}

.support-container {
    padding: 32px 40px 32px 40px;
}


.faq-title-1{
    color : #BEC2CE;
    width: fit-content;
}
.faq-title-2{
    color : #07151F;
}



@media (max-width: 768px) {

    .support-container {
        padding: 10px;
    }


    .home-faq-title-container {
        font-family: Lexend;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.05em;
        text-align: center;
    }

    .faq-title-1 {
        color: #BEC2CE;
        width: 100%;
    }

    .faq-title-2 {
    color : #07151F;
    }


}