label {
    font-weight: 500;
    font-size: 1.5rem;
    color : #164766;
}

.editor {
    background-color: white;
}

.infoMessage {
    background-color: #164766;
    color: white;
    width: fit-content;
    border-radius: 1rem;
    padding : 0.5rem;
}

.topBarContainer {
    grid-row: 1 / 2;
}

.contentContainer {
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: 75% 1fr;
    height: 100%;
}

.createDocButton {
    padding : 0.5rem 1rem;
    border:none;
    background-color: #0953E5;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
}

.columnOne {
    height: 100%;
    grid-column: 1 / 2;
    border-top: 1px solid gray;
}

.columnTwo {
    height: 100%;
    grid-column: 2 / 3;
    border-left: 1px solid gray;
    border-top: 1px solid gray;
}

.previewButton {
    display: flex;
    padding : 0.5rem 1rem;
    background-color: white;
    border-radius: 7px;
    border: 1px solid gray;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

}

.titleInput{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    font-size: 3.5rem;
}

.titleInput::placeholder, .titleInput:focus {
    font-size: 3.5rem;
    color: gray;
    background-color: rgba(255, 255, 255, 0);
    border: none;
}

.titleInput:focus{
    color : #164766;
}

.sidebar_container {
    padding: 1rem;
    height: 100%;

}

.blog_sidebar {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.blog_sidebar>div {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.blog_sidebar>div input,
textarea,
select {
    padding: 8px;
    width: 100%;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #0000000F;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.blog_sidebar>div input:focus,
textarea:focus,
select:focus {
    outline: 1px solid gray;
}



.blog_sidebar>div label {
    font-size: 14px;
    font-weight: 350;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #292C33;
    padding-bottom: 8px;
}

.blog_sidebar>div span {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.15px;
    text-align: left;
    color: #828792;
}

::-webkit-input-placeholder,
::-webkit-keygen-select {
    font-size: 14px;
    /* font-weight: 400; */
    color: #292C33;
}

.previewImage {
    border-radius: 0.625rem;
    border: 1px solid #0000000F;
    padding: 0.5rem;
    object-fit: contain;
    max-width: 100%;
}

