.featuresContainer {
    padding: 64px 0 48px 52px;
    display: grid;
    grid-template-columns: 46% 54%;
}

.featuresSectionLeft {}

.featuresSectionRight {}

.expandCard {
    box-shadow: none !important;
    margin-top: 24px;
    position: relative;
}

.activeLine {
    min-height: calc(100% - 88px);
    /* border: 1px solid; */
    border-left: 1px solid #0671E0;
    position: absolute;
    left: 40px;
    top: 72px;
}

.expandCard::before {
    background: none !important;
}

.expandCardContainer {
    padding: 0 16px;
}

.accordionDeatils {
    padding: 0px !important;
}

.expandCardTab {
    display: flex;
    align-items: center;
    gap: 12px;


}

.expandCardTab>div {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    opacity: 0.9;
}

.activeExpandCardTab {
    display: flex;
    align-items: start;
    gap: 12px;

}

.activeExpandCardTab {
    font-weight: 400;
    font-size: 48px;
    line-height: 64px;
    color: #000000;
    max-width: 100%;
    opacity: 0.9;
    text-wrap: wrap;
}

.featuresSectionRight>img {
    width: 100%;
    object-fit: contain;
}

.activeCircle {
    min-height: 48px;
    min-width: 48px;
    max-height: 48px;
    max-width: 48px;

    border: 8px solid #0671E0;
    border-radius: 100%;
}