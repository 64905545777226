.video-btn {
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 50px;
    border: 2px solid #0671E0;
    gap: 6px;
    background: none;
    color: #0671E0;
    font-weight: 500;
}

.video-btn img {
    height: 18px;
    width: 18px;
}

@media (min-width: 768px) {
    .video-btn {
        font-size: 18px;
        padding: 16px 24px;
        border-radius: 81px;
        border: 2px solid #0671E0;
        gap: 8px;

    }

    .video-btn img {
        height: 24px;
        width: 24px;
    }
}