.padding-cards img {
    width: 100%;
}

.padding-cards {
    background: var(--items-container-bg);
    padding: 40px;
    border-radius: 24px;
}

.padding-cards h2 {
    font-size: 32px;
    /* font-size: 2.3vw; */
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: left;
    color: var(--primary-800);
}

.padding-cards p {
    font-size: 16px;
    /* font-size: 1.1vw; */
    font-weight: 350;
    line-height: 20px;
    /* line-height: 1.4vw; */
    /* letter-spacing: -0.03em; */
    text-align: left;
    color: var(--primary-700);
    opacity: 70%;
}

.padding-card-init {
    opacity: 0;
}

.popIn {
    animation: pop-in 0.5s ease-out;
    /* animation-timeline: view(80% 0%);  */
}


@keyframes pop-in {
    from {
        margin-top: 175px;
        opacity: 0;
    }

    to {
        margin-top: 0px;
        opacity: 1;
    }
}


@media (max-width: 768px) {
    .padding-cards h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: left;
        color: var(--primary-800);
    }

    .padding-cards p {
        font-size: 14px;
        font-weight: 350;
        line-height: 17px;
        letter-spacing: -0.03em;
        text-align: left;
        color: var(--primary-700);
        /* opacity: 70%; */
    }

    .padding-cards {
        padding: 30px;
    }
}