.outerContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #11354d42;
}

.innerContainer {
    padding: 0;
    margin: 0;
}

@media (max-width : 378px) {
    .outerContainer {
        align-items: flex-start;
        overflow-y: scroll;
    }

    .innerContainer {
        height: 350px;
    }
}

@media (max-width : 768px) {
    .outerContainer {
        padding: 2rem;
        overflow-y: scroll;
    }
}