:root {
    --radius_inner : calc(317px/2);
    --radius_middle : calc(412px/2);
    --radius_outer : calc(487px/2)
}

.subheading-container-2-home-bs {
    font-size: 10px;
    padding : 4px 10px;
    border-radius: 8px;
    font-family: Lexend;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0.05em;
    text-align: center;
    background-color: #FFFFFF;
    color: #216A99;
}


.client_headbox{
    width: 100%;
    max-width: 100dvw !important;
    overflow: hidden;
    padding: 0px 10px;
    margin-top: -10px;

}
.clients_container{
    width: 100%;
    backdrop-filter: blur(40px);
    display: flex;
    flex-direction:  column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    border-radius: 27px;
    padding: 0 !important;
    margin: 0 !important;


}

.clients_heading{
    margin: 0px 19px ;
    margin-top: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.clients_heading h1{
    font-size: 32px !important;
    line-height: 41.6px;
    font-weight: 600;
    text-align: center;
    color: #07151F !important;
}

.clients_heading p{
    font-size: 16px !important;
    line-height: 20.8px;
    font-weight: 350;
    text-align: center;
}

.clients_carousel{
    padding: 0px 10px;
    padding-bottom: 30px;
    width: 100% !important;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    /* border: solid 2px black; */
}



@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-150px * 7));
	}
}

.slider {
	height: 40px;
	overflow: hidden;
	position: relative;
	width: 100%;
    max-width: 100%;
}

.slider::before,
.slider::after {

    background: linear-gradient(
		to right,
		#e6eff5a3 0%,
		#e6eff555 100%
	);
	content: "";
	height: 77px;
	position: absolute;
	width: 50px;
	z-index: 2;
}

.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.slider::before {
	left: 0;
	top: 0;
}

.slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(150px * 14);

}

.slide {
	height: 40px;
	width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px !important;
}

.slide img{
    max-width: 100%;
    max-height: 100%;
}



#inner,
#middle,
#outer {
    display: none;
    height: 0px;
}

.brands-pill-content {
    font-family: Lexend;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.03em;
    text-align: center;    
    color: #515C7B;
}

.orbit>.brands-pill-content {
    display: none;
}



#logo-container {
    background-color: #FAFAFA;
    border-radius: 50%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    box-shadow: 5.201793670654297px 5.201793670654297px 16.645740509033203px 0px #FFFFFF inset,-5.201793670654297px -5.201793670654297px 22.75784683227539px 0px #FFFFFF inset,0px 5.201793670654297px 31.21076202392578px 0px #FFFFFF26;
}

#logo-container>img {
    height: 66px;
    width: 78px;
}


.brand-pill-content+img {
    height: 33px;
    width: 33px;
}



#stp,
#admin,
#invoice,
#ag,
#crm,
#ref,
#ss {
    position: absolute;
}

@media (min-width : 992px){
    .subheading-container-2-home {
        font-size: 16px;
    }

    .subheading-container-2 + img{
        height: 25vw;
    }
}


@media (min-width : 1200px) {

    :root {
        --radius_inner : calc(583.5px/2);
        --radius_inner_opp : calc(-583.5px/2);
        --radius_middle : calc(890.1px/2);
        --radius_middle_opp : calc(-890.1px/2);
        --radius_outer : calc(1238.7px/2);
        --cord_p : calc(438px);
        --cord_n : calc(-438px);
        --r_test : calc(-1238.7px/2);

        
    }


    .subheading-container-2-home-bs {
        font-family: Lexend;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.05em;
        padding: 8px 20px;
        text-align: center;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 0px #FFFFFF;  

    }
    

    .brands-pill-content {
        font-family: Lexend;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.03em;
        text-align: left;        
        white-space: pre-wrap;
        position : absolute;
        left : 80px;
        color: #515C7B;

    }

    .client_headbox{
        width: 100%;
        max-width: 100%;
        padding: 0px 80px;
        border: none;
    }
    .clients_container{
        width: 100%;
        backdrop-filter: blur(40px);
        display: flex;
        flex-direction:  column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        border-radius: 27px;
      
    
    }

    .clients_heading{
        margin: 60px 315px ;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .clients_heading h1{
        font-size: 64px !important;
        line-height: 83.2px;
        font-weight: 600;
        text-align: center;
        color: #07151F !important;
    }

    .clients_heading p{
        font-size: 24px !important;
        line-height: 31.2px;
        font-weight: 350;
        text-align: center;
    }

    .clients_carousel{
        padding: 0px 10px;
        padding-bottom: 70px;
        width: 100%;
        display: flex;
    }

   .slider{
    width: 100%;
   }

   @keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-200px * 7));
	}
}

.slider {
	height: 77px;
	overflow: hidden;
	position: relative;
	width: 100%;
    max-width: 100%;
}

.slider::before,
.slider::after {

    background: linear-gradient(
		to right,
		#e6eff5a3 0%,
		#e6eff555 100%
	);
	content: "";
	height: 77px;
	position: absolute;
	width: 150px;
	z-index: 2;
}

.slider::after {
	right: 0;
	top: 0;
	transform: rotateZ(180deg);
}

.slider::before {
	left: 0;
	top: 0;
}

.slide-track {
	animation: scroll 40s linear infinite;
	display: flex;
	width: calc(200px * 14);

}

.slide {
	height: 77px;
	width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px !important;
}

.slide img{
    max-width: 100%;
    max-height: 100%;
}



    #logo-container {
        background-color: #FAFAFA;
        border-radius: 50%;
        padding: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 290px;
        width: 290px;
        background-image : radial-gradient(0% 50%, #FAFAFA 80%, #FFFFFF 100%);
        box-shadow: 4px 4px 12.800000190734863px 0px #FFFFFF inset, -4px -4px 17.5px 0px #FFFFFF inset, 0px 4px 24px 0px #FFFFFF26;
        
    }

    #logo-container>.img {
        height: 104px;
        width: 120px;
    }

    #inner {
        height: 583.5px;
        width: 583.5px;    
    }


    
    #middle {
        height: 890.1px;
        width: 890.1px;
        
    }
    
    #outer {
        margin: 20px;
        height: 1238.7px;
        width: 1238.7px;
    }
    
    #inner,
    #middle,
    #outer {
        border : 2px solid #988F8F60;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

    }

    .brand-pill-content+img {
        height: 48px;
        width: 48px;
    }
    
    .brand-section{
        background-image: radial-gradient(49.93% 70.21% at 50% 50%, #FFFFFF 0%, #E7F1F8 60%);
    }

    #stp,
    #admin,
    #invoice,
    #ag,
    #crm,
    #ref,
    #ss{
        position: absolute;
    }

    #stp {
        animation: inner 50s linear infinite;
    }

    #admin {
        animation: inner_opp 50s linear infinite;
    }

    #invoice{
        animation: middle 70s linear infinite;
    }
    #ag{
        animation: middle_opp 70s linear infinite;
    }

    #crm{
        animation: outer 60s linear infinite;
    }
    #ref{
        
        animation: outer_1 60s linear infinite;
    }
    #ss{
        animation: outer_2 60s linear infinite;
    }

}



@keyframes inner {

    from {
        transform: rotate(0deg) translateX(var(--radius_inner)) rotate(0deg) ;
    }
    to {
        transform: rotate(360deg) translateX(var(--radius_inner)) rotate(-360deg) ;
    }
}

@keyframes inner_opp{

    from {
        transform: rotate(0deg) translateX(var(--radius_inner_opp)) rotate(0deg) ;
    }
    to {
        transform: rotate(360deg) translateX(var(--radius_inner_opp)) rotate(-360deg) ;
    }
}


@keyframes middle {
    from {
        transform: rotate(0deg) translateX(var(--radius_middle)) rotate(0deg) ;
    }
    to {
        transform: rotate(-360deg) translateX(var(--radius_middle)) rotate(360deg) ;
    }
}

@keyframes middle_opp {
    from {
        transform: rotate(0deg) translateX(var(--radius_middle_opp)) rotate(0deg) ;
    }
    to {
        transform: rotate(-360deg) translateX(var(--radius_middle_opp)) rotate(360deg) ;
    }
}

@keyframes outer {
    from {
        transform: rotate(0deg) translateX(var(--radius_outer)) rotate(0deg) ;
    }
    to {
        transform: rotate(360deg) translateX(var(--radius_outer)) rotate(-360deg) ;
    }
}

@keyframes outer_1 {
    from {
        transform: rotate(0deg) translate(var(--cord_n),var(--cord_p)) rotate(0deg) ;
    }
    to {
        transform: rotate(360deg) translate(var(--cord_n),var(--cord_p)) rotate(-360deg) ;
    }
}

@keyframes outer_2 {
    from {
        transform: rotate(0deg) translate(var(--cord_n),var(--cord_n)) rotate(0deg) ;
    }
    to {
        transform: rotate(360deg) translate(var(--cord_n),var(--cord_n)) rotate(-360deg) ;
    }
}

@keyframes test_outer {
    from {
        transform: rotate(0deg) translateX(var(--r_test)) rotate(0deg) ;
    }
    to {
        transform: rotate(360deg) translateX(var(--r_test)) rotate(-360deg) ;
    } 
}



