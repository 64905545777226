.main_desc {
  /* border: solid 2px black; */
  padding: 64px 80px 0px 80px;
}
.main_desc2 {
  padding: 0px 108px;
}
.main_desc3 {
  display: flex;
  gap: 64px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;


  padding-bottom: 64px;

  border-bottom: solid 0.5px black;
}

.desc_first {
  padding: 33px 0px;

  max-width: 280px;

  display: flex;

  flex-direction: column;

  gap: 32px;
}

.desc_first h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #0b2333;
}

.desc_second {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  gap: 16px;
}

.desc_links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.desc_links button {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px;
  border-radius: 8px;
  border: solid 1px #d0d5dd;
  background-color: white;
  outline: none;
}

.blog_brief {
  width: 100% !important;
}

.heading-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.heading_item {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #90b4cc;
  cursor: pointer;

  width: fit-content;
}

.blog_brief h1 {
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 40px !important;
  color: antiquewhite;
}

.blog_brief h1,
.blog_brief h2,
.blog_brief h2,
.blog_brief h4,
.blog_brief h5,
.blog_brief h6 {
    margin: 0 !important;
  padding-bottom: 10px !important;
}

.blog_brief p {
  font-size: 18px !important;
  color: #0b2333 !important;
  line-height: 30px !important;

  margin: 0 !important;
}

.blog_brief img {
  width: 100% !important;
  height: 480px !important;
  border-radius: 18px;
  border: solid 8px #ebf7ff;
}

.blog_brief blockquote {

    border-left: solid 2px black;
    padding: 0px 15px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    line-height: 30px !important;

}

@media (max-width: 768px) {
  .desc_first {
    display: none;
  }

  .main_desc {
    /* border: solid 2px black; */
    padding: 0;
  }
  .main_desc2 {
    padding: 0px 32px 0px 32px;
  }

  .main_desc3 {
    
    padding-bottom: 32px;
  
    border-bottom: solid 0.5px black;
  }

  .blog_brief p {
    font-size: 16px !important;
    color: #0b2333 !important;
    line-height: 20px !important;
    font-weight: 400 !important;
  
    margin: 0 !important;
  }

  .blog_brief li{
    font-size: 13px !important;

  }
  
  .blog_brief img {
   
    height: 240px !important;
 
  }
  
  .blog_brief blockquote {
  
      border-left: solid 2px black;
      padding: 0px 15px !important;
      font-size: 24px !important;
      font-weight: 500 !important;
      line-height: 30px !important;
  
  }
}
