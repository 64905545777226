.titleAndDescContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.titleAndDescContainer>h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    margin: 0;
    color: #25335A !important;
}

.titleAndDescContainer div {
    /* width: 692px; */
    margin: auto;
    font-weight: 350;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #25335A;
}

@media screen and (max-width: 768px) {
    .titleAndDescContainer {
        gap: 6px;
        padding: 16px;
    }

    .titleAndDescContainer>h2 {
        font-size: 18px;
        line-height: 27px;
    }

    .titleAndDescContainer div {
        width: auto !important;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
}