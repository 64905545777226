.mainContainer {
  width: 100%;
  /* max-width: 1370px; */
  padding: 48px 67px 22px 67px;
  display: flex;
  justify-content: center;
  padding-bottom: 139px;
}

.inputStyles::-webkit-outer-spin-button,
.inputStyles::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.innerContainerSavings {
  width: 100%;
  /* max-width: 1236px; */
  display: flex;
  flex-direction: column;
  gap: 52px;
}

.savingsContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainHeading {
  color: #0671e0;
  text-align: center;
  font-family: "Lexend", sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.savingsContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.savingsInfo {
  color: #33373b;
  text-align: center;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 142%;
  margin-bottom: 27px;
  display: flex;
  width: 55%;
}

.savingsInputArea {
  display: flex;
  gap: 14px;
  justify-self: center;
}

.savingsInput {
  display: flex;
  width: 240px;
  height: 40px;
  padding: 0px 12px;
  align-items: center;
  gap: 5px;
  border: 0px;
  border-radius: 10px;
  background-color: #f6f6f6;
  background: #f6f6f6;
  color: #7C859C;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.savingsInput::placeholder {
  color: #7C859C;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}

.savingsInput:focus,
.savingsInput:active,
.savingsInput:focus-visible {
  border: none !important;
  outline: none;
}

.savingsButton {
  border-radius: 10px;
  background: #0671e0;
  width: 160px;
  height: 40px;
  flex-shrink: 0;
  border: 0;
  color: #f2f2f2;
  text-align: center;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}

.savingsDisplayArea {
  justify-self: center;
  min-width: 344px;
  max-width: fit-content;
  border-radius: 29px;
  border: 1px dashed #90aecc;
  display: flex;
  padding: 6px 19px;
  align-items: center;
  justify-items: center;
  gap: 13px;
  margin-top: 52px;
  justify-content: space-around;
}

.savingsGif {
  width: 38px;
  height: 38px;
}

.savingsResult {
  display: flex;
  height: fit-content;
}

.savingsAmount {
  color: #0671e0;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.savingsText {
  color: #33373b;
  font-family: Lexend;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}

.comparisonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 1rem 1rem;
}

.evalloCharge {
  width: 283px;
  height: 210px;
  flex-shrink: 0;
  border-radius: 26px;
  background: #2482e5;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.evalloText {
  color: #fcfcfc;
  text-align: center;
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  width: 145px;
}

.evalloSubText {
  color: #fcfcfc;
  text-align: center;
  font-family: Lexend;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 145px;
}

.curveContainer {
  width: 16px;
  height: 185px;
  border-radius: 50px;
  background: #FCFCFC;
  z-index: 100;
}

.competitors {
  height: 183px;
  align-self: center;
  display: flex;
  gap: 46px;
  /* justify-content: center; */
  align-items: center;
  padding: 0 46px;
  width: 100%;
  border: 0;
  border-radius: 22px;
  background: #2482e50d;
  z-index: 90;
  justify-content: space-around;
  position: relative;
  left: -8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.competitorInfo {
  display: flex;
  flex-direction: column;
  gap: 14px;
  opacity: 1;
  /* margin-right: 46px; */
}

.competitorName {
  color: #33373b;
  text-align: center;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  opacity: 1;
}

.competitorDetails {
  color: #33373b;
  text-align: center;
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  width: 145px;
  opacity: 0.68;
}

.separator {
  width: 2.5px;
  height: 144.5px;
  color: white;
  background-color: #fcfcfc;
  /* margin-right: 46px; */
}

@media (max-width: 768px) {
  .mainContainer {
    padding: 0;
  }

  .innerContainer {
    width: 100%;
    gap: 38px;
    max-width: calc(100vw - 134px) !important;
  }

  .mainHeading {
    color: #0671e0;
    text-align: center;
    font-family: Lexend;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    flex-direction: row;
  }

  .savingsCalculator {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .savingsInfo {
    color: #33373b;
    text-align: center;
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 142%; /* 17.04px */
    width: 299px;
  }

  .savingsInputArea {
    max-width: calc(100vw - 32px);
  }

  .savingsInput {
    border-radius: 10px;
    border: 0.7px solid #dbdbdb;
    background: #f2f2f2;
  }

  .savingsButton {
    width: 124px;
    flex-shrink: 0;
    color: #f2f2f2;
    text-align: center;
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.12px;
  }

  .savingsDisplayArea {
    min-width: 300px;
    max-width: max-content;
    margin-top: 39px;
  }

  .savingsAmount {
    color: #0671e0;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }

  .savingsText {
    color: #33373b;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
  }

  .comparisonContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 24px;
    background: #2482e50d;
    /* min-width: 100%; */
    padding: 0 0 32px 0;
    margin-bottom: 10%;
  }

  .evalloCharge {
    margin: 16px;
    margin-left: 18px;
    margin-right: 18px;
    width: 322px;
    height: 109px;
    flex-shrink: 0;
    border-radius: 18px;
    background: #2482e5;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0);
  }

  .evalloText {
    color: #fcfcfc;
    text-align: center;
    font-family: Lexend;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
  }

  .evalloSubText {
    color: #fcfcfc;
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    width: 100%;
  }

  .curveContainer {
    display: none;
  }

  .competitors {
    padding: 0;
    max-width: calc(100vw - 92px);
    flex-direction: column;
    background: none;
    margin-top: 26px;
    padding-bottom: 10px;
    height: min-content;
    padding: 0;
    gap: 22px;
  }

  .competitorInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* justify-content: center; */
    align-items: center;
    border: 0;
    border-radius: 0;
    gap: 14px;
  }

  .competitorName {
    color: #33373b;
    text-align: center;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 30px */
  }

  .competitorDetails {
    color: #33373b;
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
  }

  .separator {
    width: 75%;
    height: 1px;
    background-color: #afafaf;
    opacity: 0.28;
  }
}
