.multi_select {
    width: 100%;
    background: #fff;
    border-radius: 4px;
}

.tag_container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 8px 4px;
}

.tag {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 2px 2px 2px 4px;
    border-radius: 4px;
    font-size: 14px;
    background: #caf3ffcb;

}

.tag>div:nth-child(2) {
    background: #fa807289;
    padding: 0 4px;
    border-radius: 4px;
    color: aliceblue;
    cursor: pointer;
}

.tag>div:nth-child(2):hover {
    background: #ff6e6ee6;
}

.clear_all:hover {
    cursor: pointer;
    color: #ff6e6ee6;
}

.input_container {
    position: relative;
    display: flex;
    align-items: center;
}

.input_container button {
    position: absolute;
    right: 8px;
    background: none;
    border: none;
}


.list_container {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 99;
    scrollbar-width: thin;
    box-shadow: #0000000d 0px 1px 2px 0px;
}

.list_container ul {
    padding: 0 8px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
}

.list_container li {
    list-style: none;
    cursor: pointer;
    text-align: left;
    width: 100%;
    padding: 4px 12px;
    border-radius: 4px;
}

.list_container li:hover {
    background: #caf3ff9b;

}