.info-wrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    border-radius: 28px;
    background: linear-gradient(145.74deg, #FFFFFF 8.77%, rgba(255, 255, 255, 0) 93.65%);
    box-shadow: 0px 4px 20.600000381469727px 0px #CCE4F6, 1.5434709787368774px 3.086941957473755px 12.34776782989502px 0px #5D57A614;;

}

/* background:,
 */
.info-content {
    margin: 32px;
    width: 222px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.info-inner {
    margin: 4px;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    gap : 8px;
    background:  linear-gradient(145.7deg, rgba(240, 249, 254, 1), rgba(240, 249, 254, 0.1));
    backdrop-filter: blur(3px);
}

.info-card-heading {
    color : #216A99;
    font-family: Lexend;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.05em;
    text-align: center;
    
}

.info-card-description {
    color : #164766;
    font-family: Lexend;
    font-size: 20px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: -0.03em;
    text-align: center;
    
}