.tableHeader {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 2;
  min-height: 96px;
  min-width: 100%;
}
.headerText {
  padding-left: 45px;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29.11px;
  color: #25335a;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: 400;
  font-size: 23px;
  line-height: 27.6px;
  color: #25335a; /* Replace with your secondary color value */
  height: 100%;
  grid-row-start: 1;
  grid-row-end: 2;
}

.explorer {
  grid-column-start: 3;
  grid-column-end: 4;
  width: 100%;
  color: var(--Secondary-Solid, #25335a);
  font-family: "Gilroy-Regular";
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.46px;
  text-transform: capitalize;
}

.crew {
  grid-column-start: 5;
  grid-column-end: 6;
  color: var(--Secondary-Solid, #25335a);
  font-family: "Gilroy-Regular";
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.46px;
  text-transform: capitalize;
}

.armada {
  grid-column-start: 7;
  grid-column-end: 8;
  color: var(--Secondary-Solid, #25335a);
  font-family: "Gilroy-Regular";
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.46px;
  text-transform: capitalize;
}
.headerTextNew {
  padding-left: 45px;
  padding-top: 18px;
  padding-bottom: 18px;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29.11px;
  color: #25335a; /* Replace with your secondary color value */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column-start: 1;
  grid-column-end: 2;
  gap: 0px;
}

.titleWrapperNew {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.featureDescriptionNew {
  font-family: "Gilroy-Regular", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-secondary-80); /* Replace with your secondary color value */
  text-align: left;
}

.featureContentNew {
  padding-top: 18px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.41px;
  color: var(--color-secondary-80); /* Replace with your secondary color value */
  width: 100%;
  text-align: center;
}

.iconWrapperNew {
  cursor: pointer;
}

.integrationsAdWrapperNew {
  display: flex;
  justify-content: center;
  align-items: center;
}

.separatorLineNew {
  min-width: 1px;
  background-color: #e3e3e3;
  max-width: 1px;
  min-height: 100%;
  height: 100%;
}

.featureTitleText {
  color: #25335a;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  font-family: "Gilroy-SemiBold";
  text-align: left;
}

.featureItemNew {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 4px;
  font-family: "Gilroy-Medium", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.56px;
  color: var(--text-secondary);
  height: 50px;
}

.separatorLine {
  min-width: 0.5px;
  max-width: 0.5px;
  min-height: 40px;
  max-height: 40px;
  background-color: #e3e3e3;
}

.mobileGridContainer {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  padding: 10px 10px;
  border-radius: 14px;
  border: 1px solid #ebebeb;
  background: rgba(252, 252, 252, 0.01);
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
}

.mobileColumnHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.mobileColumnHeaderItem {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 8px;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #eee;
  color: var(--Secondary-Solid, #25335a);
  font-family: "Gilroy-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
}

.mobileTableContainer {

}

.mobileTableItem{
  display: grid;
  grid-template-columns: 136px 1fr;
  align-items: center;
  gap: 10px;
}

.mobileTableFirstDiv {
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.mobileTableFirstTitle {
  color: #25335a;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 21px */
}
.mobileTableFirstDescription {
  color: #515c7b;
  font-family: "Gilroy-Regular";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}

.mobileTableSeparatorLine {
  fill: #fff;
  stroke-width: 1px;
  stroke: #f0f0f0;
  margin: 6px 0px;
}

.mobileTableContent {
  color: var(--secondary-80, #515c7b);
  text-align: center;
  font-family: "Lexend";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.5px;
}

.desktopGridContainer {
  display: none;
  grid-template-columns: 330px 1px 257px 1px 257px 1px 257px 1fr;
  border: 1px solid #e3e3e3;
  border-radius: 22px;
  margin: 48px 0px;
  padding-bottom: 39px;
}

.desktopVerticalLine {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #e3e3e3;
  z-index: 100;
}

.desktopStickyDivider {
  grid-column: 1 / 9;
  grid-row: 2/3;
  width: 100%;
  min-height: 1px;
  background-color: #e3e3e3;
}
.desktopStickyDividerDiv {
  width: 100%;
  min-height: 1px;
  min-width: 100%;
  background: #e3e3e3;
}

@media (min-width: 768px) {
  .mobileGridContainer {
    display: none;
  }
  .desktopGridContainer {
    display: grid;
  }
}
