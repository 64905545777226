@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

:root {
  --primary-color: #216a99;
  --secondary-color: #1c5880;
  --third-color: #4683aa;
  --background-color: #e7f1f8;
  --primary-900: #0b2333;
  --primary-100: #07151F;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

header {
  /* padding: 10px; */
}

.navSelected {
  color: #0671E0;
  font-weight: 500;
}

.navNotSelected {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #11354d;
}

.navigation {
  position: relative;
  width: 67%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 2.5rem; */
  /* background: linear-gradient(88.57deg, rgba(255, 255, 255, 0.6) 78.73%, rgba(255, 255, 255, 0) 107.04%), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)); */
  margin: 0px auto;
  border-radius: 40px;
  border: 2px solid #fff;
  -webkit-background-clip: padding-box;
  /* border-image-source: linear-gradient(88.57deg, rgba(255, 255, 255, 0) 78.73%, rgba(255, 255, 255, 0.976) 107.04%); */
  box-shadow: 0px 0px 16px 4px #759fba3d;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  z-index: 999;
}

.navigation-logo {
  width: 108px;
  height: 20px;
  margin-left: 5px;
}

.middle-navigation-menu {
  /* display: block; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* gap: 2.5rem; */
}

.middle-navigation-menu> :nth-child(1) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  /* gap: 2rem; */
  /* background: #000; */
  font-size: 18px;
}

.middle-navigation-menu>div {
  cursor: pointer;
}

.dropDown-menu> :first-child div {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #11354d;
}

.nav-btn {
  display: flex;
  width: 100%;
}

.nav-btn button {
  min-width: 100px;
  border: 2px solid #0671E0 !important;
  background: transparent;
  border: 0;
  padding: 12px 16px;
  border-radius: 85px;
  color: #0671E0;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.03em;
  text-align: center;
}

.nav-btn> :nth-child(1) {
  white-space: nowrap;
}

.nav-btn> :nth-child(2) {
  /* display: none; */
  margin-left: 12px;
  text-wrap: nowrap;
  background: #0671E0;
  border: none;
  color: #fff;
}



/* #features {
    border: 1px solid;
} */

/* .navigation-features:hover .features-menu {
    display: block !important;
} */

.dont-show {
  display: none;
}

.features-menu {
  margin-top: 60px;
  position: absolute;
  width: 640px;
  height: 410px;
  top: 30px;
  left: 0;
}

.features-menu-tab {
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 6px -2px #1018280d;
  box-shadow: 0px 12px 16px -4px #1018281a;
  display: flex;
  overflow: hidden;
  z-index: 999;
}

.features-tab-right {
  width: 304px;
  background: linear-gradient(180deg, #ffffff 0%, #cde3f1 67.48%);
  padding: 20px;
  cursor: auto;
}

.features-tab-right>div {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 12px;
}

.features-tab-right>div> :nth-child(1) {
  background: linear-gradient(0deg, #f4ebff, #f4ebff),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 2px solid #fff;
  width: 240px;
  height: 136px;
  border-radius: 6px;
}

.features-tab-right>div> :nth-child(2) h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #0b2333;
}

.features-tab-right>div> :nth-last-child(2) p {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #11354d;
}

.features-tab-right>div> :nth-child(3) {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  color: #0671E0;
}

.features-tab-left {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: auto;
}

a {
  text-decoration: none;
  color: black;
}

.features-tab-items {
  display: flex;
  gap: 1rem;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.03em;
  text-align: left;
  /* color: var(--secondary-color); */
  color: #07151F;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  justify-content: start;
  align-items: center;
}

.features-tab-items:hover {
  background: var(--background-color);
}

.features-tab-items-slogan {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--third-color);
}

.new-features-lable {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: center;
  padding: 2px 10px;
  border-radius: 16px;
  color: #027a48;
  background: #ecfdf3;
}

.menu-btn {
  /* padding: 6px; */
  cursor: pointer;
  display: none;
}

.close-menu-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .navigation {
    width: 90%;
    height: 50px;
    top: 20px;
  }

  .menu-btn {
    display: block;
  }

  .middle-navigation-menu> :nth-child(1) {
    flex-direction: column;
  }

  .navigation-logo {
    width: 88px;
    height: 16px;
  }

  .middle-navigation-menu {
    display: none;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    z-index: 99;
    transition: all 0.6s ease-out;
    background: var(--background-color);
    height: 90vh;
    width: 100vw;
    padding: 0 1.5rem;
  }

  .dropDown-menu {
    width: 100%;
    margin-top: 0.75rem;
  }

  .dropDown-menu>a div {
    width: 100%;
    justify-content: space-between;
    font-size: 26px !important;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    /* padding: 10px 0; */
    color: var(--primary-color) !important;
  }

  .non-dropDown-menu {
    padding: 7px 0;
  }

  /* .dropDown-menu>a>div>div{
        padding: -10px 0;
    }
    .dropDown-menu>a>div{
        padding: 15px 0;
    } */

  .dropDown-menu-parent {
    width: 100%;
    transition: all 1s ease-in-out;
  }

  .arrow-icon {
    height: 16px;
    width: 16px;
  }

  .features-menu {
    position: relative;
    padding: 0;
    top: 0;
    width: 100%;
    height: auto;
    transition: all 1s;
    margin-top: 8px;
    /* background: var(--background-color); */
  }

  .features-menu-tab {
    background: none;
    box-shadow: none;
  }

  .features-tab-right {
    display: none;
  }

  .features-tab-left {
    gap: 0;
    padding: 10px;
  }

  .features-tab-items:hover {
    background: #fff;
  }

  .features-tab-items {
    color: #0671E0;
  }

  .nav-btn> :nth-child(1) {
    background: none;

    color: var(--primary-color);
  }

  .nav-btn> :nth-child(1):hover {
    background: var(--primary-color);
    color: #fff;
  }

  .mbile-nav-open-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: white;
    background: #0671E0;
    border-radius: 40px;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
  }

  .nav-btn> :nth-child(2) {
    display: block;
  }

  .nav-btn {
    margin-top: 1rem;
  }

  .navSelected {
    font-family: Lexend;
    font-size: 26px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0671E0;
    font-weight: 500;
  }

  .navNotSelected {
    font-family: Lexend;
    font-size: 26px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #11354d;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .dropDown-menu> :first-child div {
    font-size: 14px;
  }

  .middle-navigation-menu {
    gap: 1rem;
  }

  .navigation {
    padding: 0.5rem 1rem;
    width: 90%;
    gap: 1rem;
  }

  .navSelected {
    font-family: Lexend;
    font-size: 26px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #0671E0;
    font-weight: 500;
  }

  .navNotSelected {
    font-family: Lexend;
    font-size: 26px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #11354d;
  }
}

.show-features {
  animation: showFeatures 0.25s linear;
}

.show-navmenu {
  animation: showNavMenu 0.25s linear;
}

.hide-navmenu {
  animation: showNavMenu 0.25s linear reverse;
}

.show-navbuttons {
  animation: showNavButtons 2.5s linear;
}

@keyframes showNavButtons {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes showNavMenu {
  from {
    margin-left: -100px;
    opacity: 0;
  }
}

@keyframes showFeatures {
  0% {
    margin-top: -100px;
    opacity: 0;
    z-index: -5;
  }

  90% {
    opacity: 0.1;
    z-index: -2;
  }

  100% {
    opacity: 1;
    z-index: 0;
  }
}