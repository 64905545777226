.account {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    max-width: 70vh;
    gap: 2rem;
    position: relative;
    left: 10%;
    top: 2rem;
}

.account h1 {
    font-size: 64px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.05em;
}

.account label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding-left: 4px;
}

.account input {
    padding: 8px;
    width: 100%;
    gap: 4px;
    border-radius: 4px;
    border: 1px solid #0000000F;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    background-color: white;
}

.Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: #216a99;
}

/* plus sign */
.sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign svg {
    width: 17px;
}

.sign svg path {
    fill: white;
}

/* text */
.text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    transition-duration: .3s;
}

/* hover effect on button width */
.Btn:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
    background: #ff4141;
}

.Btn:hover .sign {
    width: 30%;
    transition-duration: .3s;
    padding-left: 20px;
}

/* hover effect button's text */
.Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
}

/* button click effect*/
.Btn:active {
    transform: translate(2px, 2px);
}