@keyframes pop-in {
    from {
        margin-top: 175px;
        opacity: 0;
    }

    to {
        margin-top: 0px;
        opacity: 1;
    }
}

.container {
    background: #ffffff !important;

}

.heroSection {
    /* height: 100vh; */
    background-image: url("./Vector\ background.svg");
    background-repeat: no-repeat;
    background-size: contain;
}

.heroSection>div {
    padding: 75px 66px 96px 66px;
    display: flex;
    gap: 72px;
}

.heroBtn {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 36px;
}

.bookDemoBtn {
    height: 48px;
    /* width: 153px; */
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -3%;
    text-align: center;
    padding: 0 27px;
    background: #0671E0;
    border-radius: 85px;
    outline: none;
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

}



.bookDemoBtn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.bookDemoBtn span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.bookDemoBtn:hover span {
    padding-right: 15px;
}

.bookDemoBtn:hover span:after {
    opacity: 1;
    right: 0;
}

.trialBtn {
    height: 48px;
    /* width: 153px; */
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -3%;
    text-align: center;
    padding: 0 32px;
    color: #0671E0;
    border-radius: 85px;
    outline: none;
    border: 1.5px solid #0671E0;
    background: #ffffff;
    display: grid;
    place-content: center;

}

.trialBtn span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.trialBtn span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.trialBtn:hover span {
    padding-right: 15px;
}

.trialBtn:hover span:after {
    opacity: 1;
    right: 0;
}

.typeWriter {
    font-weight: 500;
    font-size: 56px;
    line-height: 70px;
    letter-spacing: -5%;
    color: #0D121A;
}

.typeWriter span {
    color: #0671E0;
}

.typeWriter span+span {
    font-size: 80px;
    font-weight: 300;
}

.heroDesc {
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    color: #0D121A;
    margin-top: 24px;
}

.typeWriterResponsive {
    display: none;
}

.videoSection {
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    gap: 36px;
    align-items: center;
    justify-content: center;
}

.middleSection {
    padding: 36px 66px 96px 66px;
}

.videoContainer {
    width: 1120px;
    height: 595px;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0px -5px 14px 0px #D9EDFA;
    /* margin-top: 36px; */
}

.discoverDiv {
    padding: 96px 66px;
}

.discoverDiv>div {
    font-weight: 600px;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #333333;
}

.discoverDiv>div span {
    font-weight: 700;
    color: #0671E0;
}

.bookDemoTwo {

    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    width: 221px;
    height: 58px;
    border-radius: 28px;
    background: #0671E0;
    display: grid;
    place-content: center;
    outline: none;
    border: 2px solid #0671E0;
    margin: 24px auto 0 auto;
    /* border-image-source: linear-gradient(92.16deg, #D8EBFF 0%, #0671E0 35.18%, #0671E0 62.08%, #D8EBFF 92.37%); */
}



.bookDemoTwo span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.bookDemoTwo span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -15px;
    transition: 0.5s;
}

.bookDemoTwo:hover span {
    padding-right: 15px;
}

.bookDemoTwo:hover span:after {
    opacity: 1;
    right: 0;
}

.calendarCardContainer {
    padding: 96px 66px;

}

.calendarCardContainer>div {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    gap: 46px;
}


/* ------ TESTOMINIALS CSS -----------------------*/
.testomonialsContainer {
    width: 99vw;
    /* padding: 66px; */

}

.testomonialsContainer>h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #25335A;
    margin-bottom: 26px;
}

.testomonialCardParent {
    overflow: hidden;
}

.testomonialsChild {
    width: 100%;
    display: flex;
    position: relative;
    /* gap: 46px; */
}

.testomonialsChild>div {
    display: flex;
    align-items: stretch;
    animation: 30s slide infinite linear;
    gap: 46px;
    padding: 0 23px;
    /* margin: 0 23px; */
}


@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.freeTrialContainer {
    margin: 96px 0;
    padding: 36px;
    background: #F3F9FF;
}

.freeTrial>h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    color: #333333;
    margin: 0;
}

.freeTrial>h2>span {
    font-weight: 700;
    color: #0671E0;
}

.freeTrial>div {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    padding-top: 4px;
}

.customSolutionMobileContainer {
    display: none;
}


.lockedPricingSectionContainer {
    padding: 96px 66px;


}

.featureSlidesSection {
    padding: 0 66px;
}

.featureSlidesRessponsiveSection {
    display: none;
}


@media screen and (max-width: 768px) {

    .heroSection {
        background-size: cover;
    }

    .heroSection>div {
        flex-direction: column;
        padding: 36px 16px;
        gap: 31px;
        align-items: center;
    }

    .typeWriter {
        display: none;
    }

    .typeWriterResponsive {
        font-weight: 600px;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
    }

    .typeWriterResponsive span {
        color: #0671E0;
    }

    .typeWriterResponsive span+span {
        font-size: 30px;
        font-weight: 300;
    }

    .heroDesc {
        font-weight: normal;
        line-height: 18px;
        text-align: center;
        font-size: 16px;
        margin-top: 8px;
    }

    .heroBtn {
        justify-content: center;
        margin-top: 24px;
    }

    .bookDemoBtn {
        font-size: 16px;
        line-height: 24px;
        /* width: 120px; */
        padding: 0 16px;
        height: 36px;
    }

    .trialBtn {
        font-size: 16px;
        line-height: 24px;
        /* width: 107px; */
        padding: 0 16px;
        height: 36px;
    }

    .typeWriterResponsive {
        display: block;
    }

    .middleSection {
        padding: 16px 16px 36px 16px;
    }

    .videoSection {
        height: auto;
        padding: 16px 0;
        gap: 16px;
    }

    .videoContainer {
        width: 100%;
        height: 193px;
        padding: 6px;

    }

    .givePadding {
        padding: 0 16px;
    }

    .calendarCardContainer {
        width: 100vw;
        padding: 36px 26px;
    }

    .calendarCardContainer>div {
        /* width: 90?%; */
        overflow: scroll;
        display: flex;
        gap: 16px;
    }

    .discoverDiv {
        padding: 36px 16px;
    }

    .discoverDiv>div {
        font-size: 24px;
        line-height: 33px;
    }

    /* .discoverDiv>div>span {
        font-size: 36px;
        line-height: 54px;
    } */

    .bookDemoTwo {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        width: 127px;
        height: 34px;
        margin-top: 16px;
    }


    .testomonialsContainer>h2 {
        font-size: 18px;
        line-height: 27px;
    }

    .testomonialsChild>div {
        gap: 16px;
    }

    .freeTrialContainer {
        padding: 16px;
        margin: 36px 0;
    }

    .freeTrial>h2 {
        font-size: 20px;
        line-height: 30px;
        margin: 0;
    }

    .freeTrial>h2>span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
    }

    .freeTrial>div {
        font-size: 12px;
        line-height: 18px;
        padding-top: 0;
    }

    .customSolutionContainer {
        display: none;
    }

    .customSolutionMobileContainer {
        display: block;
    }

    .lockedPricingSectionContainer {
        padding: 46px 16px;
    }

    .featureSlidesSection {
        display: none;
    }

    .featureSlidesRessponsiveSection {
        display: block;
    }

}