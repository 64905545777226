.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 70px;
  padding: 6px;
  margin-bottom: 39px;
}

.card {
  background: white;
  padding: 6px 6px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 372px;
  gap: 12px;
  border-radius: 20px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.05);
}

.card:hover {
  border: 1px solid #0671e0;
}

.cardHeadDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mostPopular {
  box-shadow: 0px 4px 18px rgba(17, 22, 125, 0.1);
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 24px;
  padding: 22px 0px 0px 29px;
  border-radius: 17px;
  background: #f4f8fc;
}

.headerDesktop {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.price {
  color: #26292b;
  font-family: "Lexend";
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.discountedPrice {
  font-size: 16px;
  line-height: 24px;
  color: #f55a5c;
  text-decoration: line-through;
}

.featureItem {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 9px;
  margin-left: 36px;
}

.packageHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.packageHeadingText {
  color: #333;
  font-family: "Lexend";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.packageDescription {
  color: rgba(81, 92, 123, 0.5);
  font-family: "Lexend";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 162%; /* 25.92px */
  max-width: 100%;
}
.packageCreditCardText {
  color: var(--secondary-80, var(--Blue-700, #0671e0));
  font-family: "Lexend";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 162%;
}

.packageDetails {
  display: flex;
  align-items: center;
  padding: 0px 29px;
  gap: 18px;
}

.discountContainer {
  display: flex;
  flex-direction: column;
}
.discountSubContainer {
  display: flex;
  align-items: center;
}

.mostPopularIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 16px;
  background-color: rgba(249, 249, 249, 0.15);
  color: #fff; /* Equivalent to `text-blue-70` */
  font-family: "Gilroy-SemiBold", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 15.93px;
}

.featureDiv {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.featureText {
  color: #333;
  font-family: "Lexend";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.button {
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  line-height: 19.6px;
  padding: 15px;
  text-align: center;
  margin-top: 31px;
}

.buttonDiv {
  width: 100%;
  padding: 0px 17px;
  padding-bottom: 8px;
}

.buttonFree {
  border: 1px solid #2782e1;
  background: transparent;
  color: #0671e0;
}

.buttonPaid {
  background: #0671e0;
  color: white;
  border: none;
}

.dollar {
  color: #26292b;
  font-family: "Lexend";
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.82px;
}
.inclusionText {
  color: #87898d;
  font-family: "Lexend";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 36px;
  padding-bottom: 8px;
}
.monthContainer {
  color: var(--secondary-60, #7c859c);
  font-family: "Lexend";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}
.planPrice {
  color: #26292b;
  font-family: "Lexend";
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.92px;
}
.cardMobileDescription {
  display: none;
}
.cardHeadDiv1{
  width: 100%;
}


@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-top: 24px;
  }
  .card {
    width: 100%;
    max-width: calc(100vw - 56px);
    padding: 12px;
  }
  .cardHeadDiv {
    flex-direction: row;
    justify-content: space-between;
    gap: 0px;
    margin-top: 7px;
  }
  .header {
    background: transparent !important;
    padding: 0px;
  }
  .packageHeadingText {
    color: #1f354a !important;
    font-family: "Gilroy-SemiBold";
    font-size: 18px;
    line-height: normal;
    letter-spacing: -0.36px;
  }
  .packageDescription {
    display: none;
    color: rgba(81, 92, 123, 0.5) !important;
    font-family: "Gilroy-SemiBold";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 162%; /* 19.44px */
  }
  .packageCreditCardText {
    color: var(--secondary-80, var(--Blue-700, #0671e0)) !important;
    font-family: "Gilroy-SemiBold";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 162%;
  }
  .price {
    color: #1f354a;
    font-family: "Gilroy-SemiBold" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 28px */
  }
  .dollar {
    color: #1f354a;
    font-family: "Gilroy-SemiBold";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 28px */
  }
  .discountContainer {
    color: var(--secondary-70, #636d89);
    font-family: "Gilroy-Regular" !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }
  .discountSubContainer {
    color: var(--secondary-70, #636d89);
    font-family: "Gilroy-Regular" !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .discountedPrice {
    font-family: "Gilroy-Regular" !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mostPopularIndicator {
    display: none;
  }
  .inclusionText {
    display: none;
  }
  .featureItem {
    margin-left: 21px;
  }
  .packageDetails {
    padding: 0px;
    padding-top: 7px;
    align-items: start;
    gap: 4px;
  }
  .button {
    margin-top: 19px;
  }
  .buttonDiv {
    padding: 0px;
  }
  .featureDiv {
    gap: 11px;
  }
  .monthContainer {
    color: var(--secondary-70, #636d89);
    font-family: "Gilroy-Regular" !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .planPrice {
    color: #1f354a;
    font-family: "Gilroy-SemiBold" !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 28px */
  }
  .cardMobileDescription {
    display: block;
    padding-top: 12px;
    color: rgba(81, 92, 123, 0.5) !important;
    font-family: "Gilroy-SemiBold";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 162%; /* 19.44px */
  }
  .cardMobileDescriptionText{
    margin: 0px;
  }
}
