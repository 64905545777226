.customSolutionContainer {
    padding: 0 66px;
    display: flex;
    gap: 48px;
    margin-top: 36px;
    flex-direction: column;
}

.customSolutionCard>div+div {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #515C7B;
}

.customSolutionCard>div:first-child {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    opacity: 90;
}

.customSolutionCard {
    width: 353px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.customSolutionCardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 64px;
    justify-content: center;
    justify-items: center;
    padding: 0 20px 16px 20px;
}

.middleDevider {
    border-bottom: 1px solid #E9EBEF;
}