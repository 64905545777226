#inner_container_ct {
    margin-left: 15%;
    margin-right: 15%;

    display: flex;
    flex-direction: column;
    gap : 3px;
    color : #164766 !important;
}

#inner_container_ct p.p1 {margin: 0.0px 0.0px 0.0px 42.9px; text-align: center; text-indent: -42.9px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p2 {margin: 0.0px 0.0px 0.0px 42.9px; text-align: justify; text-indent: -42.9px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p3 {margin: 0.0px 0.0px 6.7px 18.9px; text-align: justify; text-indent: -18.9px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766;}
#inner_container_ct p.p5 {margin: 0.0px 0.0px 0.0px 18.9px; text-align: justify; text-indent: -18.9px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p6 {margin: 0.0px 0.0px 0.0px 52.8px; text-align: justify; text-indent: -52.8px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p7 {margin: 0.0px 0.0px 0.0px 48.0px; text-indent: -48.0px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p8 {margin: 0.0px 0.0px 10.7px 0.0px; font: 14.7px Helvetica; -webkit-text-stroke: #164766}
#inner_container_ct p.p9 {margin: 0.0px 0.0px 0.0px 81.6px; text-align: justify; text-indent: -81.6px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p10 {margin: 0.0px 0.0px 0.0px 0.0px; text-align: justify; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct p.p11 {margin: 0.0px 0.0px 0.0px 37.8px; text-align: justify; text-indent: -37.8px; font: 14.7px 'Times New Roman'; -webkit-text-stroke: #164766}
#inner_container_ct span.s1 {text-decoration: underline ; font-kerning: none; color: #164766;}
#inner_container_ct span.s2 {font-kerning: none;color: #164766;}
#inner_container_ct span.s3 {font-kerning: none;color: #164766;}
#inner_container_ct span.s4 {font: 9.3px 'Times New Roman'; font-kerning: none}
#inner_container_ct span.s5 {font-kerning: none; color: #244084; -webkit-text-stroke: 0px #244084}
#inner_container_ct span.s6 {text-decoration: underline ; font-kerning: none; color: #164766; -webkit-text-stroke: 0px #164766;}