.map-container {
    max-width: 900px;
    max-height: 500px;
    margin: auto;
    background: rgb(231, 241, 248);
    background: radial-gradient(circle, rgba(231, 241, 248, 0.975249474789916) 11%, rgba(231, 241, 248, 0.18253238795518212) 71%);
}

.stats-info {
    margin: auto;
}

.stats-info>div {
    padding: 16px 8px;
}

.stats-info>div>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px;
    border-radius: 24px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    /* background: #ffffff36; */
    background-image: radial-gradient(circle at 100% 100%, transparent 20px, #ffffff 20px, #ffffff6f 24px, transparent 24px), linear-gradient(to right, #ffffffb0, #ffffff75), radial-gradient(circle at 0% 100%, transparent 20px, #ffffff65 20px, #ffffff3c 24px, transparent 24px), linear-gradient(to bottom, #ffffff4a, #ffffff2d), radial-gradient(circle at 0% 0%, transparent 20px, #ffffff27 20px, #ffffff13 24px, transparent 24px), linear-gradient(to left, #ffffff1c, #ffffff62), radial-gradient(circle at 100% 0%, transparent 20px, #ffffff5e 20px, #ffffff3f 24px, transparent 24px), linear-gradient(to top, #ffffff40, #ffffff);
    background-size: 24px 24px, calc(100% - 48px) 4px, 24px 24px, 4px calc(100% - 48px);
    background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
    background-repeat: no-repeat;

    /* box-shadow: 0px 4px 20.600000381469727px 0px #CCE4F6; */

    /* box-shadow: 1.5434709787368774px 3.086941957473755px 12.34776782989502px 0px #5D57A614; */
    /* background: linear-gradient(0deg, rgba(240, 249, 254, 0.1), rgba(240, 249, 254, 0.1)),
    linear-gradient(145.74deg, #FFFFFF 8.77%, rgba(255, 255, 255, 0) 93.65%); */
    /* background: linear-gradient(0deg, rgba(240, 249, 254, 0.1), rgba(240, 249, 254, 0.1)),
        linear-gradient(145.74deg, #FFFFFF 8.77%, rgba(255, 255, 255, 0) 73.65%); */


}

.stats-info h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;

    color: #216A99;
}

.stats-info p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;

    color: #164766;
    /* text-align: left; */

}

@media (min-width: 768px) {
    .stats-info {
        padding: 0 92px;
        gap: 30px;
    }

    .stats-info>div {
        padding: 0px;
    }

    .stats-info>div>div {
        padding: 30px;
        border-radius: 16px;
        /* gap: 8px; */

    }

    .stats-info h1 {
        font-size: 46px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: left;


    }

    .stats-info p {
        font-size: 20px;
        font-weight: 350;
        line-height: 25px;
        letter-spacing: -0.03em;
        text-align: left;


    }
}