.package-features-dd {
    list-style-type: none;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #164766;
}

.package-dropdown-btn {
    background-color: white !important;
    border-radius: 16px;
    border: 2px solid #4683AA !important;
    margin: 0 auto;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #164766 !important;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: center;
}

.pfl-dropdown {
    width: 100% !important;
    border: 0.5px solid #FFFFFF !important;
    background-color: #FFFFFF;
    border: 2px solid #D3E1EB !important;
    box-shadow: 0px 4px 12px 0px #002B461A;
    border-radius: 16px !important;
    top : 18px !important;
}

li>img{
    left : -25px
}

.pfl-dd ul li {
    margin: 0 !important;
    padding: 19px 16px;
}


@media (min-width : 992px){
    li>img{
        left : 0px
    }
}