.links {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 0.5rem;
    width: 100%;
    padding: 0rem 1rem;
}

.nav, .selectedNav {
    color: #164766;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navForward {
    display: flex;
    align-items: center;
    color: #0B2333;
}


@media (min-width : 992px) {
    .links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap : 2.5rem;
    }

    .nav {
        padding: 0px;
        padding-bottom: 1rem;
        cursor: pointer;
        font-family: Lexend;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.03em;
        margin-bottom: 0rem;
        display: inline;
        width: auto;
    }

    .selectedNav {
        padding: 0px;
        padding-bottom: 1rem;
        cursor: pointer;
        font-family: Lexend;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.03em;
        margin-bottom: 0rem;
        display: inline;
        width: auto;
        border-bottom: 2px solid #216A99;
        color: #216A99;
        font-family: Lexend;
        font-weight: 500;
    }

    .navForward {
        display: none;
    }
}