.bg-vector {
    position: absolute;
    top: 48%;
    left: -25%;
    transform: translate(25%, -25%);
    z-index: -10;
}

.bg-vector-2 {
    position: absolute;
    top: 25%;
    right: -25%;
    transform: translate(-35%, -35%);
    z-index: -10;
}


@media (max-width: 768px) {

    .bg-vector {
        left: -25%;
        bottom: 25%;
        transform: translate(25%, 25%);
    }

    .bg-vector-1 {
        right: -27%;
        top: 18%;
        transform: translate(-35%, -18%);
    }
}