:root {
    --primary800 : #11354D; 
    --primaryBase : #216A99;
}

.navbar{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap : 1.5rem;
}

.navbarLogo {
    max-width: 100%;
    object-fit: contain;
    padding: 0.75rem 1rem;
}

.navbarLinkWrapper {
    width: 100%;
    color: var(--primary800);
}

.navbarActiveLinkWrapper{
    width: 100%;
    background-color: var(--primaryBase);
    color: #FFFFFF;
}

.navbarLinkWrapper:hover {
    background-color: var(--primaryBase);
    color: #FFFFFF;
}

.navbarLink {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap : 0.75rem;
    padding : 0.75rem 1rem;
    color: inherit;
}

