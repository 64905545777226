.pinsection {
  width: 25%;
  /* border: solid 2px black; */
}

.pinsection h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  color: #11354d;
  height: 40px;
  /* border: solid 2px black; */
}

.pinlist {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

@media (max-width: 768px) {
  .pinsection {
    width: 100%;
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .pinsection h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    height: fit-content;
    text-align: center;
  }

  .pinlist {
    margin-top: 0;
    gap: 16px;
  }
}

@media (max-width: 1250px) {
  .pinsection {
    width: 100%;
  }
}
