.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  width: 90%;
  min-width: 90%;
  border-top: 2px dashed rgba(0, 0, 0, 0.36);
  margin-top: 102px;
  margin-bottom: 63px;
}
.featureContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.teamSelectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin-top: 166px;
  margin-bottom: 340px;
  width: 467px;
  max-width: 467px;
}
.teamSelectHeading {
  color: #33373b;
  text-align: center;
  font-family: "Lexend";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 48px */
  letter-spacing: -0.64px;
}
.teamSelectorContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
}
.teamSelector {
  width: 100%;
  height: 50px;
  padding: 7.5px 12px;
  border-radius: 6px;
  background: var(--Input-Fill, #f6f9fa);
  color: var(--secondary-60, #255355);
  font-family: "Gilroy-Medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  border: none;
}
.teamSelector:focus {
  outline: none;
}
.teamSelectContinueButton {
  width: 100%;
  border-radius: 6px;
  background: var(--Blue-700-Disabled, rgba(6, 113, 224, 0.2));
  display: flex;
  height: 50px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  border: none;
  color: white;
}
.demoDiv {
  grid-row: 3/4;
  justify-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 70%, #0b2333 70%);
}
.dottedDividerDesktop {
  display: flex;
  align-self: center;
  width: 80%;
  border: none;
  border-top: 2px dashed #000;
}
.dottedDividerMobile {
  display: none;
  flex-direction: column;
  gap: 12px;
  align-self: center;
  width: 100%;
  padding: 12px 0px;
  border-top: 1px dashed #bfbfbf;
  border-bottom: 1px dashed #bfbfbf;
  color: var(--Secondary-Solid, #25335a);
  font-family: "Lexend";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .divider{
    display: none;
  }
  .teamSelectHeading {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
  }
  .teamSelectContainer {
    width: 100%;
    max-width: 100%;
    padding: 0px 24px;
  }
  .featureContainer {
    gap: 18px;
    padding: 0px 24px;
  }
  .dottedDividerDesktop {
    display: none;
  }
  .dottedDividerMobile {
    display: flex;
  }
  .demoDiv{
    margin-top: 30px;
  }
}
@media (max-width: 1024px) {
  .demoDiv {
    padding: 0px 24px;
  }
  /* .container {
    padding-left: 24px;
    padding-right: 24px;
  } */
}
