.invoicing-features {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 106px;
    width: 1285px;
    height: 865px;
    margin: 0 auto;
    position: relative;
  }
  
  .feature-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 27px;
    gap: 11px;
    width: 587px;
  }
  
  .feature-header h2 {
    width: 747px;
    height: 63px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #0671E0;
  }
  
  .feature-header p {
    width: 570px;
    height: 52px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 142%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #696D71;
  }
  
  .header-line {
    width: 51px;
    height: 0;
    border: 1px solid #0671E0;
  }
  
  .feature-content {
    display: flex;
    width: 1285px;
    height: 606px;
    background: #FFFFFF;
  }
  
  .feature-left {
    width: 498px;
    padding: 41px 0 0 79px;
  }
  
  .feature-item {
    margin-bottom: 40px;
  }
  
  .feature-icon {
    width: 40px;
    height: 40px;
    background: #0671E0;
    opacity: 0.11;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .feature-icon svg {
    width: 24px;
    height: 24px;
  }
  
  .feature-item h3 {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 52px;
    color: #3B4045;
    margin-bottom: 6px;
  }
  
  .feature-item p {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #696D71;
    margin-bottom: 24px;
  }
  
  .feature-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 153px;
    height: 48px;
    border: 1.5px solid #0671E0;
    border-radius: 85px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #0671E0;
    background: transparent;
    cursor: pointer;
  }
  
  .feature-right {
    width: 787px;
    height: 606px;
    position: relative;
  }
  
  .feature-image {
    position: absolute;
    width: 625px;
    height: 520px;
    right: 0;
    top: 35px;
    background: #ECF5FF;
    border-radius: 30px;
  }
  
  .meeting-link {
    position: absolute;
    width: 323px;
    height: 75px;
    left: 131px;
    top: 34px;
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.09);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 27px;
  }
  
  .meeting-link svg {
    width: 24px;
    height: 24px;
  }
  
  .meeting-link span {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #7C859C;
  }
  
  @media (max-width: 1300px) {
    .invoicing-features {
      width: 100%;
      height: auto;
    }
  
    .feature-content {
      flex-direction: column;
      width: 100%;
      height: auto;
    }
  
    .feature-left, .feature-right {
      width: 100%;
      padding: 20px;
    }
  
    .feature-image {
      position: relative;
      width: 100%;
      height: 300px;
      top: 0;
    }
  
    .meeting-link {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  @media (max-width: 768px) {
    .invoicing-features{
      display: none;
    }

    .feature-header h2 {
      width: 100%;
      font-size: 32px;
    }
  
    .feature-header p {
      width: 100%;
      font-size: 16px;
    }
  }
  
  