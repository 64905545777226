.price-type-v2 {
    color:  #216A99;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;    
}

.month-v2{
    color: #216A99;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    
}

.price-cost-v2 {
    color: #216A99;
    font-family: Lexend;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: left;     
}

.price-config-v2 {
    color: #216A99;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;    
    display: flex;
    justify-content: start;
    align-items: center;
    gap : 20px;    
}


#price-card-container {
    background-image: url(/src/components/PriceCardV2/oct_n_mobile.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 280px;
    width: 355px;
    padding : 40px 40px;
    scale: 1 1;
}

#price-card-container-selected {
    background-image: url(/src/components/PriceCardV2/oct.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    height: 280px;
    width: 355px;
    padding : 40px 40px;
    scale: 1.05 1.05;
}

#price-btn-container {
    padding : 16px calc((100% - 355px)/2 + 30px);
    width: 100%;
}


@media (min-width  : 992px) {
    #price-btn-container {
        padding-left: 120px;
        padding-right: 120px;
        width: 100%;
    }
}

@media (min-width : 1400px) {
    .price-type-v2 {
        color: #FFFFFF;
        font-family: Lexend;
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.03em;
        text-align: left;        
    }
    
    .month-v2{
        color: #FFFFFF;
        font-family: Lexend;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: left;
    
    }
    
    .price-cost-v2 {
        color: white;
        font-family: Lexend;
        font-size: 44px;
        font-weight: 600;
        line-height: 55px;
        letter-spacing: -0.05em;
        text-align: left;
        
    
    }
    
    .price-config-v2 {
        color: white;
        font-family: Lexend;
        font-size: 18px;
        font-weight: 350;
        line-height: 23px;
        letter-spacing: -0.03em;
        text-align: left;
    }


    

    #price-card-container {
        background-image: url(/src/components/PriceCardV2/oct_n.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 298px;
        width: 298px;
        padding-top: calc((298px - 273px)/2 + 28px);
        padding-left: calc((298px - 273px)/2 + 26px);
        animation: deselect 0.5s linear 1;
        scale: 1 1;
    }

    #price-card-container-selected {
        background-image: url(/src/components/PriceCardV2/oct_selected.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 298px;
        width: 298px;
        padding-top: calc((298px - 273px)/2 + 28px);
        padding-left: calc((298px - 273px)/2 + 26px);
        animation: select 0.5s linear 1;
        scale: 1.045;
    }

    #price-btn-container {
        padding : 8px 36px;
        width: 100%;
    }
}


@keyframes select {
    from {
        scale: 1 1;
    }
    to {
        scale: 1.045;
    }
}

@keyframes deselect {
    from {
        scale: 1.045;
    }
    to {
        scale: 1;
    }
}

