.card {
  width: 494px;
  height: 540px;
  background: #FFFFFF;
  border-radius: 13px;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  max-width: 527px;
  max-height: 540px;
}

.cardInner {
  margin: 6px;
  background: linear-gradient(179.72deg, #BDDEFF 0.25%, #FEFEFF 82.18%);
  border-radius: 10px;
  overflow: hidden;
}

.cardImage {
  position: relative;
  height: 283px;
  margin: 37px 13px 0;
  overflow: hidden;
}

.cardImage::before {
  content: '';
  position: absolute;
  left: 2.82%;
  right: -0.82%;
  top: 7.48%;
  bottom: 32.87%;
  background: linear-gradient(141.8deg, #FFFFFF 22.02%, rgba(255, 255, 255, 0) 92.67%);
  opacity: 0.15;
  z-index: 1;
}

.cardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardContent {
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 27px;
  padding-right: 8px;
}

.cardTitle {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.9);
  margin: 0 0 8px;
}

.cardDescription {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.77;
  margin: 0;
  max-width: 405px;
}

@media (max-width: 768px) {
.card {
    width: 384px;
    background: #FFFFFF;
    border-radius: 13px;
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    max-width: 384px;
    max-height: 410px;
   
  }
  
  .cardInner {
    margin: 5px;
    background: linear-gradient(-179.72deg, #FEFEFF 0.25%, #BDDEFF 82.18%);

    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .cardImage {
    position: relative;
    height: 232px;
    margin: 37px 13px 0;
    overflow: hidden;
    order: 2
  }
  
  .cardImage::before {
    content: '';
    position: absolute;
    left: 2.82%;
    right: -0.82%;
    top: 7.48%;
    bottom: 32.87%;
    background: linear-gradient(141.8deg, #FFFFFF 22.02%, rgba(255, 255, 255, 0) 92.67%);
    opacity: 0.15;
    z-index: 1;
  }
  
  .cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .cardContent {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 27px;
    padding-right: 8px;
    order: 1;
  }
  
  .cardTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0 0 8px;
  }
  
  .cardDescription {
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.77;
    margin: 0;
    max-width: 305px;
  }
}
  
  