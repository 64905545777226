.container {
  font-family: "Gilroy-Medium";
  font-weight: 400;
  font-size: 31px;
  line-height: 38.75px;
  color: #25335a;
  width: 100%;
  max-width: 1105px;
}
.mobileContainer {
  display: none;
  color: #192232 !important;
  text-align: center;
  font-family: "Gilroy-medium";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
  text-transform: capitalize;
}
.planText {
  color: #0671e0;
}
@media (max-width: 768px) {
  .container {
    display: none;
  }
  .mobileContainer{
    display: flex;
  }
}
