.form-outer {
  background-image: linear-gradient(to bottom,
      #e7f1f8 0%,
      #e7f1f8 80%,
      #002143 80%,
      #002143 100%);
  padding-top: 60px !important;
}

.form_overwrite {

  backdrop-filter: blur(30px) !important;
  border-right: 4px solid #ffffff70;
  border-top: 4px solid #ffffff70;
}

.contact_submit_btn {

  background-color: #0671E0;
  color: white;
  border-radius: 24px;
  border: none !important;
  outline: none !important;
}

.form-container {
  border-radius: 10px;
  overflow: hidden;
  /* border: solid 2px black; */
  padding: 24px 10px;
  width: 883px;
  height: 592px;
  border-left: 4px solid #ffffff70;
  border-bottom: 4px solid #ffffff70;
  backdrop-filter: blur(2.4px);
  background: linear-gradient(114.89deg,
      rgba(255, 255, 255, 0.3) 1.14%,
      rgba(255, 255, 255, 0) 101.59%);
}

.form-content-1,
.form-content-2,
.form-content-3 {
  gap: 18px;
}



.line-1 {
  color: #25335a !important;
  font-family: Lexend !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 40px !important;
  letter-spacing: -0.05em !important;
}

.line-2 {
  color: #25335a !important;
  font-family: Lexend !important;
  font-size: 14px !important;
  font-weight: 350 !important;
  line-height: 17.5px !important;
  letter-spacing: -0.03em !important;
}

.form-container>.row>div>label {
  color: #11354d !important;
  font-family: Lexend !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.form-container>.row>div>input,
.form-container>.row>div>textarea {
  color: #7C859C !important;
  font-family: Lexend !important;
  font-size: 16px !important;
  font-weight: 350 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  padding: 16px !important;
  outline: none !important;
}

.form-container>.row>div>input::placeholder {
  color: #7C859C !important;
  font-family: Lexend !important;
  font-size: 16px !important;
  font-weight: 350 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  outline: none !important;
  text-align: left !important;
}

.form-container>.row>div>textarea::placeholder {
  color: #7C859C !important;
  font-family: Lexend !important;
  font-size: 16px !important;
  font-weight: 350 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  outline: none !important;
  text-align: left !important;
}

.form-container>.row>div>textarea {
  height: 52px !important;
}

@media (min-width: 992px) {


  .form-content-1,
  .form-content-2,
  .form-content-3 {
    gap: 0;
  }

  .form-outer {
    padding-top: 32px !important;
  }

  .form-outer>form {
    margin: 0 20vw 0 20vw;
  }

  .form_overwrite {

    backdrop-filter: blur(30px) !important;
    border-right: 4px solid #ffffff70;
    border-top: 4px solid #ffffff70;
  }

  .form-container {
    border-radius: 22px !important;
    border-left: 4px solid #ffffff70 !important;
    border-bottom: 4px solid #ffffff70 !important;
    background-image: linear-gradient(287.81deg,
        rgba(255, 255, 255, 0),
        0.45%,
        rgba(255, 255, 255, 0.1) 75.98%,
        #ffffff 99.94%) !important;
    backdrop-filter: blur(2.4px);
    width: 883px;
  height: 592px;
    /* width: 100%; */
    margin: 0px;
    padding: 35px 55px;
  }

  .line-1 {
    color: #25335A !important;
    font-family: Lexend !important;
    font-size: 48px !important;
    font-weight: 600 !important;
    line-height: 60px !important;
    letter-spacing: -0.05em !important;
  }

  .line-2 {
    color: #515C7B !important;
    font-family: Lexend !important;
    font-size: 24px !important;
    font-weight: 350 !important;
    line-height: 26px !important;
    letter-spacing: -0.03em !important;
  }

  .form-container>.row>div>label {
    color: #11354d !important;
    font-family: Lexend !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
  }

  .form-container>.row>div>input,
  .form-container>.row>div>textarea {
    color: #7c859c !important;
    font-family: Lexend !important;
    font-size: 20px !important;
    font-weight: 350 !important;
    line-height: 29px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    padding: 16px !important;
    border: none !important;
    margin: 0 !important;
    outline: none !important;
  }

  .form-container>.row>div>input::placeholder {
    color: #7c859c !important;
    font-family: Lexend !important;
    font-size: 20px !important;
    font-weight: 350 !important;
    line-height: 29px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    outline: none !important;
  }

  .form-container>.row>div>textarea::placeholder {
    color: #7c859c !important;
    font-family: Lexend !important;
    font-size: 20px !important;
    font-weight: 350 !important;
    line-height: 29px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    outline: none !important;
  }

  .form-container>.row>div>textarea {
    height: 114px !important;
  }
}