.feature_main {
  padding: 64px 175px;
  width: 100% !important;
  max-width: 100vw !important;
  margin: auto;
}

.feature_main2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100% !important;

  max-width: 100% !important;
}

.feature_heading {
  display: flex;
  justify-content: space-between;
}

.feature_heading h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #0b2333;
}

.feature_move {
  display: flex;
  gap: 8px;
}

.feature_move2 {
  display: none;
}

.feature_move button,
.feature_move2 button {
  background-color: white;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  outline: none;
  border: none !important;
  cursor: pointer;
}

.feature_blogs2 {
  display: flex;
 
  gap: 32px;
  width: 100% !important;
  overflow-x: scroll;



  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}


.feature_blogs2::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

@media (max-width: 768px) {
  .feature_main {
    padding: 28px;
  }

  .feature_move2 {
    display: flex;
    gap: 8px;
  }

  .feature_move button {
    display: none;
  }

  .feature_heading h2 {
    padding: 0px 64px;
    font-size: 32px;
    text-align: center;
  }
  .feature_main2 {
    gap: 24px;
  }
}
