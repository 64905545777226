.starIcon {
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 768px) {
    .starIcon {
        width: 17px;
        height: 17px;
    }
}