.pin_banner {
  padding: 24px;
  border-radius: 16px;
  border: solid 4px #f1f7fb;
  display: flex;
  gap: 10px;
  align-items: start;
  justify-content: center;
  height: 100%;
}

.pin_container {
  display: flex;
  flex-direction: column;

  /* flex-direction: column; */
  gap: 10px;
}

.pin_button {
  background-color: #0671e00d;
  overflow: hidden;
  border: none;
  padding: 5px 15px !important;
  color: #0671e0;
  border-radius: 24px !important;
  font-weight: 300 !important;
  width: fit-content;
  font-size: 16px !important;
}

.pin_container h3 {
  font-size: 24px;
  font-weight: 500;
  color: #11354d;
  line-height: 31.2px;
}

.pin_time p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 350;
  line-height: 10px;
  color: #515c7b;
}

.summary {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 26px;
  font-weight: 350;
}

.pin_time hr {
  margin: 10px 0px;
  font-weight: 400;
}

.refer {
  height: fit-content;
  border: solid 1px #0671e0;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.refer img {
  width: 30px;
}

@media (max-width: 768px) {
  .pin_banner {
    padding: 32px;
    gap: 10px;
  }

  .pin_container {
    display: flex;
    flex-direction: column;
    /* flex-direction: column; */
    gap: 10px;
  }

  .pin_button {
    font-weight: 350;
    font-size: 14px;
    line-height: 21px;
  }

  .pin_container h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
  }

  .pin_time p {
    font-size: 14px;
    line-height: 21px;
  }

  .summary {
    -webkit-line-clamp: 3;
    font-size: 14px;
    line-height: 18.2px;
  }

  .pin_time hr {
    margin: 10px 0px;
    font-weight: 400;
  }

  .refer {
    height: fit-content;
    border: solid 1px #0671e0;
    border-radius: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
  }

  .refer img {
    width: 20px;
  }
}

