.package-features-list {
    padding-top: 9px;
    list-style-type: none;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #07151F;
}

.package-features-list>li {
    padding-top: 15px;
    margin-bottom: 30px;
    cursor: pointer;
}

.package-features-list-item-selected {
    margin-top: -5px;
    color: white !important;
}

li>img {
    left: -25px
}

@media (min-width : 992px) {
    li>img {
        left: 0px
    }
}