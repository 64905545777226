.question-from {
    margin: 1rem auto;
    position: relative;
    width: 60%;
    padding: 2rem;
    border-radius: 14px;
    background: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(6px);
    border-left: 4px solid #ffffff7b;
    border-top: 4px solid #ffffff7b;
    /* background: linear-gradient(280deg, rgba(255, 255, 255, 0.014) 43%, rgba(255, 255, 255, 0.542) 99%); */
    background: linear-gradient(114.89deg, rgba(255, 255, 255, 0.3) 1.14%, rgba(255, 255, 255, 0) 101.59%);


}

.question-from h2 {
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -0.05em;
    color: var(--primary-700);

}

.question-from p {
    font-size: 22px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    color: var(--primary-500);

}

.question-from label {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.question-from input,
.question-from textarea {
    padding: 16px;
    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

/* ::-webkit-input-placeholder {
    color:white !important;
    opacity: 80%;
} */

textarea:focus{
    background: white !important;
}

.submit-btn {
    margin: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 32px;
    background: var(--primary-base);
    color: #fff;
    border-radius: 24px;
    border: none;

}

.submit-btn:hover {
    background: #1C5880;
}

.form-bottom-strip {
    background: #0B2333;
    height: 250px;
    position: absolute;
    bottom: -0%;
    transform: translateY(25%);

}


@media (max-width: 768px) {
    .question-from {
        width: 100%;

    }

    .question-from h2 {
        font-size: 32px;
    }

    .question-from p {
        font-size: 14px;
    }

  
}


@media (min-width: 992px) {

    .form-outer_e > form {
        margin: 0 20vw 0 20vw;
      }
  
}
