@media (min-width: 992px) {
  .docsContentContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .docsContentBlock {
    width: 100%;
  }
}
