.expandCard {
    box-shadow: none !important;
    margin-top: 24px;
}

.expandCard::before {
    background: none !important;
}

.expandCardContainer {
    padding: 0 16px;
}

.expandCardTab {
    display: flex;
    align-items: center;
    gap: 15px;


}

.expandCardTabContainer {
    border-radius: 12px !important;
    padding: 6px 12px !important;
    border: 1px solid #CACEDA !important;
}

.activeExpandCardTabContainer {
    background: #0671E0 !important;
    border-radius: 12px !important;
    padding: 6px 12px !important;
    color: #fff !important;
}


.activeExpandCardTabContainer div {
    color: white !important;
}

.expandCardTab>div {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 90;
}

.expandCardDesc {
    padding-bottom: 16px;
    padding-left: 21px;
    border-left: 1px solid #0671E0;
    /* border: 1px solid; */
    position: relative;
}

.expandCardDesc>div:first-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #25335A;
}

.expandCardDesc>div+div {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #515C7B;
    margin-top: 4px;
}

.blueDot {
    height: 18px;
    width: 18px;
    border-radius: 100%;
    background: linear-gradient(180deg, #0671E0 0%, #50A6FF 100%);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -20%);
}