.searchBarNormal {
    border: 1px solid #D3E1EB;
    border-radius: 1rem;
    overflow: hidden;
}

.searchBarSelected {
    border: 1px solid #D3E1EB;
    border-radius: 1rem 1rem 0rem 0rem;
    overflow: hidden;
    box-shadow: 0px 7px 30px 0px #8AA8BB78;
    z-index: 3;
}
.searchIcon {
    background-color: white;
    color :  #6B9CBB;
    border: none;
    padding: 0.75rem 0px 0.75rem 1rem ;
    border-radius: 0;
}

.searchField {
    background-color: white;
    border: none;
    padding: 0.75rem 0px 0.75rem 0.5rem;
    border-radius: 0;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #216A99;
}

.searchField:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.searchField::placeholder{
    color: #6B9CBB;
}


.closeButton {
    background-color: white;
    color : #6B9CBB;
    border: none;
    padding: 0rem 0.5rem;
}

@media (max-width : 767.9px) {
    .searchBar {
        border: 1px solid #D3E1EB;
        border-radius: 0.5rem;
        overflow: hidden;
    }
}


/*CSS for DocsSearchRes, later to be moved to its own component*/
.superContainer {
    background-color: white;
    border-radius: 0rem 0rem 1rem 1rem;
    border: 1px solid #D3E1EB;
    position: absolute;
    width: 100%;
    box-shadow: 0px 7px 30px 0px #8AA8BB78;
    z-index: 3;
    max-height: 328px;
    overflow-y: scroll;
}

.container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap : 1rem;
    padding: 1rem 1.5rem;
}

.docsIcon {
    width: fit-content;
}

.resultContainer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    background-color: white;
}

.resultContainer>div{
    font-family: Lexend;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #11354D;
}

.resultContainer a{
    font-family: Lexend;
    font-size: 1rem;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B9CBB;
    text-transform: capitalize;
}

@media (max-width : 991.9px) {
    .superContainer {
        width: calc(100% - 2rem);
    }
}