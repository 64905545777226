.creditsEarnedPopup {
    width: 640px;
    border-radius: 12px;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px #11167D26;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.creditsEarnedPopup>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.popupHeading {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #25335A;
}

.bottomBorder {
    border-bottom: 1px solid #E9EBEF;
    width: 100%;
}

.credits>div {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #515C7B;
}

.credits>div>span {
    font-weight: 500;
    color: #25335A;
}

.credits {
    display: flex;
    align-items: center;
    gap: 6px;
}

.creditsDesc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #515C7B;
}

.formContainer {
    width: 354px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: auto;
}

.formContainer>input {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #25335A;
    padding: 7.5px 12px;
    border-radius: 6px;
}

.formContainer>input::placeholder {
    color: #7C859C;
}

.creditsEarnedPopup>form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.submitBtn {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -3%;
    color: #ffffff;
    width: 172px;
    height: 48px;
    border-radius: 85px;
    border: none;
}

@media screen and (max-width: 768px) {
    .creditsEarnedPopup {
        width: 342px;
        padding: 16px;
    }

    .formContainer {
        width: 100%;
    }

    .popupHeading {
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: #515C7B;
    }

    .creditsDesc {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
    }

    .submitBtn {
        width: 100%;
        height: 42px;
    }
}