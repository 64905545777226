.linksHeader {
    font-family: Lexend;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #FFFFFF;
    background-color: #216A99;
    gap : 1rem;
    text-transform: capitalize;
    padding: 1rem 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}


@media (min-width : 992px) {
    .linksHeader {
        font-family: Lexend;
        font-size: 20px;
        font-weight: 350;
        line-height: 25px;
        letter-spacing: -0.03em;
        text-align: left;
        color: #FFFFFF;
        background-color: #216A99;
        text-transform: capitalize;
        padding: 1rem 1.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap : 1rem;
    } 
}