.container {
    margin-top: 4rem;
    width: 100%;
    overflow: clip;
}

.smallCard {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.popIn {
    animation: pop-in 0.5s ease-out;
    /* animation-timeline: view(700px 0px); */
}

.fCard {
    opacity: 0;
}


@media (min-width : 992px) {
    .smallCard {
        margin-top: 1rem;
        margin-bottom: 2rem;
        padding-left: 5rem;
        padding-right: 5rem;
    }

}

@keyframes pop-in {
    from {
        margin-top: 175px;
        opacity: 0;
    }

    to {
        margin-top: 0px;
        opacity: 1;
    }
}