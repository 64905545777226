.topBarContainer {
    grid-row: 1 / 2;
}


.contentContainer {
    grid-row: 2 / 3;
    height: 100%;
}

.createDocButton {
    padding : 0.5rem 1rem;
    border:none;
    background-color: #1C5880;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
}


.selectedFilterButton {
    color: #164766;
    font-weight: bold;
    padding : 0.5rem;
    padding-bottom: 1rem;
    border: none;
    border-bottom: 2px solid #164766;
    border-radius: 0px;
    padding-left: 0px;
}


