.TabContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 42px;
    padding: 16px 20px 0 20px;
}

.tabItem {
    width: 370px;
    height: 84px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    opacity: 90%;
    border: 1px solid #CACEDA;
    background: #FFFFFF;
}

.tabItem>div {
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 5.05px 12.63px 0px #34344626;
}

.tabItemSelected {
    width: 370px;
    height: 84px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    opacity: 90%;
    border: none;
    background: #0671E0;

}

.tabItemSelected>div {
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 5.05px 12.63px 0px #34344626;
}