.savingsIndicator {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #dbffeebf;
  width: 75px;
}

.savingsText {
  font-family: "Gilroy-Medium";
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  margin: 0px;
  padding: 0px;
  color: #099a66 !important;
}

.durationSelector {
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  width: 186px;
  height: 34px;
  cursor: pointer;
}

.annualGradient {
  background: linear-gradient(90deg, #6eb5ff 0%, #0671e0 100%);
}

.quarterlyGradient {
  background: linear-gradient(90deg, #0671e0 0%, #6eb5ff 100%);
}

.inactiveButton {
  font-family: "Gilroy-Regular";
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  width: 90px;
  border-radius: 100px;
  background: transparent;
  border: none;
}

.activeButton {
  font-family: "Gilroy-Medium";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #0071e0;
  width: 90px;
  background: white;
  border-radius: 100px;
  height: 100%;
  border: none;
}

.mobileSelector {
  width: 98px;
  cursor: pointer;
  border: 1px solid #0000002e;
  background: #f6f9fa;
  border-radius: 8px;
  padding: 8px 12px;
  position: relative;
  display: none;
}

.mobileSelectorHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileSelectorText {
  color: #5a5a5a;
  font-family: "Gilroy-SemiBold";
  font-size: 10px;
  font-weight: 400;
}

.mobileDropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  box-shadow: 0px 4.05px 26.98px rgba(0, 0, 0, 0.17);
  background: white;
  border-radius: 6px;
  padding: 6px;
  width: 100%;
}

.mobileDropdownItem {
  cursor: pointer;
  padding: 6px 16px;
  font-family: "Gilroy-Medium";
  font-size: 10px;
  line-height: 15px;
  color: #5a5a5a;
}

.selectedItem {
  color: #0071e0;
}

.desktopContainer {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 12px;
}

.teamSizeText {
  font-family: "Gilroy-SemiBold";
  font-size: 18px;
  line-height: 22.05px;
  color: #2e2e2e;
}

.activeOpacity {
  opacity: 1;
}

.inactiveOpacity {
  opacity: 0.51;
}

.desktopSelector {
  background: white;
  padding: 6.5px 10px 6.5px 16px;
  border-radius: 8px;
  width: 132px;
  cursor: pointer;
  position: relative;
  border: 1px solid #E4E4E4;
}

.selectorActive {
  border: 1px solid #0071e0;
}

.selectorHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectorText {
  font-family: "Gilroy-SemiBold";
  font-size: 15px;
  color: #303956;
}

.editorPopup {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 11px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 4px 25.2px rgba(0, 0, 0, 0.1);
}

.editorText {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  color: #303956;
}

.dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  padding: 6px;
  box-shadow: 0px 4.05px 26.98px rgba(0, 0, 0, 0.17);
  width: 100%;
}

.dropdownItem {
  cursor: pointer;
  padding: 6px 16px;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  color: #5a5a5a;
}

.saveButton {
  background: #0071e0;
  color: white;
  padding: 5px 16px;
  border-radius: 6px;
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
}

.sizeDurationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.individualMode {
  justify-content: flex-end;
}

.durationSavings {
  display: flex;
  align-items: center;
  gap: 12px;
}

@media (max-width: 768px) {
  .desktopContainer {
    display: none;
  }
  .mobileSelector {
    display: flex;
  }
  .inactiveButton {
    font-size: 12px;
    line-height: 12px;
  }

  .activeButton {
    font-size: 12px;
    line-height: 12px;
  }
  .sizeDurationContainer{
    padding: 0px 0px;
  }
  .savingsText {
    font-size: 12px;
    line-height: 12px;
  }
  .durationSelector{
    width: 100%;
    max-width: 150px;
  }
  .savingsIndicator{
    width: 100%;
    max-width: 59px;
  }
  .durationSavings {
    gap: 4px;
  }
}
