.hero-title-desc {
    margin-top: 100px;
}

.feature-title h1 {
    font-size: 64px;
    font-weight: 600;
    line-height: 80px;
    letter-spacing: -0.05em;
    text-align: center;
    color: var(--primary-700);

}

.feature-title p {
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: center;
    /* padding: 0px 16rem; */
    color: var(--primary-500);
    max-width: 650px;
}


.color-spread-bottom {
    background: linear-gradient(0deg, rgba(229, 240, 247, 1) 20%, rgba(229, 240, 247, 0.5018601190476191) 100%);
}

.title-bg-grid {
    width: 600px;
    height: 150px;
    overflow: hidden;
}

.title-bg-grid img {
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 300%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .hero-title-desc {
        margin-top: 40px;
    }

    .feature-title h1 {
        font-size: 32px;
        margin-bottom: auto;
    }

    .feature-title p {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.03em;
        text-align: center;
        padding: 0px 2rem;
    }


    .title-bg-grid {
        width: 350px;
        height: 100px;
    }



    .title-bg-grid img {
        top: -50%;
        left: 50%;
        /* width: 100%; */
    }


}