.previewCard {
    border-radius: 1.5rem;
    background-color: rgba(255,255,255,0.3) ;
    backdrop-filter: blur(2px) ;
    border: 2px solid white;
}
.previewImage{
    padding: 0.5rem;
    border-radius: 2rem;
    width: 25rem;
    height: 20rem;
    object-fit: cover;
    max-width: 100%;
    margin: 0 auto;
}

.editButtons {
    font-size: 1rem;
    padding : 0.5rem 0.75rem;
}

