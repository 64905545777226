.container {
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
}

.navbarContainer {
    height: 100%;
    grid-column: 1/2;
    border-right: 1px solid #D3E1EB;
}

.contentContainer{
    height: 100%;
    grid-column: 2/3;
    display: grid;
    grid-template-rows: auto 1fr;
}

.creatorContainer {
    height: 100%;
    grid-column: 1/3;
    display: grid;
    grid-template-rows: auto 1fr;
}

.creatorNav{
    display: none;
}