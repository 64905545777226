.expandCardTab>div {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 0.9;
}

.expandCard {
    box-shadow: none !important;
    /* margin-top: 16px; */
}

.expandCard::before {
    background: none !important;
}

.expandCardContainer {
    padding: 0 12px;
}

.expandCardTab {
    display: flex;
    align-items: center;
    gap: 12px;


}

.expandCardTabContainer {
    border-radius: 12px !important;
    /* padding: 6px 12px !important; */
}

.activeExpandCardTabContainer {
    background: #EDF6FF !important;
    border-radius: 12px !important;
    /* padding: 6px 12px !important; */
}