:root {
    --background: #E5F0F7;
    --primary-700: #164766;
    --primary-800: #11354D;
    --primary-500: #25335A;
    --primary-base: #216A99;
    --items-container-bg: #F1F7FB;
}

.content-container {
    /* height: 100vh; */
}

.about-container {
    padding: 2rem 6rem;
}

.evallo-info>div {
    background: radial-gradient(circle, rgba(229, 240, 247, 1) 68%, rgba(229, 240, 247, 0.43463322829131656) 100%);
    /*margin: 40px 0;
    padding: 0 120px; */
    width: 75%;
    margin: auto;
}

.evallo-info h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.05em;
    text-align: center;
    color: var(--primary-700);
}

.evallo-info p {
    font-size: 20px;
    font-weight: 350;
    line-height: 26px;
    letter-spacing: -0.03em;
    text-align: center;

    color: var(--primary-500);
    /* text-align: justify; */

}



.founder-info h1 {
    font-size: 48px;
}

.founder-info h4 {
    font-size: 32px;
}

.founder-info h1,
h4 {
    color: var(--primary-700);
    margin: 0;
}

.founder-info p {
    color: var(--primary-500);
    font-size: 20px;
    font-weight: 350;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
}

.about-founder {
    background: radial-gradient(circle, rgba(229, 240, 247, 1) 68%, rgba(229, 240, 247, 0.43463322829131656) 100%);
    margin-top: 6px;

}

.hobbies-container {
    /* border: 1px solid; */
}



@media (max-width: 768px) {
    .about-container {
        padding: 10px;
    }



    .evallo-info>div {
        padding: 8px;
        margin-top: 0;
        width: 100%;

    }

    .evallo-info p {
        font-size: 16px;
        line-height: normal;
    }

    .founder-info p {
        color: var(--primary-500);
        font-size: 16px;
        font-weight: 350;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;

    }

    .founder-info h1 {
        font-size: 32px;
    }

    .founder-info h4 {
        font-size: 16px;
    }
}