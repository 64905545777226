h1,h2,h3,h4,h5,h6 {
    color: #164766;
}

.title{
    color: #164766;
    font-family: Lexend;
    font-size: 2rem;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.05em;
    text-align: center;    
    padding-bottom: 32px;
}

.description{
    color: #4683AA;
    font-family: Lexend;
    font-size: 1rem;
    font-weight: 350;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: justify;
}
