.test{
    background-image: linear-gradient(#4683AA,#FFFFFF,#4683AA);
}

.test2{
    border: 4px solid;
    border-image-source: linear-gradient(135deg, #4683AA 0%, #FFFFFF 49.8%, #4683AA 100%);
}

.assignmentTooltip {
    font-size: 10px;
    height: 25px;
    width: 60px;
    text-wrap: wrap;
}

@media (min-width: 992px) {
    .assignmentTooltip {
        height: 100%;
        width: 100%;
        text-wrap: nowrap;
        font-size: 16px;
    } 
}
