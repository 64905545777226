.question_from {
    margin: 1rem auto;
    position: relative;
    width: 60%;
    padding: 59px 41px 35px 41px;
    border-radius: 17px;
    border: 2px solid #0671E0;
    /* background: rgba(255, 255, 255, 0.4); */
    background: #F8FBFD;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(6px);
}

.question_from input,
.question_from textarea {
    margin-bottom: 1.5rem;
}

.question_from h2 {
    font-size: 56px;
    font-weight: 600;
    letter-spacing: -0.05em;
    color: var(--primary-700);

}

.question_from p {
    font-size: 24px;
    font-weight: 350;
    line-height: 26px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #515C7B;

}

.question_from label {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #11354D;
}

.question_from input,
.question_from textarea {
    padding: 16px;
    border-radius: 6px;
    outline: none;
    border: 1px solid #ADB2C0;
    font-size: 14px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 4px;
}

::-webkit-input-placeholder {
    color: #BEC2CE;
    /* opacity: 80%; */
}

.submit_btn {
    margin: auto;
    padding: 24px 36px;
    background: #0671E0;
    color: #fff;
    border-radius: 85px;
    border: none;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.03em;
    margin-top: 32px;
}

.submit_btn:hover {
    background: #0979f0f3;
}


.eForm_container>div {
    text-align: center;
}

.eForm_container h1 {
    font-weight: 600;
    font-size: 64px;
    line-height: 80px;
    letter-spacing: -0.05em;
    color: #11354D;
}

.eForm_container p {
    font-weight: 350;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.03em;
    color: #515C7B;
    margin-top: 20px;
}


@media (max-width: 768px) {
    .question_from {
        width: 100%;
    }

    .question_from h2 {
        font-size: 32px;
        text-align: center;
    }

    .question_from p {
        font-size: 14px;
        letter-spacing: -0.03em;
        text-align: center;
        margin: 0;
        margin-bottom: 12px;
    }

    .question_from input,
    textarea {
        margin-bottom: 1rem;
    }

    .question_from input,
    .question_from textarea {
        padding: 8px 12px;
    }

    .question_from {
        padding: 24px 34px;
    }

    .eForm_container h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .eForm_container p {
        font-size: 16px;
        line-height: 24px;

    }

    .eForm_container>div {
        padding : 64px 72px;
    }

    .submit_btn {
        font-size: 16px;
        line-height: 24px;
    }
}