.container{
    width: 100%;
    padding: 1.5rem 2rem; 
    /* animation: pop-in linear;
    animation-timeline: view(y);
    scale: 1; */
}


.imgContainer {
    border-radius: 12px;
    background: #F1F7FB;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.img{
    padding : 1rem;
    width: 100%;
    object-fit: contain;
}

.heading {
    font-family: Lexend;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #07151F;
    padding-right: 3rem;
}

.description {
    font-family: Lexend;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: left;
    color : #25335A;
}



@media (min-width : 992px){

    .container{
        width: 100%;
        padding: 2rem 7.7vw 2rem 7.7vw; 
        max-height: 474px;
        margin-top: 2rem;
    }

    
    .img{
        padding : 1rem;
        max-width: 100%;
        object-fit: contain;
    }
    
    .imgContainer {
        border-radius: 24px;
        background: #F1F7FB;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 53%;
        max-width: 700px;
    }
    
    
    .heading {
        font-size: 3rem;
        font-family: Lexend;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: left;
        color: #07151F;
    }
    
    .description {
        font-size: 1.25rem;
        font-family: Lexend;
        font-weight: 350;
        line-height: 25px;
        letter-spacing: -0.03em;
        text-align: left;
        color : #25335A;
    }

    .contentContainer {
        width: 47%;
    }
}
