.footer {
  margin: 0px;
  padding: 32px 24px 32px 24px;
  position: relative !important;
  background-color: #0b2333 !important;
}

.footer-interactive {
  border-bottom: 0.75px solid #90b4cc;
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
}

.footer-list {
  margin: 0;
  list-style-type: none;
  padding: 0;
  font-weight: 300;
  color: #bec2ce;
}

.footer-list a {
  color: #bec2ce;
  font-size: 12px;
  font-weight: 350;
  line-height: 14px;
  letter-spacing: 0em;
}

.footer-list a:hover {
  color: #0671e0;
}

.links-heading,
.links-heading a {
  font-weight: 500;
  color: #fff;
}

.footer-tagline-md {
  display: none;
}

.footer-sub-button {
  width: 34%;
  font-weight: 600;
  background-color: #0671e0;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: center;
  padding: 16px;
  color: #fff;
}

.footer-sub-button:hover {
  color: #0b2333;
  background: #fff;
}

.footer-brand-section {
  font-size: 14px;
  gap: 12px;
  font-weight: 300;
}

.footer-links-section {
  margin-top: 48px;
  font-size: 12px;
}

.footer-form-section {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: left;
}

.footer-form-controls {
  margin-top: 20px;
  margin-bottom: 48px;
}

.footer-form-controls > input {
  margin-bottom: 16px;
  background-color: #fff;
  color: #4683aa;
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.01em;
  padding: 16px;
}

.footer-form-controls > input::placeholder {
  color: #000;
}

.social-links {
  color: white;
  gap: 20px;
}

.footer-list > li {
  padding-bottom: 8px;
}

.footer-brand-ip {
  padding-top: 24px;
  font-size: 12px;
  gap: 16px;
}

.trademark p {
  margin: 0;
  font-size: 9px;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: 70%;
}


@media (min-width: 992px) {
  .footer {
    padding: 56px 72px 56px 72px;
  }

  .footer-tagline-container > .footer-tagline {
    display: none;
  }

  .footer-tagline-container > .footer-tagline-md {
    display: inline;
  }

  .footer-brand-section {
    font-size: 16px;
  }

  .footer-links-section {
    padding-top: 0px;
    margin-top: 0px;
    font-size: 12px;
    gap: 40px;
  }

  .footer-form-section {
    font-size: 24px;
  }

  .footer-form-controls {
    margin-top: 16px;
    font-size: 16px;
  }

  .footer-sub-button {
    width: 34% !important;
    height: 52px;
  }

  .footer-form-controls > input {
    height: 52px;
  }

  .footer-brand-ip {
    font-size: 9px;
  }

  .test-logo {
    height: 32;
    width: 174;
  }
}
