.faq-sec {
    padding-bottom: 15px;
    border-bottom: 1px solid #D3E1EB;
    margin: 0 auto;
}

.faq-question{
    color: #25335A;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-right: 15px;
}

.faq-answer {
    color: #515C7B;
    font-family: Lexend;
    font-size: 16px;
    font-weight: 350;
    line-height: 20px;
    letter-spacing: -0.03em;
    text-align: left;
    margin-top: 5px;
}



@media (min-width : 992px) {
    .faq-sec {
        margin-left: 22vw;
        margin-right: 22vw;
    }
    .faq-answer {
        margin-right: 30px;
    }
}
