.carousel-container {
    position: relative;
    width: 100%;
    max-width: 1237px;
    height: 111px;
    margin: 0 auto;
    overflow: visible;
  }
  
  .carousel-frame {
    position: absolute;
    width: 1237px;
    height: 111px;
    left: 0;
    top: 34px;
    background: #FFFFFF;
    border-radius: 98px;
    overflow: hidden;
    z-index: 50;
  }

  .carousel-frame-background{
    position: absolute;
    width: 1190px;
    height: 111px;
    left : 23px;
    background: #FFFFFF;
    opacity: 0.5;
    border-radius: 100px;
  }

  
  .carousel-content {
    position: absolute;
    width: 100%;
    height: 77px;
    left: 0;
    top: calc(50% - 77px/2);
    overflow: visible;
  }
  
  .carousel-images {
    position: absolute;
    height: 55.26px;
    left: 0;
    top: calc(50% - 55.26px/2 + 0.13px);
    display: flex;
    align-items: center;
    opacity: 0.62;
    animation: scroll 30s linear infinite;
    width: 200%; /* Double the width to ensure continuous scrolling */
  }
  
  .carousel-image {
    flex-shrink: 0;
    margin-right: 57px;
    object-fit: contain;
    height: auto;
    width: auto;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  @media (max-width: 768px) {
    .carousel-container {
      height: 80px;
    
    }
  
    .carousel-frame {
      height: 80px;
      width: 358px;
      border-radius: 20px;

    }
    .carousel-frame-background {
      /* height: 80px;
      width: 315px;
      border-radius: 20px; */
      display: none;

    }
  
    .carousel-content {
      height: 60px;
    }
  
    .carousel-images {
      height: 40px;
    }
  
    .carousel-image {
      max-height: 40px;
      max-width: 100px;
      margin-right: 30px;
    }
  }
  
  