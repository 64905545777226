.enq_form {
    padding:  20px 0px;
    background-color: white;
}


.contact_add_details{

    padding-top: 100px;
    padding-bottom: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
}


.add_details_1 , .add_details_2, .add_details_3{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
}


.details_box{

    padding: 10px 30px;
    background-color: white;
    color: #07151F;
    border: solid 1px #ADB2C0;
    border-radius: 10px;
}
.details_box2{

    padding: 10px 15px;
    background-color: white;
    color: #07151F;
    border: solid 1px #ADB2C0;
    border-radius: 10px;
}



@media (max-width: 768px) { 

    .contact_add_details{

        padding-top: 50px;
        padding-bottom: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 10px;
        column-gap: 0px;
    }
    
    
    .add_details_1 , .add_details_2, .add_details_3{
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
    }
    
    
    .details_box{
    
        padding: 10px 30px;
        background-color: white;
        color: #07151F;
        border: solid 1px #ADB2C0;
        border-radius: 10px;
    }
    .details_box2{
    
        padding: 10px 15px;
        background-color: white;
        color: #07151F;
        border: solid 1px #ADB2C0;
        border-radius: 10px;
    }


}