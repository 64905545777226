.mainContainer {
    width: 100%;
    max-width: 1080px;
    justify-self: center;
    margin-bottom: 160px;
    display: flex;
    justify-content: center;
   
}

.innerContainer {
    width: 100%;
    border-radius: 20px;
    border: 5px solid #E4F1FF;
    background: #FFF;
    background: linear-gradient(180deg, #FCFEFF 0%, #E4EFFC 100%);
    max-width: calc(100vw - 32px);
}

.mobileView {
    display: block;
    margin-top: 31px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 7px;
    align-items: center;
}

.mobileTextInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mobileTextEvallo {
    color: var(--Blue-700, #0671E0);
    font-family: Lexend;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%;
}

.mobileText {
    color: #333;
    text-align: center;
    font-family: Lexend;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 138%; /* 38.64px */
    height: 102px;
}

.mobileCreditInfo {
    color: #333;
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.mobileDemoButton {
    display: flex;
    width: 136px;
    height: 36px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 85px;
    border: 1.5px solid #0671E0;
    background: #0671E0;
    color: #EEF5FD;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.42px;
    margin-top: 25px;
}

.mobileImages {
    display: flex;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: flex-end;
    gap: 19px;
    width: 100%;
    padding: 0 27px;
}

.mobileImage {
    vertical-align: bottom;
    width: 66px;
    height: 190px
}

.mobileImage1 {
    vertical-align: bottom;
    width: 66px;
    height: 150px;
}

.desktopView {
    display: none;
}
  
@media (min-width: 768px) {
    .mainContainer {
        margin-bottom: 177px;
    }

    .innerContainer {
        border-radius: 27px;
        border: 10px solid #E4F1FF;
    }

    .mobileView {
        display: none;
    }

    .desktopView {
        display: flex;
        gap: 65px;
        justify-content: space-evenly;
    }

    .desktopImage {
        align-self: flex-end;
    }

    .textInfo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 62px;
    }
    
    .textEvallo {
        color: var(--Blue-700, #0671E0);
        font-family: Lexend;
        font-size: 42px;
        font-style: normal;
        font-weight: 300;
        line-height: 138%;
    }
    
    .desktopText {
        color: #333;
        text-align: center;
        font-family: Lexend;
        font-size: 42px;
        font-style: normal;
        font-weight: 300;
        line-height: 138%; /* 57.96px */
    }
    
    .creditInfo {
        color: #333;
        text-align: center;
        font-family: Lexend;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 27px */
        margin-top: 30px;
    }

    .buttonImages {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
    }

    .demoButton {
        display: flex;
        height: 50px;
        padding: 14px 52px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        color: #0671E0;
        text-align: center;
        font-family: Lexend;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        border-radius: 28px;
        border: 2px solid #0671E0;
        background: none;
    }

    .desktopImagesLeft {
        display: flex;
        align-items: flex-end;
        margin-left: 100px;
    }

    .desktopImagesRight {
        display: flex;
        align-items: flex-end;
        margin-right: 100px;
    }

    .desktopImage {
        height: 113px;
        width: 73px;
    }

    .desktopBigImage {
        height: 193px;
        width: 73px;
    }
}
  