.ev-card {
    border-radius: 12px;
    background-color: #F1F7FB;
    box-shadow: 0px 1.9941691160202026px 10.269970893859863px 0px #CCE4F6;
    padding: 10px;
}

.ev-card-heading {
    color: #25335A;
    font-family: Lexend;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.05em;
    text-align: center;
    
}

.ev-card-desc {
    font-family: Lexend;
    font-size: 10px;
    font-weight: 350;
    line-height: 13px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #515C7B;
}

@media (min-width : 992px) {
    .ev-card {
        border-radius: 24px;
    }
    
    .ev-card-heading {
        font-size: 48px;
        font-family: Lexend;
        font-size: 48px;
        font-weight: 600;
        line-height: 60px;
        letter-spacing: -0.05em;
        text-align: center;
    }
    
    .ev-card-desc {
    font-family: Lexend;
    font-size: 20px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: -0.03em;
    text-align: left;


    }
}