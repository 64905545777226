.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 95px;
  margin-bottom: 278px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 45px;
  justify-content: space-between;
  width: 100%;
}

.mainSection {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 50px;
  width: 100%;
}

.contentWrapper-md {
  gap: 24px;
}

.title {
  color: #33373b;
  text-align: center;
  font-family: "Lexend";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 48px */
  letter-spacing: -0.64px;
}

.optionsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.card {
  display: flex;
  background-color: #fcfcfcf0;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  max-width: 467px;
  height: 160px;
  max-height: 160px;
  padding-left: 6px;
}

.cardIconWrapper {
  height: 100%;
  padding: 6px 0px 6px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 148px;
  background-color: #eff5fb;
  border-radius: 12px;
}

.cardContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-top: 15px;
  padding-left: 26px;
  gap: 8px;
  width: 260px;
  height: 148px;
  max-height: 160px;
  overflow: hidden;
}

.cardTitle {
  color: #202b39;
  font-family: "Gilroy-Medium";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: -0.2px;
  margin-bottom: 0px;
}

.cardDescription {
  color: var(--Button-grey-text, #636363);
  font-family: "Gilroy-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin-bottom: 0px;
}

.cardLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  width: 71px;
  height: 26px;
  border-radius: 13px;
  margin-top: 10px;
}

.signInText {
  font-family: "Gilroy-Regular";
  font-size: 16px;
  line-height: 19.2px;
  color: var(--secondary-90);
}

.signInLink {
  color: var(--blue-70);
  font-family: "Gilroy-Medium";
  text-decoration: none;
}

.signInLink:hover {
  color: var(--blue-70);
}

.bottomSection {
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16.8px;
}

.bottomSection-md {
  font-size: 17px;
  line-height: 20.4px;
}

.bottomText {
  font-family: "Gilroy-Regular";
  color: var(--secondary-90);
}

.learnMoreLink {
  color: var(--blue-70);
  font-family: "Gilroy-Medium";
  text-decoration: none;
}

.learnMoreLink:hover {
  color: var(--blue-70);
}
.goAnim {
  display: flex;
  align-items: center;
  animation: move 2s infinite;
}
@keyframes move {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (max-width: 1024px) {
  .optionsWrapper {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 100%;
    padding-left: 6px;
  }
  .cardTitle {
    font-size: 16px;
    line-height: 150%; /* 24px */
    letter-spacing: -0.16px;
  }
  .cardDescription {
    font-size: 12px;
    line-height: 150%; /* 18px */
  }
  .cardIconWrapper {
    width: 142px;
    height: 134px;
  }
  .cardContent {
    width: 183px;
    height: 130px;
  }
  .cardLink {
    margin-top: 8px;
    width: 50px;
    height: 26px;
  }
  .title {
    font-size: 20px;
    line-height: 150%; /* 30px */
    letter-spacing: -0.4px;
  }
  .container{
    padding: 0px 24px;
  }
}
