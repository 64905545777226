.card {
    max-width: 309px;
    /* height: 486px; */
    border-radius: 24px;
    /* border: 1px solid #ADB2C0; */
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    /* border: 4px solid #0671E0; */
}

.recommend {
    position: absolute;
    top: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.5px;
    padding: 10px 0;
    background: #0671E0;
    color: #fff;
    text-align: center;
    width: 100%;
}

.card>div+div {
    padding: 74px 49px 37px 49px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 29px;
    height: 100%;
    border-radius: 24px;
}

.plan_type {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.6px;
    color: #0671E0;
    text-transform: uppercase;
}

.price_amt>h1 {
    font-weight: 600;
    font-size: 56px;
    line-height: 65px;
    letter-spacing: -1px;
    color: #25335A;
}

.price_amt>div {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #67718c;
    margin-top: 12px;
}

.plan_desc {
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    color: #515C7B;
    /* padding: 0 10px; */
}

.btn_div>button {
    background: #066fe013;
    padding: 13px 35px;
    border-radius: 60px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #0671E0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.btn_div>button:hover {
    background-color: rgba(6, 111, 224, 0.275);
}

.recommendedButton {
    background-color: #0671E0 !important;
    color: white !important;
}

.recommendedButton:hover {
    background-color: rgba(9, 111, 219, 0.8) !important;
}

.btn_div>div {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #25335A;
    opacity: 70%;
    margin-top: 12px;
}

.plan_day {
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    letter-spacing: normal;
}

.dollar {
    font-size: 24px;
}

@media (max-width: 768px) {
    .card {
        width: 100%;
    }

    .card>div+div {
        gap: 16px;
        padding: 60px 50px 30px 50px;
    }

    .plan_type {
        font-size: 24px;
        line-height: 30px;
    }

    .price_amt>h1 {
        font-size: 32px;
        margin-bottom: -20px;
    }

    .dollar {
        font-size: 14px;
    }

    .plan_day {
        font-size: 14px;
    }

    .price_amt>div {
        font-size: 14px;
        margin-top: 0;
    }

    .plan_desc {
        font-weight: 350;
        font-size: 14px;
        line-height: 18px;
        color: #515C7B;
    }
}