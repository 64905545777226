.submit_btn {
  margin: auto;
  padding: 20px 40px;
  background: #0671e0;
  color: #fff;
  border-radius: 85px;
  border: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.03em;
  margin-top: 32px;
}

.submit_btn:hover {
  background: #0979f0f3;
}
.eForm_container {
  background-color: #ffffff;
  padding: 64px 72px;
  border-radius: 32px 32px 0px 0px;
}

.eForm_container > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0px 137px;
}

.eForm_container h1 {
  font-weight: 600;
  font-size: 64px;
  line-height: 80px;
  letter-spacing: -0.05em;
  color: #11354d;
}
.eForm_container span {
  color: #0671e0;
}

.eForm_container p {
  font-weight: 350;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.03em;
  color: #515c7b;
  /* margin-top: 20px; */
  margin: 0;
  padding: 0px 221px;
}

@media (max-width: 1200px) { 
  .eForm_container p {
    padding: 0px 100px;
  }
  .eForm_container {
    border-radius: 24px 24px 0px 0px;

    padding: 32px 72px;
  }
  .eForm_container > div {
    padding: 0px;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .eForm_container {
    border-radius: 24px 24px 0px 0px;

    padding: 32px 72px;
  }

  .eForm_container > div {
    padding: 0px;
    gap: 20px;
  }

  .eForm_container h1 {
    font-size: 34px;
    line-height: 38.4px;
  }

  .eForm_container p {
    font-size: 14px;
    line-height: 24px;
    padding: 0px;
  }

  .submit_btn {
    font-size: 16px;
    line-height: 24px;

    margin-top: 12px;
  }
}
