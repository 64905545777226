.mainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 72px;
    padding-top: 115px;
  }
  
  .innerContainerSavings {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: calc(100vw - 32px);
    /* gap: 38px; */
  }

  .textInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding-bottom: 18px;
  }

  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .headingBlue {
    color: #0671E0;
    text-align: center;
    font-family: Lexend;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 42px */
  }

  .headingBlack {
    color: #3F4449;
    font-family: Lexend;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  .description {
    color: #33373B;
    text-align: center;
    font-family: Lexend;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 142%; /* 17.04px */
    width: 70%;
  }

  .separator {
    width: 17px;
    height: 1;
    margin-bottom: 38px;
  }

  .toggleArea {
    width: 100%;
    padding-bottom: 14px;
  }

  .toggleComponent {
    width: 100%;
    display: flex;
    padding: 8px;
    border-radius: 6px;
    gap: 12px;
  }

  .active {
    background: var(--Blue-BgLight, #F3F9FF);
  }

  .iconContainer {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 12px;
    border: 1px solid var(--Blue-700, #0671E0);
    background: var(--Blue-700, #175CD3);
  }

  .headerArea {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .heading {
    width: 80%;
    color: #2F3441;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  .cardComponent {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 18px;
    flex-direction: column;
    padding-bottom: 44px;
  }

  .cardDescription {
    width: 75%;
    color: #33373B;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }

  .demoButton {
    border-radius: 24px;
    background: #FCFCFC;
    border-radius: 85px;
    border: 1.5px solid var(--Blue-700, #0671E0);
    display: flex;
    width: 115px;
    height: 36px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: var(--Blue-700, #0671E0);
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.42px;
    align-self: flex-start;
    margin-top: 23px;
    margin-bottom: 36px;
  }

  .imageContainer {
    width: 75%;
  }
  
  .cardImage{
    height: 260px;
    width: 100%;
  }


  @media (min-width: 500px) {
    .cardImage{
      height: auto;
    }
  }
  
  @media (min-width: 768px) {
    .mainContainer {
        display: none;
    }
  }

  