.simpleEnquiryFormPopup {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #0000006f;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    z-index: 9999;
}