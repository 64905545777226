.featureHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 27px;
    gap: 11px;
    width: 100%;
  max-width: 1285px;
    margin: 0 auto;
    padding-bottom: 106px;
  }

  .featureHeaderTitle {
    width: 747px;
    height: 63px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #0671E0;
    gap: 11px;
  }
  
  .featureHeaderPara {
    width: 570px;
    height: 52px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 142%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #696D71;
  }
  
  .headerLine {
    width: 51px;
    height: 0;
    border: 1px solid #0671E0;
  }
  
.featuresContainer {
  width: 100%;
  max-width: 1285px;
  background-color: #FFFFFF ;
  display: grid;
  grid-template-columns: 46% auto;
  justify-content: space-between;
  /* align-content: space-between; */
  margin: 0 auto;
  min-width: calc(100vw - 156px);
  gap: 50px;
}

.featuresSectionLeft {
}

.featuresSectionRight {
}

.blacktext
{
  color: #3F4449;
}

.expandCard {
  box-shadow: none !important;
  margin-top: 24px;
  position: relative;
  display: flex;
  align-items: center;
}

.activeLine {
  min-height: calc(100% - 88px);
  /* border: 1px solid; */
  border-left: 1px dashed #0671e0;
  position: absolute;
  left: 35px;
  top: 72px;
}

.expandCard::before {
  background: none !important;
}

.expandCardContainer {
  padding: 0 0px;
  width: 100%;
}

.accordionDeatils {
  padding: 0px !important;
}

.expandCardTab {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  text-wrap: nowrap;
  white-space: nowrap;
  background-color: transparent;
}

.expandCardTab > div {
  font-weight: 400;
  font-size: 22px;
  line-height: 52px;
  color: #3b4045;
}

.activeExpandCardTab {
  display: flex;
  align-items: center;
  gap: 12px;
}

.activeExpandCardTab {
  font-weight: 400;
  font-size: 28px;
  line-height: 52px;
  color: #3b4045;
  max-width: 100%;
  text-wrap: nowrap;
}

.activeExpandHeadingText{
  max-width: 467px;
}

.featuresSectionRight > img {
  width: 100%;
  height: 100%;
  max-height: 582px;
}

@media (max-width: 768px) {
  .featuresContainer {
      display: none;
  }
  .featureHeader
  {
    display: none;
  }
}