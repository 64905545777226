.lead_form {
    /* width: 740px; */
    width: calc(100vw*(740/1440));
    /* height: calc(100vw*(591/1440)); */
    height: 100%;
    border-radius: 17.24px;
    position: relative;
    z-index: 2;

}

.lead_form div{
    padding-bottom: 20px !important;
}




.lead_form form {
    width: 100%;
    height: 100%;
    background: #FFFFFF4D;
    border: 4px solid #ffffffdb;
    padding-top: calc(100vw*(30/1440));
    padding-bottom: calc(100vw*(30/1440));
    padding-left: calc(100vw*(25/1440));
    padding-right: calc(100vw*(25/1440));
    border-radius: 1.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
}

.lead_form form label {
    font-family: Lexend;
    font-size: calc(100vw*(18/1440));
    font-weight: 600;
    line-height: calc(100vw*(26/1440));
    text-align: left;
    color: #216A99;
}

.lead_form form input::placeholder,
textarea::placeholder {
    /* font-family: Lexend;
    font-size: 20px;
    font-weight: 350;
    line-height: 29px;
    text-align: left; */
    color: #515C7B;
    font-family: Lexend;
    font-size: 20px !important;
    font-weight: 350;
    line-height: calc(100vw*(29/1440));
    text-align: left;
}

.lead_form form input {
    margin-top: 4px;
    font-size: 20px !important;
}

.lead_form form textarea {
    font-family: Lexend;
    font-size: calc(100vw*(20/1440));
    font-weight: 350;
    line-height: calc(100vw*(29/1440));
    text-align: left;
    padding: calc(100vw*(16/1440));

    margin-top: 4px;
}

/* .lead_form form input:focus,
textarea:focus {
    background: #216A99;
    color: #fff;
    font-weight: 600;
    outline: 1px solid #fff;

}

.lead_form form input:focus::placeholder,
textarea:focus::placeholder {
    color: #fff;
} */

.submit_btn {
    padding: calc(100vw*(15/1440));
    border-radius: 3rem;
    background: #0671E0;
    border: none;
    width: 100%;
    font-size: calc(100vw*(20/1440));
    font-weight: 500;
    line-height: calc(100vw*(23/1440));
    letter-spacing: -0.02em;
    text-align: left;
    color: #fff;
    text-align: center;
}

@media (max-width: 768px) {
    .lead_form {
        height: 100%;
        width: 100%;
    }

    .lead_form form {


        padding: 24px;
        border-radius: 16px;
        border: 4px;

    }

    .lead_form div{
        padding-bottom: 10px !important;
    }
    

    .lead_form form label {
        font-family: Lexend;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.3px;
        text-align: left;

    }

    .lead_form form input {
        padding: 10px !important;
        border-radius: 6px;
        font-family: Lexend;
        font-size: 15px !important;
        font-weight: 350;
        line-height: 20.3px;
        text-align: left;

    }

    .lead_form form textarea {
        padding: 10px;
        border-radius: 6px;
        font-family: Lexend;
        font-size: 15px !important;
        font-weight: 350;
        line-height: 20.3px;
        text-align: left;


    }

    
    

    .lead_form form input::placeholder,
    textarea::placeholder {
        font-family: Lexend;
        font-size: 15px !important;
        font-weight: 350;
        line-height: 20.3px;
        text-align: left;

    }

    .submit_btn {

        padding: 12px 24px 12px 24px;
        border-radius: 24px;
        font-family: Lexend;
        font-size: 16px;
        font-weight: 500;
        line-height: 18.4px;
        letter-spacing: -0.02em;

    }

    /* .lead_form form {
        border: 2px solid white;
        padding-top: calc(100vw*(30/375));
        padding-bottom: calc(100vw*(30/375));
        padding-left: calc(100vw*(25/375));
        padding-right: calc(100vw*(25/3));
    } */


}