.logo {
    height: 100%;
    width: 60%;
    object-fit: contain;
}

.fullHeight {
    height: 100vh;
}


@media (min-width : 992px) {

    .logo {
        max-height: 49px;
        max-width: 132px;
    }

    .fullHeight {
        height: 100%;
    }

    .docsButton {
        background-color: #216A99;
        font-family: Lexend;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.03em;
        color : #FFFFFF;
        border-radius: 85px;
        border: none;
        max-height: 38px;
        padding: 12px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

     
}