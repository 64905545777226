:root {
    --background: #e5f0f7;
    --primary-700: #164766;
    --primary-800: #11354d;
    --primary-500: #25335A;
    --primary-base: #216a99;
    --items-container-bg: #f1f7fb;
  }
  
  .head_block {
    /* padding-bottom: 100px; */
  }
  
  .cont_blog {
    width: 100%;
    padding: 59px 72px;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
  
  .search_box {
    background-color: #ffff;
    padding: 18px 32px;
    border-radius: 16px;
    gap: 10px;
    outline: none;
    align-items: center;
    justify-content: center;
  }
  
  .search_box img {
    width: 22px;
    height: 22px;
  }

  .search_box svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  
  .search_box input {
    border: none;
    outline: none;
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    color: #0671e066;
  }
  
  .search_box input::placeholder {
    font-size: 24px;
    font-weight: 350;
    line-height: 30px;
    color: #0671e066;
  }
  
  .categories h2 {
    font-size: 48px;
    font-weight: 600;
  
    line-height: 62.4px;
    color: #11354d;
  }
  
  .blog_pins {
    gap: 64px;
  }
  
  .categories {
    display: flex;
    flex-direction: column;
    gap: 16px;
 
  }

 
  
  .topic_btn {
    padding: 15px 25px;
    border-radius: 60px;
    background-color: transparent;
    outline: none;
    color: #0671e080;
    font-size: 24px;
    line-height: 30px;
    font-weight: 450;
    border: solid 1px #0671e080;
    backdrop-filter: blur(3px);
  }
  
  .btn_selected {
    background-color: #0671e0;
    color: #ffff;
  }
  
  .cat_list{
      gap: 10px;
  }

  .start_div{
    padding-top: 100px;
  }
  
  /* ********************* */
  
  @media (max-width: 768px) {
    .head_block {
      padding-bottom: 8px;
    }
  
    .cont_blog {
      width: 100%;
      padding: 16px 0px;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  
    .cont_blog_outer {
      padding: 10px 28px;
    }
  
    .cat_list{
      align-items: center;
      justify-content: center;
  }
  
    .search_box img {
      width: 16px;
      height: 16px;
    }
  
    .search_box input {
      font-size: 14px;
      font-weight: 350;
      line-height: 17.5px;
      padding: 0;
    }
  
    .search_box input::placeholder {
      font-size: 14px;
      font-weight: 350;
      line-height: 17.5px;
    }
  
    .categories h2 {
      font-size: 28px;
      line-height: 33.6px;
      text-align: center;
    }
  
    .topic_btn {
      padding: 15px 20px;
      font-size: 16px;
      line-height: 20px;
    }
  
  
    .blog_pins{
      flex-direction: column-reverse;
  
      gap: 16px;
    }

    .start_div{
      padding-top: 20px;
    }
  }
  
  
  @media (max-width: 1250px) {
    
    .blog_pins{
      flex-direction: column-reverse;
    }
  }