.testomonialCard {
    min-width: 546px;
    max-width: 546px;
    border-radius: 16px;
    padding: 48px;
    background: #F4F5F7;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* margin: 0 24px; */
}

.reviewerImage {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    /* background: #0671E0; */
    color: #ffffff;
    display: grid;
    place-items: center;
}

.reviewerImage>div {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
}

.reviewerImage>img {
    width: 100%;
    object-fit: cover;
}

.reviewerInfo {
    display: flex;
    align-items: center;
    gap: 12px;
}

.reviewerName>div:first-child {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000B0F;
}

.reviewerName>div+div {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000B0F;
    margin-top: 2px;
}

.review {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #313435;
}

.starsContainer {
    display: flex;
    gap: 8px;
}

@media screen and (max-width: 768px) {
    .testomonialCard {
        min-width: 296px;
        max-width: 296px;
        border-radius: 16px;
        padding: 16px;
        gap: 10px;
    }

    .reviewerImage>div {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .reviewerImage {
        width: 37px;
        height: 37px;
    }

    .reviewerName>div:first-child {
        font-size: 12px;
        line-height: 18px;
    }

    .reviewerName>div+div {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        margin-top: 0;
    }

    .review {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
    }
}