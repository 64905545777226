.mainContainer {
  width: 100%;
  padding: 0 16px;
  margin-bottom: 32px;
}

.innerContainer {
  width: 100%;
}

.mobileComponent {
  display: block;
  width: 100%;
}

.mobileTextInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.mobileHeading {
  color: #31363B;
  text-align: center;
  font-family: Lexend;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 42px */
  margin-bottom: 8px;
}

.mobileDescription {
  color: #33373B;
  text-align: center;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 148%; /* 17.76px */
  margin-bottom: 17px;
  width: 90%;
}

.mobileSeparator {
  width: 17px;
  height: 1px;
  color: #33373B;
  margin-bottom: 54px;
}

.toggleCards {
  display: flex;
  gap: 17px;
  flex-direction: column;
}

.toggleComponent {
  width: 100%;
  padding: 8px;
  display: flex;
  border-radius: 6px;
  justify-content: space-between;
}

.nameImage {
  display: flex;
  gap: 8px;
}

.imageArea {
  border-radius: 37px;
  border: 1px solid var(--Blue-700, #0671E0);
  background: var(--Blue-700, #175CD3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
}

.toggleImage {
  width: 22.845px;
  height: 22.845px;
}

.nameArea {
  color: #2F3441;
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  align-self: center;
}

.active {
  background-color: #F3F9FF;
}

.cardsInfo {
  margin-top: 38px;
  display: flex;
  gap: 17px;
  flex-direction: column;
}

.mobileCard {
  border-radius: 16px;
  border: 1px dashed #A0BEDD;
  display: flex;
  gap: 13px;
  padding-left: 17px;
}

.cardImage {
  width: 36px;
  height: 36px;
  margin-top: 15px;
}

.cardText {
  margin-top: 22px;
  display: flex;
  gap: 9px;
  flex-direction: column;
}

.cardHeading {
  color: #25292E;
  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 18.9px */
}

.cardDescription {
  color: #33373B;
  font-family: Lexend;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 148%; /* 17.76px */
  margin-bottom: 17px;
}

@media (min-width: 768px) {
  .mobileComponent {
      display: none;
  }
  .mainContainer {
    margin-bottom: 130px;
  }
}
