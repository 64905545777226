    .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    width: 100%;
}

.heading {
    font-family: Lexend;
    font-size: 2rem;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;
    color: #07151F;
}

.description {
    font-family: Lexend;
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 19px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #25335A;
    width: 100%;
}

.image {
    width: 100%;
    object-fit: contain;
}

@media (min-width : 992px) {


    .heading {
        margin-top: -1.75rem;
        font-family: Lexend;
        font-size: 4rem;
        font-weight: 600;
        line-height: 77px;
        letter-spacing: -0.05em;
        text-align: center;
    }

    .description {
        font-family: Lexend;
        font-size: 1.5rem;
        font-weight: 350;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: center;
        width: 80%;
        margin: auto;

    }

    .container {
        padding-left: 17.5vw;
        padding-right: 17.5vw;
        padding-bottom: 5rem;
    }

    .image {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}