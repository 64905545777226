.formStrip {
  background: #0b2333;
  height: 100px;
  position: absolute;
  bottom: -0%;
  transform: translateY(2%);
}

.signin-from {
  margin: 1rem auto;
  top: 0;
  transform: translateY(40%);
  position: relative;
  width: 40%;
  padding: 2rem;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  border-left: 4px solid #ffffff7b;
  border-top: 4px solid #ffffff7b;
  background: linear-gradient(
    280deg,
    rgba(255, 255, 255, 0.014) 43%,
    rgba(255, 255, 255, 0.43463322829131656) 99%
  );
}

.signin-from input,
textarea {
  margin-bottom: 1.5rem;
}

.signin-from h2 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.05em;
  color: var(--primary-700);
}

.signin-from p {
  font-size: 22px;
  font-weight: 350;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  color: var(--primary-500);
}

.signin-from label {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.signin-from input,
.signin-from textarea {
  padding: 16px;
  border-radius: 6px;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 350;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

::-webkit-input-placeholder {
  color: var(--primary-500);
  opacity: 80%;
}

.submit-btn {
  margin: auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 32px;
  background: var(--primary-base);
  color: #fff;
  border-radius: 24px;
  border: none;
}

.form-bottom-strip {
  background: #0b2333;
  height: 250px;
  position: absolute;
  bottom: -0%;
  transform: translateY(25%);
}

.bg-vector {
  position: absolute;
  top: 45%;
  left: -25%;
  transform: translate(25%, -25%);
}

.bg-vector-1 {
  position: absolute;
  top: 25%;
  right: -10%;
  transform: translate(-35%);
  rotate: 120deg;
  z-index: 0;
}

@media (max-width: 768px) {
  .signin-from {
    width: 100%;
  }

  .signin-from h2 {
    font-size: 32px;
  }

  .signin-from p {
    font-size: 14px;
  }

  .signin-from input,
  textarea {
    margin-bottom: 1rem;
  }
}
