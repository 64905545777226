.input_container {
    padding: 26px;
    border: 1px dashed #CDCFD3;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    margin: 8px 0;
}

.input_div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: 12px;
}

.input_div>p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.input_div>p span {
    color: #3672E8;
}

.icon {
    color: rgb(1, 235, 252);
    font-size: 2rem;
    cursor: pointer;
    animation: iconflicker 2s linear infinite;
}

.input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;
}