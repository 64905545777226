.cardContainer {
    width: 402px;
    border-radius: 12px;
    padding: 24px;
    background: #F3F9FF;
    display: flex;
    flex-direction: column;
    gap: 36px;
    box-shadow: 0px 2px 16px 0px #00000026;

}

.cardContainer>div:first-child {
    /* background: #000; */
}

.cardTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: #000000;
    opacity: 90;
}

.cardDesc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    opacity: 90;
    padding-top: 8px;
}

.cardImage>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 768px) {
    .cardContainer {
        min-width: 315px;
        max-width: 315px;
        border-radius: 12px;
        gap: 24px;
        box-shadow: 0px 8px 16px 0px #0000000D;

    }

    .cardTitle {
        font-size: 16px;
        line-height: 24px;

    }

    .cardDesc {
        font-size: 14px;
        line-height: 21px;
    }
}