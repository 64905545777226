.slide-container {
    position: relative;
    height: 100%;
    width: calc(100vw*(500/1440));
    z-index: 2;
}

.data-container {
    position: relative;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    background: linear-gradient(114.89deg, rgba(255, 255, 255, 0.3) 1.14%, rgba(255, 255, 255, 0) 101.59%),
        linear-gradient(355.31deg, rgba(255, 255, 255, 0.2) 5.27%, rgba(255, 255, 255, 0) 95.08%);
    backdrop-filter: blur(4px);
    background-image: radial-gradient(circle at 100% 100%, transparent 20px, #ffffff 20px, #ffffff39 24px, transparent 24px), linear-gradient(to right, #ffffff89, #ffffff00), radial-gradient(circle at 0% 100%, transparent 20px, #ffffff00 20px, #ffffff00 24px, transparent 24px), linear-gradient(to bottom, #ffffff00, #ffffff00), radial-gradient(circle at 0% 0%, transparent 20px, #ffffff00 20px, #ffffff00 24px, transparent 24px), linear-gradient(to left, #ffffff00, #ffffff0b), radial-gradient(circle at 100% 0%, transparent 20px, #ffffff3a 20px, #ffffff17 24px, transparent 24px), linear-gradient(to top, #ffffff2a, #ffffffe2);
    background-size: 24px 24px, calc(100% - 48px) 4px, 24px 24px, 4px calc(100% - 48px);
    background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
    background-repeat: no-repeat;
}

.data-item {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease;
    width: 100%;
    height: 100%;
    padding: 35px 33px;
}

.slide-image-container {
    margin-bottom: calc(100vw*(40/1440));
    width: 100%;
    height: 40%;

}

.data-item img {
    width: 100%;
    object-fit: cover;
}

.data-item h2 {
    font-family: Lexend;
    font-size: calc(100vw*(26/1440));
    font-weight: 700;
    line-height: calc(100vw*(30/1440));
    letter-spacing: -0.03em;
    text-align: left;
    color: #4683AA;
    margin-bottom: 15px;
}

.data-item p {
    font-family: Lexend;
    font-size: calc(100vw*(24/1440));
    font-weight: 350;
    line-height: calc(100vw*(36/1440));
    letter-spacing: -0.03em;
    text-align: left;
    color: #515C7B;
}

.data-item.active {
    opacity: 1;
}

.data-item:not(.active) {
    opacity: 0;
    transition: opacity .5s ease-in;
    /* Add a delay for fade-out */
}

@media (max-width: 768px) {
    .slide-container {
        height: calc(100vw*(590/1440));
        display: none;
    }

    .data-item p {
        font-size: calc(100vw*(20/1440));
    }
}