.desktopComponent {
    display: flex;
    flex-direction: column;
}

.textInfo {
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding-bottom: 60px;
    align-items: center;
}

.heading {
    color: #31363B;
    text-align: center;
    font-family: Lexend;
    font-size: 42px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 63px */
}

.description {
    color: #31363B;
    text-align: center;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 142%; /* 25.56px */
    width: 45%;
}

.buttonsArea {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;
    padding-bottom: 88px;
}

.buttonComponent {
    width: 300px;
    height: 74px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 9px;
    border: 0.8px solid #D5DEE7;
    cursor: pointer;
}

.activeButton {
    background: #0671E0;
    border: none;
}

.buttonImage {
    height: 60px;
    width: 60px;
    border-radius: 100%;
}

.buttonText {
    color: #25335A;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
}

.activeButton .buttonText {
    color: #FCFCFC;
    font-family: Lexend;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 27px */
}

.cardsComponent {
    display: flex;
    justify-content: center;
    gap: 26px;
}

.cardData {
    width: 270px;
    height: 213px;
    border: 1px dashed #A0BEDD;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.cardHovered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 11px;
}

.cardHoverHeading {
    color: #25292E;
    text-align: center;
    font-family: Lexend;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 21.6px */
    width: 233px;
}

.cardHoverDescription {
    width: 214px;
    color: var(--secondary-80, #515C7B);
    text-align: center;
    font-family: Lexend;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 21px */
}

.cardNormal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;

}

.cardImage {
    width: 61px;
    height: 61px;
}

.cardHeading {
    color: #25292E;
    text-align: center;
    font-family: Lexend;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 27px */
    width: 168px;
}

@media (max-width: 768px) {
    .desktopComponent {
        display: none;
    }

    .mainContainer {
        padding: 0;
    }
}
