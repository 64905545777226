.landing_container {
    background-image: url("../../assets/backgrounds/reverse_grid.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-size: contain;
}


.bg_grid_container img {
    width: 100%;
    object-fit: cover;
}

.page_container {
    height: 100%;
    width: 100%;
    /* position: absolute; */
    position: relative;
    top: 0;
}

.landing_header {
    margin-top: 3.25rem;
    /* background: #ffffff; */
}

.landing_header h1 {
    font-family: Lexend;
    font-size: calc(100vw*(48/1440));
    font-weight: 600;
    line-height: calc(100vw*(60/1440));
    letter-spacing: -0.05em;
    text-align: center;
    color: #164766;
    /* margin-top: 5rem; */
    padding-top: 3.5rem;
}

.landing_header h1>span {
    color: #B5CDDD;
}

.slide_form_container {
    /* padding: 0 4.4rem; */
    /* display: grid;
    grid-template-columns: 1fr 2fr; */
    display: flex;
    justify-content: center;
    gap: calc(100vw*(60/1440));
    margin-top: 5rem;
    margin-bottom: 6rem;
    z-index: 2;
    /* overflow-y: hidden; */
    /* background: #3279e5; */
}

.slides {
    /* background: #f96a6a81; */
    /* display: none; */
    /* padding: 1rem 0; */
    /* max-width: 500px; */
    /* width: 100%; */
    /* background: #000; */
    position: relative;

}

.tri_r {
    position: absolute;
    top: -3%;
    right: -20%;
    z-index: 9;
}

.tri_l {
    position: absolute;
    bottom: -12%;
    left: -8%;
    z-index: 9;
}

.tri_form {
    position: absolute;
    bottom: -5%;
    right: -5%;
    rotate: 80deg;
}

.form_container {
    /* background: #7bf9d9a7; */
    /* min-height: 600px; */
    border-radius: 0;
    position: relative;
    padding-top: 43px;
    padding-bottom: 80px;
}

.form_container iframe {
    height: 100%;
    width: 100%;
    border-radius: 0;
}

.section_seven {
    background: radial-gradient(49.93% 70.21% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(217, 234, 243, 0.3) 100%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
    backdrop-filter: blur(4px);
    padding: 32px 48px;
}

.top_border {
    height: 4px;
    width: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9640449929971989) 34%, rgba(255, 255, 255, 0.25536151960784315) 99%);

}

.lead_faq {

    width: 100%;
    /* padding: 32px 18vw; */

    margin-top: 70px;
    margin-bottom: 100px;

}

.lead_faq_child {
    width: 66%;
    margin: auto;
}

.lead_faq>div {
    background: radial-gradient(49.93% 70.21% at 50% 50%, rgba(255, 255, 255, 0.3) 0%, rgba(217, 234, 243, 0.3) 100%),
        linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
    backdrop-filter: blur(4px);
    border-radius: 20px;
    background-image: radial-gradient(circle at 100% 100%, transparent 20px, #ffffff 20px, #ffffff39 24px, transparent 24px), linear-gradient(to right, #ffffff89, #ffffff00), radial-gradient(circle at 0% 100%, transparent 20px, #ffffff00 20px, #ffffff00 24px, transparent 24px), linear-gradient(to bottom, #ffffff00, #ffffff00), radial-gradient(circle at 0% 0%, transparent 20px, #ffffff00 20px, #ffffff00 24px, transparent 24px), linear-gradient(to left, #ffffff00, #ffffff0b), radial-gradient(circle at 100% 0%, transparent 20px, #ffffff3a 20px, #ffffff17 24px, transparent 24px), linear-gradient(to top, #ffffff2a, #ffffffe2);
    background-size: 24px 24px, calc(100% - 48px) 4px, 24px 24px, 4px calc(100% - 48px);
    background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
    background-repeat: no-repeat;
    padding: 32px 54px;

}

.popup_calendly {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    background: #0000001c;
    /* border: 2px solid greenyellow; */
    /* display: none; */
}

.popup_calendly iframe {
    width: 100%;
    height: 100%;
    /* margin: auto; */
}

.hide_cal {
    display: none;
}

@media (max-width: 768px) {
    .slide_form_container {
        flex-direction: column-reverse;

    }

    .slides {
        grid-column: 1/2;
        grid-row: 1/2;
        /* height: 500px; */

    }

    .form_container {
        position: relative;
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .slide_form_container {
        padding: 1rem;
    }

    .lead_faq {
        padding: 0 1rem;
        margin-bottom: 2rem;
    }

    .lead_faq_child {
        width: 100%;
    }

    .lead_faq>div {
        border: none;
        padding: .5rem;
    }

    .tri_r {
        display: none;
        top: -50;
    }

    .landing_header h1 {

        font-family: Lexend;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.05em;
        text-align: center;
        padding: 0;
    }

    .slide_form_container {
        padding-top: 0;
    }

    .lead_faq_child>div {
        padding: 0 1rem;
    }

    .popup_calendly {
        padding: 2rem 1rem;
    }
}