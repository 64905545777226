.select-pill-container {
    background-color: #33617F;
    gap : 15px;
    font-family: Lexend;
    font-size: 13px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.01em;
    text-align: center;
    padding: 5px;
}

.select-pill-selected,
.select-pill-normal {
    border: none;
    padding: 8px 16px;
}

.select-pill-selected {
    background-color: white;
    color : #216A99;
    border-radius: 25px;

}

.select-pill-normal {
    background-color: #33617F;
    color: white;
    border-radius: 25px;
}

@media (min-width : 992px) {
    .select-pill-container {
        gap : 15px;
        font-family: Lexend;
        font-size: 18px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.01em;
        text-align: center;        
        padding: 5px;
    }
    
    .select-pill-selected,
    .select-pill-normal {
        border: none;
        padding: 13px 24px;
    }
}