/* .................... Mobile first .......... */

.hero-image-container h1 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-align: center;
    color: #164766;
}


.sl-demo{
    height: 200px !important;
    border-radius: 0px;
}

.hero-image-container p {
    font-size: 14px;
    font-weight: 350;
    line-height: 19px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #25335A;

}

.hero-image-container button {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.03em;
    text-align: center;
    padding: 12px 16px;
    border-radius: 113.6px;
    background: #0671E0;
    color: #fff;
    border: none;

}

.hero-image-container {
    padding-top: 50px;
    width: 94%;
}

.hero-image-container>div {
    position: relative;
    top: -25px;

}


@media screen and (min-width: 450px) {

    .sl-demo{
        height: 30% !important;
        border-radius: 0px;
    }

}

@media screen and (min-width: 768px) {

    .hero-image-container h1 {
        font-size: 64px;
        font-weight: 600;
        line-height: 80px;
        letter-spacing: -0.05em;
        text-align: center;
        color: #164766;
    }
    .sl-demo{
        height: 40% !important;
        border-radius: 0px;
    }


    .hero-image-container p {
        margin: auto;
        font-size: 24px;
        font-weight: 350;
        line-height: 30px;
        letter-spacing: -0.03em;
        text-align: center;
        width: 80%;
    }

    .hero-image-container button {
        font-size: 20px;
        font-weight: 350;
        line-height: 25px;
        letter-spacing: -0.03em;
        text-align: center;
        margin: 16px;
        padding: 16px 24px;
        border-radius: 81px;
        gap: 24px;
    }

    .hero-image-container {
        width: 60%;
        padding: 80px 20px 0px 20px;
    }

    .hero-image-container>div {
        position: relative;
        top: -60px;

    }
}


  
  .hover-text {
    position: absolute;
    color: #fff;
    border-radius: 5px;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .hover-button {
    background-color: #066fe0 !important;
    font-size: 15px !important;
    line-height: 37.5px !important;
    padding: 6px  30px !important;
    font-weight: 400;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); 
  }


  @media screen and (min-width: 1200px) {

    .sl-demo{
        height: 80% !important;
        border-radius: 0px;
    }


   }
