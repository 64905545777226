.brandCarouselContainer {
    background: #F3F9FF;
    padding: 48px 66px;
    overflow: hidden;
    /* max-width: 100vw; */
    width: 99vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.titleHeading {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: black;

}

.brandStatsCard {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.brandStatsCard div:first-child {
    font-weight: 700;
    font-size: 40px;
    line-height: 47.4px;
    letter-spacing: -2%;
    color: #25335A;
    text-align: center;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
}

.brandStatsCard div+div {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #25335A;
}

.brandStatsCardContainer {
    width: 910px;
    display: flex;
    justify-content: space-between;
    padding: 12px 23px;
    margin: auto;
}

.logos {
    width: 100%;
    display: flex;
    position: relative;
}

.logosContainer {

    /* width: 99vw; */
    overflow: hidden;
}

.logos_slide {
    display: flex;
    /* gap: 64px; */
    animation: 30s slide infinite linear;
}

.logos_slide>div {
    padding: 0 32px;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.middleBorder {
    border-bottom: 1px solid #CACEDA;
}

.smallStar {
    display: none;
}

@media screen and (max-width: 768px) {
    .brandCarouselContainer {
        padding: 24px 16px;
        gap: 12px;
    }

    .titleHeading {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;

    }

    .brandStatsCardContainer {
        width: 100%;
    }

    .brandStatsCard div:first-child {
        font-size: 18px;
        line-height: 27px;
    }

    .brandStatsCard div+div {
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #25335A;
    }

    .brandStatsCard {
        gap: 2px;
    }

    .bigStar {
        display: none;
    }

    .smallStar {
        display: block;
    }
}