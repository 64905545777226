.lockedPricingSection {
    background-image: url('../../assets/images/CalendarV2/LockedBG.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(12px);
    min-height: 522px;
    max-height: 530px;
}

.LockedPricingContainer {
    /* z-index: 111; */
    position: relative;
}

.LockedPricing {
    width: 100%;
    height: 100%;
    background: #ffffff8e;
    /* opacity: 0.5; */
    /* z-index: 222; */
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}

.unlockPriceBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 48px;
}

.unlockPriceBtnContainer>div {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    text-align: center;
    color: #25335A;
}

.unlockPriceBtnContainer>button {

    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    width: 320px;
    height: 63px;
    border-radius: 36px;
    background: #0671E0;
    color: #ffffff;
    gap: 24px;
    justify-content: center;
    border: none;
}

@media screen and (max-width: 768px) {
    .unlockPriceBtnContainer>div {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }

    .unlockPriceBtnContainer>button {
        width: 254px;
        height: 53px;
        font-size: 16px;
        line-height: 20px;
    }

    .lockedPricingSection {
        background-size: cover;
    }
}