/* Styles for BusinessStats */
@media (min-width: 768px) {
  /* .combined-container{
     display: block;
  } */
.business-stats-container {
  position: relative;
  width: 100%;
  max-width: 1194px;
  margin: 2rem auto;
  background-color: rgba(6, 113, 224, 0.06);
  border-radius: 22px;
  padding: 2rem;
}

.blue-text{
  color: #0671E0;
}

.stats-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  font-size: 52px;
  line-height: 150%;
  color: #0671E0;
  justify-content: center;
}

.stat-label {
  font-family: 'Lexend', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #0671E0;
  margin-top: 0.5rem;
  justify-content: center;
}

/* Styles for DiscoverCard */
.discover-card-container {
  position: absolute;
  width: 593px;
  height: 310px;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.card-background {
  box-sizing: border-box;
  position: absolute;
  width: 593px;
  height: 248px;
  left: 0;
  top: 19px;
  background: #FFFFFF;
  opacity: 0.5;
  border-radius: 38px 38px 0px 0px;
}

.card-foreground {
  box-sizing: border-box;
  position: absolute;
  width: 573px;
  height: 310px;
  left: 10px;
  top: 0;
  background: #FFFFFF;
  border: 1px solid #0671E0;
  border-radius: 38px 38px 0px 0px;
}

.card-title {
  position: absolute;
  width: 435px;
  height: 108px;
  left: 50%;
  top: 63px;
  transform: translateX(-50%);
  font-family: 'Lexend', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #0671E0;
}

.ellipses-group {
  position: absolute;
  width: 51px;
  height: 11px;
  left: 20px;
  top: 25px;
  opacity: 0.12;
  display: flex;
  justify-content: space-between;
}

.ellipse {
  width: 11px;
  height: 11px;
  background: #0671E0;
  border-radius: 50%;
}

.button-container {
  position: absolute;
  width: 148px;
  height: 39px;
  left: 50%;
  top: 201px;
  transform: translateX(-50%);
}

.button {
  position: absolute;
  width: 148px;
  height: 39px;
  background: #0671E0;
  border-radius: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
}

.button-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #F2F2F2;
}

.button-arrow {
  width: 6px;
  height: 6px;
  border-right: 1.3px solid #F2F2F2;
  border-bottom: 1.3px solid #F2F2F2;
  transform: rotate(-45deg);
  margin-left: 5px;
}

/* Styles for combined component */
.combined-container {
  display: block;
  position: relative;
  margin-top: 155px; /* Half of DiscoverCard height */
  margin-bottom: 155px; /* Half of DiscoverCard height */
}
}



/* @media (max-width: 768px) {
  .business-stats-container {
    position: relative;
    width: 100%;
    max-width: 1194px;
    margin: 2rem auto;
    background-color: rgba(6, 113, 224, 0.06);
    border: 1px solid #0671E0;
    border-radius: 22px;
    padding: 2rem;
  }
  
  .stats-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-number {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 52px;
    line-height: 150%;
    color: #0671E0;
  }
  
  .stat-label {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #0671E0;
    margin-top: 0.5rem;
  }
  
  .discover-card-container {
    position: relative;
    width: 593px;
    height: 310px;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  
  .card-background {
    box-sizing: border-box;
    position: absolute;
    width: 593px;
    height: 248px;
    left: 0;
    top: 19px;
    background: #FFFFFF;
    opacity: 0.5;
    border-radius: 38px 38px 0px 0px;
  }
  
  .card-foreground {
    box-sizing: border-box;
    position: absolute;
    width: 573px;
    height: 310px;
    left: 10px;
    top: 0;
    background: #FFFFFF;
    border: 1px solid #0671E0;
    border-radius: 38px 38px 0px 0px;
  }
  
  .card-title {
    position: absolute;
    width: 435px;
    height: 108px;
    left: 50%;
    top: 63px;
    transform: translateX(-50%);
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #0671E0;
  }
  
  .ellipses-group {
    position: absolute;
    width: 51px;
    height: 11px;
    left: 20px;
    top: 25px;
    opacity: 0.12;
    display: flex;
    justify-content: space-between;
  }
  
  .ellipse {
    width: 11px;
    height: 11px;
    background: #0671E0;
    border-radius: 50%;
  }
  
  .button-container {
    position: absolute;
    width: 148px;
    height: 39px;
    left: 50%;
    top: 201px;
    transform: translateX(-50%);
  }
  
  .button {
    position: absolute;
    width: 148px;
    height: 39px;
    background: #0671E0;
    border-radius: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  }
  
  .button-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;
    color: #F2F2F2;
  }
  
  .button-arrow {
    width: 6.5px;
    height: 3.15px;
    border: 1.3px solid #F2F2F2;
    transform: rotate(-90deg);
    margin-left: 5px;
  }
  
  
  .combined-container {
    position: relative;
    margin-top: 155px; 
    margin-bottom: 155px; 
  }
}
 */
/* .combined-container{
  display: none;
} */