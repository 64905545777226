.scrollWatcher {
    height: 2rem;
    position: fixed;
    top: 0px;
    left: 0px;
    transform-origin: 0;
    background-color: black;
    animation: scrollAnim linear;
    animation-timeline: scroll();
    width: 100%;
    scale: 0 1;

    
}


@keyframes scrollAnim {
    to {
        scale: 1 1;
    }
}