.blog_section {
  width: 75%;
  margin-top: 70px;
}

.blog_section h4 {
    display: none;
}

@media (max-width: 768px) {
  .blog_section {
    width: 100%;
    margin-top: 0px;
    display:  flex;
    flex-direction: column;
    gap: 16px;
  }

  .blog_section h4 {
    display: block;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    height: fit-content;
   width: 100%;
   text-align: center;
   color: #11354D;
 
  }


}


@media (max-width: 1250px) {
  .blog_section {
    width: 100%;
  }
}
