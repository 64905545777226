.responsiveIconSize {
    height: 38px;
    width: 38px;
}

.calendarSectionIcons {
    width: 42px;
    height: 42px;
}

@media screen and (max-width: 768px) {
    .responsiveIconSize {
        height: 28px;
        width: 28px;
    }

    .calendarSectionIcons {
        width: 32px;
        height: 32px;
    }
}