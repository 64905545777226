.publishButton {
    padding : 0.5rem 1rem;
    border:none;
    background-color: #1C5880;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: white;
}


.saveButton {
    display: flex;
    padding : 0.5rem 1rem;
    background-color: white;
    border-radius: 7px;
    border: 1px solid gray;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

