.button-filled {
    background-color: #0671E0;
    font-size: 12px;
    color: white;
}

.button-filled:hover {
    background-color: #2d86e6;
    color: white;
}

@media (min-width: 992px) {
    .button-filled {
        font-family: Lexend;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: -0.03em;
        text-align: center;
        padding : 16px 24px;
    }
}