.container {
    padding: 0.5rem 1.5rem;
}

.dropdownHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: Lexend;
    font-size: 1.25rem;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #164766;
}

.dropdownLinks {
    padding-top : 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap : 1rem;
}

.dropdownLinks a{
    font-family: Lexend;
    font-size: 1rem;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #4683AA;
}

.dropdownLinksSelected{
    color: #164766 !important;

}

.containerSubMenu {
    padding: 0rem 0rem;
    width: 100%;
}

.dropdownHeaderSubMenu {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:  #216A99;
}

.dropdownLinksSubMenu{
    padding-left: 0.5rem;
}


@media (min-width : 992px) {
    .container {
        padding: 0rem;
        width: 100%;
    }

    .dropdownHeader {
        flex-direction: row-reverse;
        justify-content: flex-end;
        gap : 1rem;
        padding: 0.75rem 1.5rem;
    }

    .dropdownHeaderSelected {
        color: #FFF;
        background-color: #216A99;
    }

    .dropdownLinks {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap : 1rem;
        padding: 0.5rem 0rem !important;
    }

    .dropdownLinks a{
        padding : 0rem 3.5rem;
    }

    .dropdownHeaderSubMenu {
        background-color: transparent;
        color:  #164766;
        padding: 0rem 0rem;
    }

    .containerSubMenu {
        padding: 0rem 1.5rem;
        width: 100%;
    }
    
    .dropdownLinksSubMenu{
        padding-left: 0.5rem;
    }
}
